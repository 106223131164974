<template>
  <v-fade-transition mode="out-in">
    <div class="contract-detail">
      <msal-functions :ref="refMsalFunction"></msal-functions>
      <div class="contract-detail-loading" v-if="isContractLoading/* && isLoadingOpportunity*/">
        <v-progress-circular
          :width="5"
          color="grey"
          indeterminate
          :size="30"
        ></v-progress-circular>
        <div
          class="grey--text"
          :style="{
            'margin-top': '12px',
            'font-size': '14px'
          }"
        >
          Loading
        </div>
      </div>
      <v-layout wrap v-else>
        <v-flex
          xs12
          lg8
          order-xs2
          pt-0
          pb-0
        >
          <material-app-card
            id="contract-review-draft-step-0"
            v-if="!isContractLoading"
            color="primary"
            icon="mdi-lightbulb-outline"
            :title="isPendingContract ? '1.0 Select Opportunities' : 'Opportunities'"
          >
            <template v-slot:extra>
              <v-tooltip left :disabled="$vuetify.breakpoint.smAndUp || isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text
                    color="primary" style="margin:0 0 0 6px;"
                    v-show="isDraftStepInOpportunity || fixingOpportunity"
                    v-on="on"
                    class="extra-button-text"
                    :icon="!$vuetify.breakpoint.smAndUp"
                    @click.stop="addingOpportunity = true"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                    <span 
                      style="font-size:14px;padding-left:8px;font-weight:bold"
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      Add Opportunity
                    </span>
                  </v-btn>
                </template>
                <span>Add Opportunity</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <div v-if="false" class="v-card-x-buttons">
                <v-btn small icon text@click.stop="addingOpportunity = true" v-if="isDraftStepInOpportunity || fixingOpportunity" color="primary"
                  style="margin:0 0 0 6px;"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex v-if="fixingOpportunity" xs12 pt-1 pl-3 pr-3 pb-0 class="v-card-x-cell">
                  <div class="error lighten-1 white--text" style="text-align:left;padding:21px 21px 18px 18px;display:flex;align-items:start;border-radius:6px;margin-bottom:8px;">
                    <v-icon color="white" style="margin-top: -3px;margin-right: 18px">
                      mdi-alert-circle-outline
                    </v-icon>
                    <div>
                      Before submit
                      <span style="font-weight: bold;">Client Project Contract</span>
                      request, please add your
                      <span style="font-weight: bold;">opportunity!</span>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <flexible-list
                    expand
                    expand-mini
                    :expand-icon-size="24"
                    
                    :no-data-text="(isDraftStepInOpportunity || fixingOpportunity) ? 'Please Add Opportunity' : 'No Opportunity'"

                    :headers="opportunityFields"
                    :items="opportunities"
                    :loading="isLoadingOpportunity"
                    :item-hover-style="oppHoverStyle"
                    :mini-item-hover-style="miniOppHoverStyle"

                    :mini-size="510"
                    :mini-columns="3 - ((windowWidth < 450) * 1) - !(isDraftStepInOpportunity || fixingOpportunity)"

                    :titleStyle="{
                      'padding-top': '9px',
                      'padding-bottom': '12px',
                      'color': 'rgba(0, 0, 0, .68)',
                      'font-weight': '400'
                    }"
                    :valueStyle="{
                      'padding-top': '9px',
                      'padding-bottom': '9px'
                    }"
                    :mini-columns-style="miniColumnsStyle"
                  >
                    <!-- <template v-slot:slot-icon="props">
                      <v-avatar size="24">
                        <v-icon color="grey">
                          mdi-lightbulb-outline
                        </v-icon>
                      </v-avatar>
                    </template> -->
                    <template v-slot:slot-actions="props">
                      <v-avatar v-if="isDraftStepInOpportunity || fixingOpportunity" size="30">
                        <v-btn small text icon
                          @click.stop="removeOpportunity(props.index)"
                        >
                          <v-icon size="24" color="grey">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-avatar>
                    </template>
                    <template v-slot:expansion="props">
                      <tr>
                        <td class="opportunity-expansion-logo"></td>
                        <td class="opportunity-expansion-title">Opportunity Name</td>
                        <td colspan=3 class="opportunity-expansion-value">{{ props.item.name }}</td>
                      </tr>
                      <tr>
                        <td class="opportunity-expansion-logo"></td>
                        <td class="opportunity-expansion-title bottom">Product(s)</td>
                        <td colspan=3 class="opportunity-expansion-value">{{ 
                          props.item.products
                            .map(el => el.product || '')
                            .filter(el => !!el)
                            .join(' / ') || '-'
                          }}</td>
                      </tr>
                    </template>
                    <template v-slot:expansion-mini="props">
                      <tr>
                        <td class="opportunity-expansion-logo"></td>
                        <td colspan=2 class="opportunity-expansion-title">Product(s): {{ 
                          props.item.products
                            .map(el => el.product || '')
                            .filter(el => !!el)
                            .join(' / ') || '-'
                          }}</td>
                      </tr>
                    </template>
                  </flexible-list>
                </v-flex>
                <template v-if="isDraftStepInOpportunity">
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container">
                      <v-btn color="error lighten-1"
                        v-if="isNewContract"
                        @click="cancelNewContract"
                      >
                        Cancel
                      </v-btn>
                    </div>
                  </v-flex>
                  <template v-if="!(isContractLoading || isLoadingOpportunity)">
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container-right">
                        <v-btn color="primary"
                          :disabled="opportunities.length < 1"
                          @click="draftStepOpportunityNext"
                        >
                          Next
                        </v-btn>
                      </div>
                    </v-flex>
                  </template>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="contract-review-draft-step-1"
            color="primary"
            icon="mdi-toolbox-outline"
            :title="isPendingContract ? '2.0 Specify Contract Type' : 'Contract Type'"
            v-if="!isPendingContract || contract.draftStep >= 1"
          >
            <template v-slot>
              <v-form ref="contract-type-form">
                <v-layout row wrap class="v-card-x-layout">
                  <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell">
                    <div style="margin-top: -16px">
                      <v-select
                        v-model="contract.type"
                        :items="contractTypeList"
                        :disabled="!isDraftStepInContractType"
                        label="Contract Type"
                        ref="contract_form_type"
                        item-text="value"
                        item-value="value"
                        :rules="[v => !!contractTypeList.find(el => el.value === v) || 'Please select']"
                      >
                      </v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="v-flex-row-breaker">
                  </v-flex>
                  <v-flex xs12 pt-1 pl-3 pr-3 pb-2 class="v-card-x-cell"
                    v-if="isContractGeneratorAvailable"
                  >
                    <div style="margin-top: -16px">
                      <v-radio-group class="contract-radio-group-vertical"
                        :disabled="!isDraftStepInContractType"
                        v-model="contract.contractGenerator"
                        :rules="[v => (!!v && v != '') || 'Please select']"
                      >
                        <v-radio value="Yes" color="primary">
                          <template v-slot:label>
                            <span>Contract Generator: client accept to use Nielsen template without any revision.</span>
                          </template>
                        </v-radio>
                        <v-radio value="No" color="primary">
                          <template v-slot:label>
                            <span>Client request to use client template/ client request revision regarding to Nielsen template</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      tabindex="0"
                      ref="contract_form_generator"
                      style="height: 1px;"
                    ></div>
                  </v-flex>
                  <v-flex xs12 class='v-flex-row-breaker'>
                  </v-flex>
                  <template v-if="!(isContractLoading || isLoadingOpportunity) && isDraftStepInContractType">
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="primary"
                          @click="draftStepContractTypePrevConfirm"
                        >
                          Previous
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container-right">
                        <v-btn color="primary"
                          @click="draftStepContractTypeNext"
                        >
                        <!-- :disabled="!contractTypeCardReady()" -->
                          Next
                        </v-btn>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>

          <material-app-card
            id="contract-review-draft-step-2"
            color="primary"
            icon="mdi-text-box-check-outline"
            :title="isPendingContract ? '2.1 Upload Drafted Contract' : 'Drafted Contract'"
            v-if="(!isPendingContract || contract.draftStep >= 2) && !isContractGeneratorUsed"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-3 pl-3 px-4 class="v-card-x-cell">
                  <span
                    >Please ONLY upload the documents you need to sign. (Other supporting document you can upload with "comments")
                  </span>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell" v-if="isDraftStepInDraftedContract">
                  <div style="width: 100%;display: flex;align-items: center;">
                    <x-upload
                      request-class="file"
                      request-function="upload"
                      accept=".doc,.docx,.pdf"
                      :params="{
                        email: userEmail,
                        loginStatus: loginStatus
                      }"
                      :before-upload="beforeUploadDraftedContract"
                      :on-error="errorUploadDraftedContract"
                      :on-success="successUploadDraftedContract"
                    >
                      <v-btn text
                        style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                      >
                        <v-icon color="primary" size="24" style="margin-right:12px;">
                          mdi-upload
                        </v-icon>
                        <div v-if="contract.draftedContract.length > 0">Upload more <b>Client Confirm Contract</b></div>
                        <div v-else>Please upload <b>Client Confirm Contract</b></div>
                      </v-btn>
                    </x-upload>
                  </div>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <file-list
                    :files="contract.draftedContract"
                    :deletable="isDraftStepInDraftedContract"
                    :multiple-rows="windowWidth < 650"
                    @click-download="downloadFile"
                    @click-delete="deleteDraftedContract"
                  ></file-list>
                  <div
                    tabindex="0"
                    ref="contract_form_draft_contract"
                    style="height: 1px;"
                  ></div>
                </v-flex>
                <template v-if="!(isContractLoading || isLoadingOpportunity) && isDraftStepInDraftedContract">
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container">
                      <v-btn color="primary"
                        @click="draftStepDraftedContractPrevConfirm"
                      >
                        Previous
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container-right">
                      <v-btn color="primary"
                        :disabled="!draftedContractReady()"
                        @click="draftStepDraftedContractNext"
                      >
                        Next
                      </v-btn>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="contract-review-draft-step-3"
            color="primary"
            icon="mdi-file-document-edit-outline"
            :title="isPendingContract ? (isContractGeneratorUsed ? '3.0' : '2.2') + ' Generate Contract' : 'Contract Information'"
            v-if="(!isPendingContract || ( contract.draftStep > 3 || (contract.draftStep === 3 && !isContractGeneratorUsed)))"
          >
            <template v-slot>
              <v-form ref="contract-info-form">
                <v-layout row wrap class="v-card-x-layout">
                  <v-flex xs12
                    v-if="isContractLoading"
                    :style="{
                      margin: '12px',
                      display: 'flex',
                      'align-items': 'center',
                      'justify-content': 'center'
                    }"
                  >
                    <v-progress-circular
                      :width="4"
                      color="grey"
                      indeterminate
                      :size="24"
                    ></v-progress-circular>
                    <div
                      class="grey--text text--lighten-1"
                      :style="{
                        'margin-left': '9px'
                      }"
                    >
                      Loading
                    </div>
                  </v-flex>
                  <template v-else>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="languageAvailable"
                    >
                      <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(contractInfoChecked && !contract.language ? ['red--text'] : []), ...(isDraftStepInContractInfo ? [] : ['my-disabled--text'])]">Language</div>
                      <div>
                        <v-radio-group row class="contract-radio-group-horizonal"
                          :disabled="!isDraftStepInContractInfo"
                          v-model="contract.language"
                          :rules="[v => (!!v && v != '') || 'Please select']"
                        >
                          <v-radio label="English" value="English" color="primary"></v-radio>
                          <v-radio label="中文" value="Chinese" color="primary"></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="contract_form_language"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="contractTemplateAvailable"
                    >
                      <div>
                        <v-select
                          :disabled="!isDraftStepInContractInfo"
                          label="Contract Template"
                          :items="contractTemplateList"
                          v-model="contract.usedTemplate"
                          ref="contract_form_template"
                          :rules="[v => !!contractTemplateList.find(el => el === v) || 'Please select']"
                        >
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div>
                        <v-select
                          :disabled="!isDraftStepInContractInfo"
                          label="Sales Organization"
                          :items="salesOrganizationListForSelect"
                          :item-text="languageAvailable ? (contract.language === 'Chinese' ? 'textCn' : 'textEn') : 'text'"
                          :placeholder="salesOrganizationPlaceholder"
                          v-model="contract.salesOrganization"
                          ref="contract_form_sales_organization"
                          :rules="[v => !!salesOrganizationListForSelect.find(el => el.value === v) || 'Please select']"
                        >
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div>
                        <v-autocomplete
                          :disabled="!isDraftStepInContractInfo"
                          label="Sold To Party (SAP)"
                          ref="contract_form_sap"
                          no-filter
                          :search-input.sync="contract.sapKey"
                          v-model="contract.sap"
                          :items="contract.saps"
                          :loading="isSapsLoading"
                          :rules="[v => !!contract.sap || v != '' || 'Please input and select']"
                          @focus="focusSap"
                          @blur="blurSap"
                          @input="inputSap"
                        ></v-autocomplete>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div>
                        <v-autocomplete
                          v-if="contract.sap"
                          :disabled="!isDraftStepInContractInfo"
                          label="Customer Number (SAP)"
                          ref="contract_form_customer_number"
                          v-model="contract.customerNumber"
                          :items="contract.customerNumbers"
                          :rules="[v => !!contract.customerNumber || v != '' || 'Please input and select']"
                        ></v-autocomplete>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="amountAvailable"
                    >
                      <div class="v-card-contract-amount">
                        <v-text-field
                          append-icon="mdi-pencil"
                          :label="'Amount (Including Tax)' + (!!contractAmountToThousands ? ': ' + contractAmountToThousands : '')"
                          type="number"
                          :disabled="!isDraftStepInContractInfo"
                          v-model="contract.amount"
                          @blur="blurContractAmount"
                          ref="contract_form_amount"
                          :rules="[
                            v => !isNaN(parseFloat(v)) || 'Please input number',
                            v => parseFloat(v) > 0 || 'Amount should be greater than 0'
                          ]"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="currencyAvailable"
                    >
                      <div>
                        <v-select
                          :disabled="!isDraftStepInContractInfo"
                          label="Currency"
                          :items="contractCurrencyList"
                          v-model="contract.currency"
                          ref="contract_form_currency"
                          :rules="[v => (!!v && v != '') || 'Please select']"
                        >
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div>
                        <v-dialog
                          ref="startDate"
                          v-model="showingStartDatePickerGenerator"
                          :return-value.sync="contract.startDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="contract.startDate"
                              label="Contract Start Date"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!isDraftStepInContractInfo"
                              ref="contract_form_start_date"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="contract.startDate" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedStartDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="showingStartDatePickerGenerator = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="$refs.startDate.save(contract.startDate)">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div>
                        <v-dialog
                          ref="endDate"
                          v-model="showingEndDatePickerGenerator"
                          :return-value.sync="contract.endDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="contract.endDate"
                              label="Contract End Date"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!isDraftStepInContractInfo"
                              ref="contract_form_end_date"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="contract.endDate" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedEndDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="showingEndDatePickerGenerator = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="$refs.endDate.save(contract.endDate)">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="billingTermsAvailable"
                    >
                      <div>
                        <v-select
                          :disabled="!isDraftStepInContractInfo"
                          label="Billing Terms"
                          :items="billingTermsValues"
                          v-model="contract.billingTerms"
                          ref="contract_form_billing_terms"
                          :rules="[v => !!billingTermsValues.find(el => el.value === v) || 'Please select']"
                        >
                        </v-select>
                      </div>
                      <template v-if="billingTermsOthersAvailable">
                        <div :class="['v-card-x-cell-title', ...(contractInfoChecked && !contract.billingTermsOthers ? ['red--text'] : [])]">Billing Terms - Specify</div>
                        <div class="title-solo-textarea">
                          <v-textarea
                            solo
                            no-resize
                            rows="3"
                            placeholder="Please specify"
                            style="margin-top: 0px"
                            :counter="termsOthersMax"
                            v-model="contract.billingTermsOthers"
                            :readonly="!isDraftStepInContractInfo"
                            ref="contract_form_billing_terms_others"
                            :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          >
                          </v-textarea>
                        </div>
                      </template>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="paymentTermsAvailable"
                    >
                      <div>
                        <v-select
                          :disabled="!isDraftStepInContractInfo"
                          label="Payment Terms"
                          :items="paymentTermsValues"
                          v-model="contract.paymentTerms"
                          ref="contract_form_payment_terms"
                          :rules="[v => !!paymentTermsValues.find(el => el.value === v) || 'Please select']"
                        >
                        </v-select>
                      </div>
                      <template v-if="paymentTermsOthersAvailable">
                        <div :class="['v-card-x-cell-title', ...(contractInfoChecked && !contract.paymentTermsOthers ? ['red--text'] : [])]">Payment Terms - Specify</div>
                        <div class="title-solo-textarea">
                          <v-textarea
                            solo
                            no-resize
                            rows="3"
                            placeholder="Please specify"
                            style="margin-top: 0px"
                            :counter="termsOthersMax"
                            v-model="contract.paymentTermsOthers"
                            :readonly="!isDraftStepInContractInfo"
                            ref="contract_form_payment_terms_others"
                            :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          >
                          </v-textarea>
                        </div>
                      </template>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="false && msalsaAvailable"
                    >
                      <div class="v-card-x-cell-title v-card-x-cell-title-absolute">Follow MSA/LSA</div>
                      <v-tooltip right nudge-top="60" :disabled="isMobile">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            small
                            v-on="on"
                            style="margin-left: 12px !important;"
                          >
                            mdi-help-circle
                          </v-icon>
                        </template>
                        <div style="text-align: left;padding: 6px;">
                          <div style="margin-bottom: 3px;width: 180px;">MSA/LSA stand for Master Service Agreement/Local Service Agreement If 'Yes', this contract will follow previous MSA/LSA with this client. If 'No', this contract will not follow previous MSA/LSA with this client. If 'N/A', it does not have MSA/LSA.</div>
                        </div>
                      </v-tooltip>
                      <div>
                        <v-radio-group row class="contract-radio-group-horizonal" :disabled="!isDraftStepInContractInfo"
                          v-model="contract.msalsa"
                        >
                          <v-radio label="Yes" value="Yes" color="primary"></v-radio>
                          <v-radio label="No" value="No" color="primary"></v-radio>
                          <v-radio label="N/A" value="NA" color="primary"></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="contract_form_msalsa"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 pt-3 pl-3 pr-3 pb-3 class="v-card-x-cell"
                      v-if="sowAvailable"
                    >
                      <div
                        v-if="!isDraftStepInContractInfo"
                        class="v-card-x-cell-title pb-1"
                      >
                        Scope of Work (SOW - Word .DOCX Only)
                      </div>
                      <div v-else style="width: 100%;display: flex;align-items: center;">
                        <x-upload
                          request-class="file"
                          request-function="upload"
                          accept=".docx"
                          :params="{
                            email: userEmail,
                            loginStatus: loginStatus
                          }"
                          :before-upload="beforeUploadSow"
                          :on-error="errorUploadSow"
                          :on-success="successUploadSow"
                        >
                          <v-btn text
                            v-if="contract.sow.length === 0"
                            style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          >
                            <v-icon color="primary" size="24" style="margin-right:12px;">
                              mdi-upload
                            </v-icon>
                            <div>Upload Scope of Work (SOW - Word .DOCX Only)</div>
                          </v-btn>
                        </x-upload>

                        <v-btn text
                          v-if="contract.sow.length > 0"
                          style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          @click="isSowUploadConfirming = true"
                        >
                          <v-icon color="primary" size="24" style="margin-right:12px;">
                            mdi-upload
                          </v-icon>
                          <div>Change Scope of Work (SOW - Word .DOCX Only)</div>
                        </v-btn>
                      </div>

                      <v-expand-transition>
                        <div 
                          v-if="contractInfoChecked && contract.sow.length === 0" 
                          class="red--text"
                          style="font-size:12px;"
                        >
                          Please upload Scope of Work
                        </div>
                      </v-expand-transition>
                      
                      <div style="padding-bottom: 16px;">
                        <file-list
                          :files="contract.sow"
                          :deletable="isDraftStepInContractInfo"
                          :multiple-rows="windowWidth < 650"
                          @click-download="downloadFile"
                          @click-delete="deleteSow"
                        ></file-list>
                        <div
                          tabindex="0"
                          ref="contract_form_sow"
                          style="height: 1px;"
                        ></div>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="projectDescriptionAvailable"
                    >
                      <div class="v-card-x-cell-title">Project Description</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          ref="contract_form_project_description"
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please specify"
                          style="margin-top: 0px"
                          :counter="commentsMax"
                          v-model="contract.projectDescription"
                          :readonly="!isDraftStepInContractInfo"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <template v-if="!(isContractLoading || isLoadingOpportunity) && isDraftStepInContractInfo">
                      <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                        <div class="bottom-btn-container">
                          <v-btn color="primary"
                            :disabled="!isContractInfoSavable()"
                            @click="draftStepContractInfoPrevConfirm"
                          >
                            Previous
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                        <div class="bottom-btn-container-right">
                          <v-btn color="primary"
                            :disabled="!isContractInfoSavable()"
                            @click="saveContractDraft"
                          >
                            Save
                          </v-btn>
                          <v-btn color="primary"
                            v-if="isContractGeneratorUsed"
                            @click="postGenerateContractPreview"
                          >
                            Generate
                          </v-btn>
                          <v-btn color="primary"
                            v-else
                            @click="draftStepContractInfoNext"
                          >
                            Next
                          </v-btn>
                        </div>
                      </v-flex>
                    </template>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>

          <material-app-card
            id="contract-review-draft-step-4"
            color="primary"
            icon="mdi-text-box-check-outline"
            :title="(isPendingContract ? '3.1 ' : '') + 'Contract'"
            v-if="(!isPendingContract || contract.draftStep >= 4) && isContractGeneratorUsed"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell v-card-x-cell">
                  <div style="padding-bottom: 16px;">
                    <file-list
                      v-if="isContractGeneratorUsed"
                      :files="dummyContractsUnderReview"
                      :multiple-rows="windowWidth < 650"
                    >
                      <template v-slot:buttons>
                        <td style="width: 86px;">
                          <v-btn small text icon
                            class="mt-0 mb-0"
                            @click="downloadDoc"
                          >
                            <v-icon color="primary">
                              mdi-download
                            </v-icon>
                          </v-btn>
                          <v-btn small text icon
                            class="mt-0 mb-0"
                            @click="showSowContract"
                          >
                            <v-icon color="primary">
                              mdi-eye-settings
                            </v-icon>
                          </v-btn>
                        </td>
                      </template>
                    </file-list>
                  </div>
                  <div v-if="isDraftStepInGeneratedContract" class="error lighten-1 white--text" style="text-align:left;padding:21px 21px 18px 18px;display:flex;align-items:start;border-radius:6px;margin-bottom:16px;">
                    <v-icon color="white" style="margin-top: -3px;margin-right: 18px">
                      mdi-alert-circle-outline
                    </v-icon>
                    <div>
                      <div>Based on your input, we have generated a Nielsen standard contract. Please double check the contract carefully, <span style="font-weight:bold;text-decoration:underline;">especially the Scope of Work in APPENDIX.</span></div>
                      <div>Now you can download and <span style="font-weight: bold;text-decoration: underline;">confirm the contract with your client</span>, or re-edit your contract.</div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 class="v-flex-row-breaker">
                </v-flex>
                <template v-if="!(isContractLoading || isLoadingOpportunity) && isDraftStepInGeneratedContract">
                  <v-flex xs6 pt-1 pl-3 pb-3>
                    <div class="bottom-btn-container">
                      <v-btn color="primary"
                        @click="draftStepGeneratedContractPrevConfirm"
                      >
                        Re-edit
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-1 pr-3 pb-3>
                    <div class="bottom-btn-container-right">
                      <v-btn color="primary"
                        @click="draftStepGeneratedContractNext"
                      >
                        Next
                      </v-btn>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="contract-review-draft-step-5"
            color="primary"
            icon="mdi-stamper"
            :title="isPendingContract ? (isContractGeneratorUsed ? '4.0' : '3.0') + ' Send For Approval' : 'Approval Information'"
            v-if="!isPendingContract || contract.draftStep >= 5"
          >
            <template v-slot>
              <v-form ref="approval-info-form">
                <v-layout row wrap class="v-card-x-layout">
                  <v-flex xs12 sm6 pa-0>
                    <v-flex xs12 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="approver-selector-without-details">
                        <account-profile
                          :email="contract.managerApprover.email"
                          :icon="contract.managerApprover.icon"
                          :name="contract.managerApprover.name"
                          :hide-tooltip="isMobile || !contract.managerApprover.email"
                          :gap-of-tag-icon-and-name="12"
                          :tag-color="themeColor.default"
                          left
                        >
                          <template v-slot:tag>
                            <leader-select
                              v-model="contract.managerApprover"
                              label="Commercial Leader Approver"
                              :owner="contract.requestor.email"
                              :leader-titles="leaderTitles"
                              :disabled="!isDraftStepInApprovalInfo"
                              :rules="[v => !!v && v != '' || 'Please select']"
                            ></leader-select>
                          </template>
                        </account-profile>
                      </div>
                      <div
                        tabindex="0"
                        ref="contract_form_manager_approver"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 py-0 pl-3 pr-3 class="v-card-x-cell">
                      <div v-if="cpgApproverAvailable" class="approver-selector-without-details pb-1">
                        <account-profile
                          :email="contract.cpgApprover.email"
                          :icon="contract.cpgApprover.icon"
                          :name="contract.cpgApprover.name"
                          :hide-tooltip="isMobile || !contract.cpgApprover.email"
                          :gap-of-tag-icon-and-name="12"
                          :tag-color="themeColor.default"
                          left
                        >
                          <template v-slot:tag>
                            <user-select-sync
                              v-model="contract.cpgApprover"
                              label="CPG Approver"
                              :items="[contract.cpgApprover]"
                              disabled
                            ></user-select-sync>
                          </template>
                        </account-profile>
                      </div>
                      <div
                        tabindex="0"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 py-0 pl-3 pr-3 class="v-card-x-cell">
                      <div v-if="ciApproverAvailable" class="approver-selector-without-details pb-1">
                        <account-profile
                          :email="contract.ciApprover.email"
                          :icon="contract.ciApprover.icon"
                          :name="contract.ciApprover.name"
                          :hide-tooltip="isMobile || !contract.ciApprover.email"
                          :gap-of-tag-icon-and-name="12"
                          :tag-color="themeColor.default"
                          left
                        >
                          <template v-slot:tag>
                            <user-select-sync
                              v-model="contract.ciApprover"
                              label="CI Approver"
                              :items="[contract.ciApprover]"
                              disabled
                            ></user-select-sync>
                          </template>
                        </account-profile>
                      </div>
                      <div
                        tabindex="0"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 py-0 pl-3 pr-3 class="v-card-x-cell">
                      <div v-if="financeApproverAvailable" :class="'pb-1 approver-selector-' + (financeLeaderApproval ? 'without' : 'with') + '-details'">
                        <account-profile
                          :email="contract.financeApprover.email"
                          :icon="contract.financeApprover.icon"
                          :name="contract.financeApprover.name"
                          :hide-tooltip="isMobile || !contract.financeApprover.email"
                          :gap-of-tag-icon-and-name="12"
                          :tag-color="themeColor.default"
                          left
                        >
                          <template v-slot:tag>
                            <user-select-sync
                              v-model="contract.financeApprover"
                              label="Finance Approver"
                              :items="financeApproversOptions"
                              :disabled="!isDraftStepInApprovalInfo || financeLeaderApproval"
                              :rules="[v => !!v && v != '' || 'Please select']"
                            >
                            </user-select-sync>
                          </template>
                        </account-profile>
                        
                        <v-tooltip bottom :disabled="isMobile">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-if="!financeLeaderApproval"
                              size="28" class="mt-3 ml-2 mr-1 on-cursor-pointer" v-on="on">
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <div class="contract-review-details-approver-tooltip pb-2 pr-1">
                            <div class="mb-1">Finance Approver:</div>
                            <template v-for="(fa, fi) in financeApproversOptions">
                              <div v-if="fa && fa.location && fa.name" :key="'finance_approver_' + fi" class="ml-1">
                                - {{ fa.location }}: {{ fa.name }}
                              </div>
                            </template>
                          </div>
                        </v-tooltip>
                      </div>
                      <!-- Finance approval not available - 2023/04/11 requested by Colleen (1/2) -->
                      <!-- 
                      <div v-else class="mb-4">
                        <span class="v-card-x-cell-title v-card-x-cell-title-absolute my-disabled--text">Finance Approver</span>
                        <div class="contract-review-approver-no-need">
                          <div>No Need</div>
                        </div>
                      </div>
                      -->
                      <div
                        tabindex="0"
                        ref="contract_form_finance_approver"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 py-0 pl-3 pr-3 class="v-card-x-cell">
                      <div v-if="legalApproverAvailable" class="approver-selector-with-details pb-1">
                        <account-profile
                          :email="contract.legalApprover.email"
                          :icon="contract.legalApprover.icon"
                          :name="contract.legalApprover.name"
                          :hide-tooltip="isMobile || !contract.legalApprover.email"
                          :gap-of-tag-icon-and-name="12"
                          :tag-color="themeColor.default"
                          left
                        >
                          <template v-slot:tag>
                            <user-select-sync
                              v-model="contract.legalApprover"
                              label="Legal Approver"
                              :items="legalApproversOptions"
                              :disabled="!isDraftStepInApprovalInfo"
                              :rules="[v => !!v && v != '' || 'Please select']"
                            ></user-select-sync>
                          </template>
                        </account-profile>
                        
                        <v-tooltip bottom :disabled="isMobile">
                          <template v-slot:activator="{ on }">
                            <v-icon size="28" class="mt-3 ml-2 mr-1 on-cursor-pointer" v-on="on">
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <div class="contract-review-details-approver-tooltip pb-2 pr-1">
                            <div class="mb-1">Legal Approver:</div>
                            <template v-for="(la, li) in legalApproversOptions">
                              <div v-if="la && la.location && la.name" :key="'legal_approver_' + li" class="ml-1">
                                - {{ la.location }}: {{ la.name }}
                              </div>
                            </template>
                          </div>
                        </v-tooltip>
                      </div>
                      <div class="mb-4" v-else>
                        <span class="v-card-x-cell-title v-card-x-cell-title-absolute my-disabled--text">Legal Approver</span>
                        <div class="contract-review-approver-no-need">
                          <div>No Need</div>
                        </div>
                      </div>
                      <div
                        tabindex="0"
                        ref="contract_form_legal_approver"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 sm6 pa-0>
                    <v-flex xs12 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="v-card-x-cell-title my-disabled--text">Requester</div>
                      <div style="padding-top: 2px;max-width: 270px;">
                        <account-profile
                          :email="contract.requestor.email"
                          :name="contract.requestor.name"
                          :icon="contract.requestor.icon"
                          :hide-tooltip="isMobile"
                          :gap-of-tag-icon-and-name="12"
                          tag-color="rgba(0,0,0,.68)"
                          tag-font-size="16"
                        >
                        </account-profile>
                      </div>
                    </v-flex>
                    <v-flex xs12 mt-6 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="v-card-x-cell-title my-disabled--text">Cost Center</div>
                      <div
                        class="my-disabled--text"
                        style="padding-top: 6px;"
                      >
                        {{ contract.costCenter }}
                      </div>
                    </v-flex>
                    <v-flex xs12 mt-6 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="v-card-x-cell-title my-disabled--text">Team</div>
                      <div
                        class="my-disabled--text"
                        style="margin-top: 6px;"
                      >
                        {{ contract.team }}
                      </div>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                  <v-flex xs12 pt-3 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div :class="['v-card-x-cell-title', ...(isDraftStepInApprovalInfo ? [] : ['my-disabled--text'])]">Background Briefing</div>
                    <div class="title-solo-textarea">
                      <v-textarea
                        solo
                        no-resize
                        rows="3"
                        placeholder="Please specify"
                        style="margin-top: 0px"
                        :counter="commentsMax"
                        v-model="contract.backgroundBriefing"
                        :readonly="!isDraftStepInApprovalInfo"
                        ref="contract_form_background_briefing"
                      >
                      </v-textarea>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                  <template v-if="!(isContractLoading || isLoadingOpportunity) && isDraftStepInApprovalInfo">
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="primary"
                          :disabled="!isApprovalInfoSavable()"
                          @click="draftStepApprovalInfoPrevConfirm"
                        >
                          Previous
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div
                        class="bottom-btn-container-right"
                      >
                        <v-btn color="primary"
                          :disabled="!isApprovalInfoSavable()"
                          @click="saveContractDraft"
                        >
                          Save
                        </v-btn>
                        <v-btn color="primary"
                          @click="confirmSubmit"
                        >
                          Submit
                        </v-btn>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>
        </v-flex>

        <v-flex
          xs12
          lg4
          order-lg2
          pt-0
          pb-0
        >
          <material-app-card
            v-if="!isContractLoading"
            color="primary"
            icon="mdi-radar"
            :title="isNewContract ? 'Client Project Contract' : contract.requestId"
          >
            <template v-slot:extra>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-show="canAbandon"
                    @click="isContractAbandoning = true"
                    v-on="on"
                  >
                    <v-icon size="22">mdi-cancel</v-icon>
                  </v-btn>
                </template>
                <span>Abandon</span>
              </v-tooltip>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-show="canRecall"
                    @click="clickRecall"
                    v-on="on"
                  >
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>
                </template>
                <span>Recall</span>
              </v-tooltip>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-show="!isNewContract"
                    @click="showShareAccess"
                    v-on="on"
                  >
                    <v-icon v-if="canShare">mdi-account-plus</v-icon>
                    <v-icon v-else>mdi-account</v-icon>
                  </v-btn>
                </template>
                <span v-if="canShare">Share Access</span>
                <span v-else>Access</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <div class="contract-stepper">
                <v-stepper
                  :value="statusStep"
                  vertical
                >
                  <template v-for="(status, si) in statusListStepper">
                    <!-- <v-divider
                      :key="'step_divider_' + si"
                    ></v-divider> -->
                    <v-stepper-step
                      :key="'step_' + si"
                      color="primary"
                      :step="si + 1"
                      :complete="statusStep >= si + 1"
                      :complete-icon="statusStep > si + 1 ? '$vuetify.icons.complete' : 'mdi-star'"
                    >
                      {{ status.value }}
                    </v-stepper-step>
                    <v-stepper-content :key="'step_content_' + si" :step="si + 1">
                    </v-stepper-content>
                  </template>
                </v-stepper>
              </div>
            </template>
          </material-app-card>

          <material-app-card
            color="primary"
            icon="mdi-message-draw"
            title="Review Panel"
            v-if="!isPendingContract"
          >
            <template v-slot:extra>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-if="canComment"
                    @click="showSendComments(0)"
                    v-on="on"
                  >
                    <v-icon size="20">mdi-forum-outline</v-icon>
                  </v-btn>
                </template>
                <span>Comment</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex v-if="canDownloadSignFile || canViewSignInfo" xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <div ref="contract_sign" style="width:100%"></div>
                  <file-list
                    :files="isSigningContract ? dummyContractSigning : dummyContractSigned"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                  >
                    <template v-slot:buttons="props">
                      <td style="width: 86px; text-align: right;">
                        <v-btn small text icon
                          class="mt-0 mb-0"
                          v-if="canDownloadSignFile"
                          @click="downloadSignFile(props.index)"
                        >
                          <v-icon color="primary">
                            mdi-download
                          </v-icon>
                        </v-btn>
                        <v-btn small text icon
                          v-if="canViewSignInfo"
                          @click="clickViewSign"
                        >
                          <v-icon color="primary">
                            mdi-eye-settings
                          </v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </file-list>
                </v-flex>
                <v-flex v-else-if="isApprovalContract || isApprovedContract" xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <div ref="contract_under_review" style="width:100%"></div>
                  <file-list
                    v-if="isContractGeneratorUsed"
                    :files="dummyContractsUnderReview"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                  >
                    <template v-slot:buttons>
                      <td style="width: 86px;">
                        <v-btn small text icon
                          class="mt-0 mb-0"
                          @click="downloadDoc"
                        >
                          <v-icon color="primary">
                            mdi-download
                          </v-icon>
                        </v-btn>
                        <v-btn small text icon
                          @click="showSowContract"
                        >
                          <v-icon color="primary">
                            mdi-eye-settings
                          </v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </file-list>
                  <file-list
                    v-if="isDraftedContractAvailable"
                    :files="contract.draftedContract"
                    :deletable="false"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                    @click-download="downloadFile"
                  ></file-list>
                </v-flex>
                <v-flex xs12 class="v-flex-row-breaker">
                </v-flex>
                <template v-if="!isContractLoading">
                  <v-flex xs3 pt-1 pl-3 pr-3 pb-3>
                    <div class="bottom-btn-container">
                      <v-btn v-if="false && canComment" color="primary"
                        @click="showSendComments(0)"
                      >
                        Comment
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs9 pt-1 pl-3 pr-3 pb-3>
                    <div class="bottom-btn-container-right">
                      <v-btn v-if="!isMobile && canReviseContract && isDraftedContractAvailable" color="primary"
                        @click="showUploadNewDraftedContract"
                      >
                        Revise Contract
                      </v-btn>
                      <template v-if="canApprove">
                        <v-btn v-if="isDraftedContractAvailable" color="primary"
                          @click="isContractApproving = true"
                        >
                          Approve
                        </v-btn>
                        <v-btn v-else color="primary"
                          @click="showSowContract"
                        >
                          Action
                        </v-btn>
                      </template>
                      <v-btn v-if="canSign" color="primary"
                        @click="clickStartSign"
                      >
                        Sign
                      </v-btn>
                      <v-btn v-if="canViewSignInfo" color="primary"
                        @click="clickViewSign"
                      >
                        Next
                      </v-btn>
                      <v-btn v-if="canArchive" color="primary"
                        @click="clickArchive"
                      >
                        Archive
                      </v-btn>     
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            color="primary"
            icon="mdi-history"
            title="Activity History"
            v-if="history.length > 0 && $vuetify.breakpoint.lgAndUp"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <activity-history
                    :history="history"
                  >
                  </activity-history>
                </v-flex>
              </v-layout>
            </template>
          </material-app-card>
        </v-flex>

        <v-flex
          v-if="!$vuetify.breakpoint.lgAndUp"
          order-xs3
          xs12
          pt-0
          pb-0
        >
          <material-app-card
            color="primary"
            icon="mdi-history"
            title="Activity History"
            v-if="history.length > 0"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <activity-history
                    :history="history"
                  >
                  </activity-history>
                </v-flex>
              </v-layout>
            </template>
          </material-app-card>
        </v-flex>

        <!-- Add Opportunity -->
        <v-dialog
          v-model="addingOpportunity"
          width="fit-content"
          persistent
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <add-opportunity-card
            :is-mobile="isMobile"
            @close="closeAddOpportunityNew"
            @submit="doAddOpportunity"
          >
          </add-opportunity-card>
        </v-dialog>
        
        <share-access
          v-model="isAccessSharing"
          :owner="contract.requestor"
          :members="collaborators"
          :editable="canShare"
          @save-members="postUpdatedCollaborator"
        ></share-access>

        <forward-reviewer
          v-if="canApprove"
          :value="false && isReviewerForwarding"
          @complete="postForward"
        ></forward-reviewer>

        <v-dialog
          v-model="isNewDraftedContractUploadCardShowing"
          persistent
          width="fit-content"
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <v-card class="new-drafted-contract-card">
            <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile" text icon @click="isNewDraftedContractUploadCardShowing = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    Contract Update
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn v-if="isMobile" text icon @click="confirmNewDraftedContract">
                    <v-icon>check</v-icon>
                  </v-btn>
                  <v-btn v-else text icon @click="isNewDraftedContractUploadCardShowing = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div :style="{position: 'absolute',top: '64px',bottom: 0,left: 0,right: 0,overflow: 'visible'}">
              <div style="display: flex; align-items: center;justify-content: space-between;padding: 0 12px;">
                <div style="margin-right: 4px;">
                  <x-upload
                    request-class="file"
                    request-function="upload"
                    accept=".doc,.docx,.pdf"
                    :params="{
                      email: userEmail,
                      loginStatus: loginStatus
                    }"
                    :before-upload="beforeUploadNewDraftedContract"
                    :on-error="errorUploadNewDraftedContract"
                    :on-success="successUploadNewDraftedContract"
                  >
                    <v-btn text
                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                    >
                      <v-icon color="primary" size="24" style="margin-right:12px;">
                        mdi-upload
                      </v-icon>
                      <div>Upload New Contract</div>
                    </v-btn>
                  </x-upload>
                </div>
              </div>
              <file-list
                :files="newDraftedContract.files"
                :multiple-rows="windowWidth < 650"
                :style="{
                  'height': 'calc(100% - 45px - ' + (isMobile ? newDraftedContractCommentBoxRows * 18 + 98 : 236) + 'px)',
                  'min-height': '60px',
                  'padding': '0 12px',
                  'padding-bottom': '40px',
                  'overflow-y': 'auto'
                }"
                @click-download="downloadFile"
                @click-delete="deleteNewDraftedContract"
              ></file-list>
              <div
                class="contract-review-comments-container"
                :style="isMobile ? { 'padding-bottom': '3px' } : { 'padding': '12px 18px' }"
              >
                <div :style="{'text-align': 'left','font-size': '14px',padding: '2px ' + ( isMobile ? '18px' : '0')}">Comments</div>
                <textarea-at
                  v-model="newDraftedContract.comments"
                  :rows="isMobile ? newDraftedContractCommentBoxRows : 4"
                  placeholder="Please input comments (if any)"
                  :counter="commentsMax"
                  solo
                  no-resize
                
                  @input="draftCommentInput"
                  @at="draftCommentAt"
                  @at-insert="draftCommentAtInsert"
                >
                </textarea-at>
              </div>

              <div v-if="!isMobile" style="padding: 0 18px 2px;text-align: right;">
                <v-btn color="primary"
                  :disabled="newDraftedContract.comments ? newDraftedContract.comments.length > commentsMax : false"
                  @click="confirmNewDraftedContract"
                >
                  Update
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="toShowGenerateContract"
          persistent
          fullscreen
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <v-card class="generate-contract-card">
            <v-list class="sidebar-top">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile && !isFreshContractPreviewing && !isDraftStepInContractType" text icon @click="toShowGenerateContract = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    <span 
                      v-if="isDraftStepInContractType || isFreshContractPreviewing"
                    >Generate Contract</span>
                    <span v-else>Contract Information</span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <div style="display: flex">
                    <v-tooltip bottom :disabled="isMobile">
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="primary" class="ml-3"
                          v-if="canComment"
                          @click="showSendComments(0)"
                          v-on="on"
                        >
                          <v-icon>mdi-forum-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Comment</span>
                    </v-tooltip>
                    <v-btn v-if="!isMobile && !isFreshContractPreviewing && !isDraftStepInContractType" text icon @click="toShowGenerateContract = false" class="ml-2">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div
              :style="{
                'position': 'absolute',
                /*'top': (isMobile ? '50' : '62') + 'px',*/
                /*'left': isMobile ? '0' : '9px',*/
                /*'right': isMobile ? '0' : '9px',*/
                'top': '64px',
                'left': '0',
                'right': '0',
                'bottom': isMobile ? '0' : '65px',
                'overflow': 'hidden',
                'border-radius': isMobile ? '0' : '3px'
              }"
            >
              <div style="position: relative;width: 100%;height: 100%; background-color: rgba(209, 209, 209);">
                <v-layout wrap pl-2
                  :style="{
                    'height': '100%',
                    'overflow': $vuetify.breakpoint.lgAndUp ? 'hidden' : 'auto',
                    'border-top': '1px solid lightgrey',
                    'border-bottom': '1px solid lightgrey'
                  }"
                >
                  <v-flex
                    xs12
                    lg6
                    px-2
                    pt-0
                    pb-4
                    :style="{
                      'height': $vuetify.breakpoint.lgAndUp ? '100%' : 'auto',
                      'overflow': $vuetify.breakpoint.lgAndUp ? 'auto' : 'hidden'
                    }"
                  >
                    <material-app-card
                      id="contract-review-draft-step-1-generate"
                      color="primary"
                      icon="mdi-file-document-edit-outline"
                      :title="(isPendingContract ? '3.0 ' : '') + (isDraftStepInContractType || isFreshContractPreviewing ? 'Generate Contract' : 'Contract Information')"
                      :style="{
                        'margin-top': '32px'
                      }"
                    >
                      <template v-slot>
                        <v-form ref="contract-info-form-generate" :lazy-validation="true">
                          <v-layout row wrap class="v-card-x-layout">
                            <v-flex xs12
                              v-if="isContractLoading"
                              :style="{
                                margin: '12px',
                                display: 'flex',
                                'align-items': 'center',
                                'justify-content': 'center'
                              }"
                            >
                              <v-progress-circular
                                :width="4"
                                color="grey"
                                indeterminate
                                :size="24"
                              ></v-progress-circular>
                              <div
                                class="grey--text text--lighten-1"
                                :style="{
                                  'margin-left': '9px'
                                }"
                              >
                                Loading
                              </div>
                            </v-flex>
                            <template v-else>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="languageAvailable"
                              >
                                <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(contractInfoChecked && !contract.language ? ['red--text'] : []), ...(isDraftStepInContractType ? [] : ['my-disabled--text'])]">Language</div>
                                <div>
                                  <v-radio-group row class="contract-radio-group-horizonal"
                                    :disabled="!canEditContractForm"
                                    v-model="contract.language"
                                    :rules="[v => (!!v && v != '') || 'Please select']"
                                  >
                                    <v-radio label="English" value="English" color="primary"></v-radio>
                                    <v-radio label="中文" value="Chinese" color="primary"></v-radio>
                                  </v-radio-group>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="contract_form_language"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="contractTemplateAvailable"
                              >
                                <div>
                                  <v-select
                                    :disabled="!canEditContractForm"
                                    label="Contract Template"
                                    :items="contractTemplateList"
                                    v-model="contract.usedTemplate"
                                    ref="contract_form_template"
                                    :rules="[v => !!contractTemplateList.find(el => el === v) || 'Please select']"
                                  >
                                  </v-select>
                                </div>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                                <div>
                                  <v-select
                                    :disabled="!canEditContractForm"
                                    label="Sales Organization"
                                    :items="salesOrganizationListForSelect"
                                    :item-text="languageAvailable ? (contract.language === 'Chinese' ? 'textCn' : 'textEn') : 'text'"
                                    :placeholder="salesOrganizationPlaceholder"
                                    v-model="contract.salesOrganization"
                                    ref="contract_form_sales_organization"
                                    :rules="[v => !!salesOrganizationListForSelect.find(el => el.value === v) || 'Please select']"
                                  >
                                  </v-select>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                                <div>
                                  <v-autocomplete
                                    :disabled="!canEditContractForm"
                                    label="Sold To Party (SAP)"
                                    ref="contract_form_sap"
                                    no-filter
                                    :search-input.sync="contract.sapKeyGen"
                                    v-model="contract.sap"
                                    :items="contract.saps"
                                    :loading="isSapsLoading"
                                    :rules="[v => !!contract.sap || v != '' || 'Please input and select']"
                                    @focus="focusSapGen"
                                    @blur="blurSapGen"
                                    @input="inputSapGen"
                                  ></v-autocomplete>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                                <div>
                                  <v-autocomplete
                                    v-if="contract.sap"
                                    :disabled="!canEditContractForm"
                                    label="Customer Number (SAP)"
                                    ref="contract_form_customer_number"
                                    v-model="contract.customerNumber"
                                    :items="contract.customerNumbers"
                                    :rules="[v => !!contract.customerNumber || v != '' || 'Please input and select']"
                                  ></v-autocomplete>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="amountAvailable"
                              >
                                <div class="v-card-contract-amount">
                                  <v-text-field
                                    append-icon="mdi-pencil"
                                    :label="'Amount (Including Tax)' + (!!contractAmountToThousands ? ': ' + contractAmountToThousands : '')"
                                    type="number"
                                    :disabled="!canEditContractForm"
                                    v-model="contract.amount"
                                    @blur="blurContractAmount"
                                    ref="contract_form_amount"
                                    :rules="[
                                      v => !isNaN(parseFloat(v)) || 'Please input number',
                                      v => parseFloat(v) > 0 || 'Amount should be greater than 0'
                                    ]"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="currencyAvailable"
                              >
                                <div>
                                  <v-select
                                    :disabled="!canEditContractForm"
                                    label="Currency"
                                    :items="contractCurrencyList"
                                    v-model="contract.currency"
                                    ref="contract_form_currency"
                                    :rules="[v => (!!v && v != '') || 'Please select']"
                                  >
                                  </v-select>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                                <div>
                                  <v-dialog
                                    ref="startDateGenerator"
                                    v-model="showingStartDatePicker"
                                    :return-value.sync="contract.startDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="contract.startDate"
                                        label="Contract Start Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditContractForm"
                                        ref="contract_form_start_date"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="contract.startDate" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedStartDates"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="showingStartDatePicker = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="changeStartDate">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                                <div>
                                  <v-dialog
                                    ref="endDateGenerator"
                                    v-model="showingEndDatePicker"
                                    :return-value.sync="contract.endDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="contract.endDate"
                                        label="Contract End Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditContractForm"
                                        ref="contract_form_end_date"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="contract.endDate" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedEndDates"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="showingEndDatePicker = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="changeEndDate">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="billingTermsAvailable"
                              >
                                <div>
                                  <v-select
                                    :disabled="!canEditContractForm"
                                    label="Billing Terms"
                                    :items="billingTermsValues"
                                    v-model="contract.billingTerms"
                                    ref="contract_form_billing_terms"
                                    :rules="[v => !!billingTermsValues.find(el => el.value === v) || 'Please select']"
                                    @change="changeBillingTerm"
                                  >
                                  </v-select>
                                </div>
                                <template v-if="billingTermsOthersAvailable">
                                  <div :class="['v-card-x-cell-title', ...(contractInfoChecked && !contract.billingTermsOthers ? ['red--text'] : [])]">Billing Terms - Specify</div>
                                  <div class="title-solo-textarea">
                                    <v-textarea
                                      solo
                                      no-resize
                                      rows="3"
                                      placeholder="Please specify"
                                      style="margin-top: 0px"
                                      :counter="termsOthersMax"
                                      v-model="contract.billingTermsOthers"
                                      :readonly="!canEditContractForm"
                                      ref="contract_form_billing_terms_others"
                                      :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                      @input="changeBillingTermSpec"
                                    >
                                    </v-textarea>
                                  </div>
                                </template>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="paymentTermsAvailable"
                              >
                                <div>
                                  <v-select
                                    :disabled="!canEditContractForm"
                                    label="Payment Terms"
                                    :items="paymentTermsValues"
                                    v-model="contract.paymentTerms"
                                    ref="contract_form_payment_terms"
                                    :rules="[v => !!paymentTermsValues.find(el => el.value === v) || 'Please select']"
                                    @change="changePaymentTerm"
                                  >
                                  </v-select>
                                </div>
                                <template v-if="paymentTermsOthersAvailable">
                                  <div :class="['v-card-x-cell-title', ...(contractInfoChecked && !contract.paymentTermsOthers ? ['red--text'] : [])]">Payment Terms - Specify</div>
                                  <div class="title-solo-textarea">
                                    <v-textarea
                                      solo
                                      no-resize
                                      rows="3"
                                      placeholder="Please specify"
                                      style="margin-top: 0px"
                                      :counter="termsOthersMax"
                                      v-model="contract.paymentTermsOthers"
                                      :readonly="!canEditContractForm"
                                      ref="contract_form_payment_terms_others"
                                      :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                      @input="changePaymentTermSpec"
                                    >
                                    </v-textarea>
                                  </div>
                                </template>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="false && msalsaAvailable"
                              >
                                <div class="v-card-x-cell-title v-card-x-cell-title-absolute">Follow MSA/LSA</div>
                                <v-tooltip right nudge-top="60" :disabled="isMobile">
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      small
                                      v-on="on"
                                      style="margin-left: 12px !important;"
                                    >
                                      mdi-help-circle
                                    </v-icon>
                                  </template>
                                  <div style="text-align: left;padding: 6px;">
                                    <div style="margin-bottom: 3px;width: 180px;">MSA/LSA stand for Master Service Agreement/Local Service Agreement If 'Yes', this contract will follow previous MSA/LSA with this client. If 'No', this contract will not follow previous MSA/LSA with this client. If 'N/A', it does not have MSA/LSA.</div>
                                  </div>
                                </v-tooltip>
                                <div>
                                  <v-radio-group row class="contract-radio-group-horizonal" :disabled="!canEditContractForm"
                                    v-model="contract.msalsa"
                                  >
                                    <v-radio label="Yes" value="Yes" color="primary"></v-radio>
                                    <v-radio label="No" value="No" color="primary"></v-radio>
                                    <v-radio label="N/A" value="NA" color="primary"></v-radio>
                                  </v-radio-group>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="contract_form_msalsa"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 pt-3 pl-3 pr-3 pb-3 class="v-card-x-cell"
                                v-if="sowAvailable"
                              >
                                <div
                                  v-if="!canEditContractForm"
                                  class="v-card-x-cell-title pb-1"
                                >
                                  Scope of Work (SOW - Word .DOCX Only)
                                </div>
                                <div v-else style="width: 100%;display: flex;align-items: center;">
                                  <x-upload
                                    request-class="file"
                                    request-function="upload"
                                    accept=".docx"
                                    :params="{
                                      email: userEmail,
                                      loginStatus: loginStatus
                                    }"
                                    :before-upload="beforeUploadSow"
                                    :on-error="errorUploadSow"
                                    :on-success="successUploadSow"
                                  >
                                    <v-btn text
                                      v-if="contract.sow.length === 0"
                                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    >
                                      <v-icon color="primary" size="24" style="margin-right:12px;">
                                        mdi-upload
                                      </v-icon>
                                      <div>Upload Scope of Work (SOW - Word .DOCX Only)</div>
                                    </v-btn>
                                  </x-upload>

                                  <v-btn text
                                    v-if="contract.sow.length > 0"
                                    style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    @click="isSowUploadConfirming = true"
                                  >
                                    <v-icon color="primary" size="24" style="margin-right:12px;">
                                      mdi-upload
                                    </v-icon>
                                    <div>Change Scope of Work (SOW - Word .DOCX Only)</div>
                                  </v-btn>
                                </div>

                                <v-expand-transition>
                                  <div 
                                    v-if="contractInfoChecked && contract.sow.length === 0" 
                                    class="red--text"
                                    style="font-size:12px;"
                                  >
                                    Please upload Scope of Work
                                  </div>
                                </v-expand-transition>
                                
                                <div style="padding-bottom: 16px;">
                                  <file-list
                                    :files="contract.sow"
                                    :deletable="canEditContractForm"
                                    :multiple-rows="windowWidth < 650"
                                    @click-download="downloadFile"
                                    @click-delete="deleteSow"
                                  ></file-list>
                                  <div
                                    tabindex="0"
                                    ref="contract_form_sow"
                                    style="height: 1px;"
                                  ></div>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="projectDescriptionAvailable"
                              >
                                <div class="v-card-x-cell-title">Project Description</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    ref="contract_form_project_description"
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please specify"
                                    style="margin-top: 0px"
                                    :counter="commentsMax"
                                    v-model="contract.projectDescription"
                                    :readonly="!isDraftStepInContractType"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changeProjectDescriptions"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                            </template>
                          </v-layout>
                        </v-form>
                      </template>
                    </material-app-card>
                  </v-flex>

                  <v-flex
                    v-if="isDraftStepInContractType && !isPreviewContractLoading"
                    xs12
                    lg6
                    pl-2
                    pr-4
                    pt-0
                    pb-4
                    :style="{
                      'height': $vuetify.breakpoint.lgAndUp ? '100%' : 'auto',
                      'overflow-x': 'hidden',
                      'overflow-y': $vuetify.breakpoint.lgAndUp ? 'auto' : 'hidden'
                    }"
                  >
                    <div
                      id="contract-generater-button"
                      class="pl-10 d-flex align-center"
                      :style="{
                        'margin-top': $vuetify.breakpoint.lgAndUp ? '32px' : '0px',
                        'min-height': $vuetify.breakpoint.lgAndUp ? 'calc(100% - 32px)' : '120px',
                      }"
                    >
                      <span>Please finish request form, then click</span>
                      <v-btn color="primary"
                        class="mx-2"
                        @click="postGenerateContractPreview"
                      >
                        Generate
                      </v-btn>
                      <span>to preview contract.</span>
                    </div>
                  </v-flex>
                  <v-flex
                    v-else
                    xs12
                    lg6
                    px-2
                    pt-0
                    pb-4
                    style="position:relative;height:100%;overflow:hidden;"
                  >
                    <div
                      :style="{
                        'position': 'absolute',
                        'top': '0',
                        'left': '0',
                        'right': '0',
                        'bottom': '0',
                        'overflow': 'hidden',
                        'background-color': 'rgb(209,209,209)'
                      }"
                    >
                      <div style="position: relative;width: 100%;height: 100%;">
                        <div
                          v-if="isPreviewContractLoading || !contractPdfMsPath || !contractPreviewSrc"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgb(209,209,209);
                          "
                        >
                          <div style="text-align:center">
                            <v-progress-circular
                              :width="5"
                              color="grey"
                              indeterminate
                              :size="30"
                            ></v-progress-circular>
                            <div
                              class="grey--text text--darken-1"
                              :style="{
                                'margin-top': '9px',
                                'font-size': '14px'
                              }"
                            >
                              {{
                                isPreviewContractLoading
                                  ? 'Generating'
                                  : 'Loading'
                              }}
                            </div>
                          </div>
                        </div>
                        <iframe ref="contractPreviewiFrame" v-else :src="contractPreviewSrc" width="100%" height="100%" frameborder="0"></iframe>
                        <!-- <div v-else style="color: white;padding: 6px;">* Contract template will show in this grey box</div> -->
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </div>

            <div v-if="!isMobile" style="position: absolute;bottom: 0;left: 0;right: 0;padding: 2px 12px;display: flex;">
              <v-btn color="primary"
                v-if="canEditContractForm"
                @click="closeGenerateContract"
              >
                Previous
              </v-btn>
              <v-btn color="primary"
                v-if="isDraftStepInGeneratedContract && isFreshContractPreviewing"
                @click="reEditPreviewingContractV3"
              >
                Re-edit
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                v-if="!canEditContractForm && !isPreviewContractLoading && contractDocMsPath"
                @click="downloadDoc"
              >
                Download as {{ isApprovedContract ? 'PDF' : 'Word' }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                v-if="canEditContractForm"
                @click="postGenerateContractPreview"
              >
                Generate
              </v-btn>
              <v-btn color="primary"
                v-if="isDraftStepInGeneratedContract && isFreshContractPreviewing"
                @click="toShowGenerateContract = false"
              >
                Next
              </v-btn>
              <v-btn color="primary"
                v-if="canApprove && isContractGeneratorUsed"
                @click="isContractApproving = true"
              >
                Approve
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isContractRecalling"
          persistent
          width="fit-content"
        >
          <v-card class="client-project-contract-sign-type-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Recall</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isContractRecalling = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            
            <div style="text-align: left;padding: 12px 24px;display: flex;align-items: start;">
              <v-icon color="warning darken-1" style="margin-top: -3px;margin-right: 9px">mdi-help-circle</v-icon>
              <slot>
                <span>Do you confirm to recall this request?
                  {{
                    cntCanRecall === 1 ?
                      canRecallSubmit ? 'Approval process will restart from the very beginning.' :
                      canRecallSign ? 'Signature process will restart.' :
                      '' 
                    : ''
                  }}
                </span>
              </slot>
            </div>

            <v-form ref="client-project-contract-recall-form" :lazy-validation="true">
              <v-radio-group v-if="cntCanRecall > 1" class="client-project-contract-radio-group-vertical client-project-contract-recall-radio-group mx-4 my-3"
                v-model="recall.type"
                :rules="[v => !!v || 'Please select']"
              >
                <v-radio class="py-2" :value="1" color="primary"
                  v-if="canRecallSubmit"
                >
                  <template v-slot:label>
                    <div>
                      <div>Resubmit</div>
                      <div class="client-project-contract-recall-radio-description">Approval process will restart from the very beginning</div>
                    </div>
                  </template>
                </v-radio>
                <v-radio class="py-2" :value="2" color="primary"
                  v-if="canRecallSign"
                >
                  <template v-slot:label>
                    <div>
                      <div>Resign</div>
                      <div class="client-project-contract-recall-radio-description">Signature process will restart</div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-form>

            <div :class="{'mt-3': (cntCanRecall === 1)}" style="padding: 0 16px 8px;text-align: right;">
              <v-btn color="primary"
                :disabled="!recall.type"
                @click="postRecallContract"
              >
                Yes
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignTypeSelecting"
          persistent
          width="fit-content"
        >
          <v-card class="client-project-contract-sign-type-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Sign</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isSignTypeSelecting = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-radio-group class="client-project-contract-radio-group-vertical client-project-contract-sign-con-type-radio-group mx-3 mt-0 mb-2"
              v-model="signFlow.type"
              :rules="[v => !!v || 'Please select']"
            >
              <v-radio class="py-2" :value="1" color="primary" key="client-project-contract-sign-type-radio-physical-chop">
                <template v-slot:label>
                  <div>
                    <div>Physical Chop</div>
                    <div class="client-project-contract-sign-con-type-radio-description">Cannot be recalled once selected</div>
                  </div>
                </template>
              </v-radio>
              <v-radio class="py-2" :value="2" color="primary" key="client-project-contract-sign-type-radio-e-chop">
                <template v-slot:label>
                  <div>
                    <div>E Chop</div>
                    <div class="client-project-contract-sign-con-type-radio-description">Send a link to client/vendor for chop</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>

            <div style="padding: 0 16px 8px;text-align: right;">
              <v-btn color="primary"
                :disabled="!signFlow.type"
                :loading="isSignTypeNextLoading"
                @click="finishSelectType"
              >
                Next
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignerSelecting"
          persistent
          width="fit-content"
        >
          <v-card class="client-project-contract-signer-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Signers</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon
                    :disabled="isSignFlowCreating"
                    @click="isSignerSelecting = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-form ref="client-project-contract-signer-form" :lazy-validation="true">
              <v-layout wrap>
                <v-flex xs12 mx-4 mt-3 mb-2>
                  <div class="sign-flow-signer-header">Internal Signer</div>
                </v-flex>
                <v-flex xs12 mx-4>
                  <v-autocomplete
                    :disabled="false"
                    label="Legal Entity Name"
                    placeholder="Please select"
                    v-model="signFlow.intSigner.organize.name"
                    :items="intSignerOrganizeNames"
                    :rules="[v => !!v || '']"
                  >
                  </v-autocomplete>
                </v-flex>
                <template
                  v-if="signFlow.extSigner.need"
                >
                  <v-flex xs12 mx-4 mt-4 mb-2>
                    <div class="sign-flow-signer-header">External Signer</div>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Legal Entity Name"
                      placeholder="Please input"
                      no-filter
                      :return-object="false"
                      item-value="name"
                      :search-input.sync="extSignerOrganizeNameKey"
                      v-model="signFlow.extSigner.organize.name"
                      :items="extSignerOrganizeNames"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerOrganizeName"
                      @blur="() => signFlow.extSigner.organize.name = extSignerOrganizeNameKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.name }}</div>
                          <div v-if="item.hasAgent">
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" size="18" v-on="on">
                                  playlist_add_check
                                </v-icon>
                              </template>
                              <span>Liaison in Legal Entity</span>
                            </v-tooltip>
                          </div>
                          <div v-else>
                            <v-icon color="grey lighten-1" size="18">
                              list
                            </v-icon>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Name"
                      placeholder="Please input"
                      :return-object="false"
                      item-text="name"
                      item-value="name"
                      :search-input.sync="extSignerAgentNameKey"
                      v-model="signFlow.extSigner.agent.name"
                      :items="extSignerAgentNames"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentName"
                      @blur="() => signFlow.extSigner.agent.name = extSignerAgentNameKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.name }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Mobile No."
                      placeholder="Please input"
                      :return-object="false"
                      item-value="mobile"
                      item-text="mobile"
                      :search-input.sync="extSignerAgentMobileKey"
                      v-model="signFlow.extSigner.agent.mobile"
                      :items="extSignerAgentMobiles"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentMobile"
                      @blur="() => signFlow.extSigner.agent.mobile = extSignerAgentMobileKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.mobile }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Email Address"
                      placeholder="Please input"
                      :return-object="false"
                      item-value="email"
                      item-text="email"
                      :search-input.sync="extSignerAgentEmailKey"
                      v-model="signFlow.extSigner.agent.email"
                      :items="extSignerAgentEmails"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentEmail"
                      @blur="() => signFlow.extSigner.agent.email = extSignerAgentEmailKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.email }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                </template>
              </v-layout>
            </v-form>

            <div style="padding: 0 16px 8px;text-align: right;" class="mt-3">
              <v-btn color="primary"
                :loading="isSignFlowCreating"
                @click="createSignFlow"
              >
                Next
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignUrlDisplayed"
          persistent
          width="fit-content"
        >
          <v-card class="client-project-contract-signer-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Sign</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isSignUrlDisplayed = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-layout wrap pb-10>
              <v-flex xs12 mx-4 mt-2 mb-2>
                <div style="display: flex; align-items: center;">
                  <div class="sign-flow-info-sign-header">Internal Sign</div>
                  <div class="ml-4">
                    <!-- xxxxx -->
                    <v-btn
                      small text icon
                      class="my-0"
                      color="primary"
                      @click="clickIntLink"
                    >
                      <v-icon>mdi-arrow-right-circle</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-flex>
              <v-flex v-if="false" xs12 mx-4>
                <div class="sign-flow-info-sign-content grey--text">URL: {{ signFlow.intSigner.url }}</div>
              </v-flex>
              <v-flex xs12 mx-4 mt-3 mb-3>
                <div class="sign-flow-info-sign-content red--text">NOTE:</div>
                <div class="sign-flow-info-sign-content red--text mb-3">* Signature Consant Password: {{ signFlow.intSigner.pwd }}</div>
                <div class="sign-flow-info-sign-content red--text">* location of official seal (The Nielsen Company GZ Limited): GZ Office</div>
                <div class="sign-flow-info-sign-content red--text">* location of contract seal (The Nielsen Company GZ Limited): SH Office</div>
              </v-flex>
              <template v-if="isExtSigningContract && signFlow.extSigner.need">
                <v-flex xs12 mx-4 mt-3 mb-2>
                  <div class="sign-flow-info-sign-header">External Sign</div>
                </v-flex>
                <v-flex v-if="false" xs12 mx-4 mt-2>
                  <div class="sign-flow-info-sign-content grey--text">URL: {{ signFlow.extSigner.url }}</div>
                </v-flex>
              <v-flex xs12 mx-4 mt-2>
                <div class="sign-flow-info-sign-content primary--text">* Notification email has been sent.</div>
              </v-flex>
              </template>
            </v-layout>

          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isContractApproving"
          persistent
          width="fit-content"
        >
          <v-card class="approve-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Approve</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isContractApproving = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div style="position: absolute;top: 64px;bottom: 0;left: 0;right: 0;overflow: visible;">
              <div style="text-align: left;padding: 3px 16px;">
                Please confirm the approval of this contract.
              </div>
              <div
                class="contract-review-comments-container"
                :style="{ 'padding': '12px 16px' }"
              >
                <textarea-at
                  v-model="contract.approvalComments"
                  :rows="3"
                  placeholder="Please input comments (if any)"
                  hide-details
                  solo
                  no-resize
                
                  @input="approvalCommentInput"
                  @at="approvalCommentAt"
                  @at-insert="approvalCommentAtInsert"
                >
                </textarea-at>
              </div>

              <div style="padding: 0 16px 2px;text-align: right;">
                <v-btn color="primary" @click="postApproveContract">
                  Approve
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSowUploadConfirming"
          persistent
          width="400px"
        >
          <v-card class="pt-6">
            <v-card-text v-if="contract.sow.length > 0">
              Replace Scope of Work (SOW - Word .DOCX Only) with the upload file?
            </v-card-text>
            <v-card-text v-else>
              Upload Scope of Work (SOW - Word .DOCX Only)
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                style="min-width: 90px;"
                flat
                @click="isSowUploadConfirming = false"
              >
                No
              </v-btn>
              <x-upload
                request-class="file"
                request-function="upload"
                accept=".docx"
                :params="{
                  email: userEmail,
                  loginStatus: loginStatus
                }"
                :before-upload="beforeUploadSow"
                :on-error="errorUploadSow"
                :on-success="successUploadSow"
              >
                <v-btn
                  dark
                  color="primary"
                  style="min-width: 90px;margin-left: 9px !important;"
                >
                  Yes
                </v-btn>
              </x-upload>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isCommentsSending"
          persistent
          width="fit-content"
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
          style="overflow: visible"
        >
          <v-card class="comments-card">
            <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile" text icon @click="closeSendComments">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    {{ isContractSubmitting ? 'Send For Approval' : 'Comments' }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn v-if="isMobile && !isContractSubmitting" text icon @click="postSendComments">
                    <v-icon>check</v-icon>
                  </v-btn>
                  <v-btn v-else text icon @click="closeSendComments">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-form ref="contract-comment-form">
              <div :style="{position: 'absolute',top: '72px',bottom: 0,left: 0,right: 0,overflow: 'visible'}">
                <div class="contract-review-comments-container contract-review-comments-container-icon"
                  v-if="isContractSubmitting"
                  :style="{
                    'text-align': 'left',
                    ... isMobile ? { 'padding-bottom': '6px' } : { 'padding': '0 16px 12px' }
                  }"
                >
                  <v-icon color="warning darken-1">
                    mdi-help-circle
                  </v-icon>
                  <div>
                    Your Request will be sent to
                    <span class="contract-review-comments-container-icon-bold">{{ contract.managerApprover.name + ' (' + contract.managerApprover.email + ')' }}</span>
                    for approval. Do you confirm to submit this request?
                  </div>
                </div>

                <div
                  class="contract-review-comments-container"
                  v-if="false && isCurrentApprover"
                  :style="isMobile ? { 'padding': '12px 15px 6px' } : { 'padding': '8px 16px 0' }"
                >
                  <person-select
                    v-model="comments.to"
                    label="Comment to"
                    :items="listApproverCommentTo"
                    :rules="[v => (!!v && !!listApproverCommentTo.find(el => el.email === v.email)) || 'Please select']"
                  >
                  </person-select>
                </div>

                <div
                  class="contract-review-comments-container"
                  :style="isMobile ? { 'padding-bottom': '6px' } : { 'padding': '8px 16px 0' }"
                >
                  <textarea-at
                    v-model="comments.content"
                    :rows="(isMobile ? commentBoxRows : 7) - (isContractSubmitting ? 3 : 0) - (false && isCurrentApprover ? 4 : 0)"
                    :placeholder="`Please input comments${isContractSubmitting ? ' (If any)' : ''}`"
                    :counter="commentsMax"
                    :solo="!isMobile"
                    no-resize
                    :rules="[...(isContractSubmitting ? [] : [v => (!!v && v.toString().trim() != '') || 'Please input'])]"

                    @input="commentInput"
                    @at="commentAt"
                    @at-insert="commentAtInsert"
                  >
                  </textarea-at>
                </div>
                <div style="display: flex; align-items: center;justify-content: space-between;padding: 0 12px;">
                  <x-upload
                    request-class="file"
                    request-function="upload"
                    :params="{
                      email: userEmail,
                      loginStatus: loginStatus
                    }"
                    :before-upload="beforeUploadAttachment"
                    :on-error="errorUploadAttachment"
                    :on-success="successUploadAttachment"
                  >
                    <v-btn text
                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                    >
                      <v-icon color="primary" size="24" style="margin-right:12px;">
                        mdi-upload
                      </v-icon>
                      <div>Upload Supporting Documents (If Any)</div>
                    </v-btn>
                  </x-upload>
                </div>
                <file-list
                  :files="comments.files"
                  :multiple-rows="windowWidth < 650"
                  :style="{
                    'height': 'calc(100% - ' + ((isMobile ? commentBoxRows * 18 + 24 : 244) + (false && isCurrentApprover ? 36 : 32) + 42) + 'px)',
                    'padding': '0 12px',
                    'padding-bottom': (isMobile ? 60 : 0) + 'px',
                    'overflow-y': 'auto'
                  }"
                  @click-download="downloadFile"
                  @click-delete="deleteAttachment"
                ></file-list>

                <div v-if="!isMobile" style="padding: 6px 16px;text-align: right;">
                  <v-btn v-if="isContractSubmitting" color="primary"
                    :disabled="comments.content ? comments.content.length > commentsMax : false"
                    @click="postSubmitContract"
                  >
                    Submit
                  </v-btn>
                  <v-btn v-else color="primary"
                    @click="postSendComments"
                  >
                    Send
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-card>
        </v-dialog>

        <confirm-dialog
          :card-class="['confirm-card']"
          v-model="isDraftStepMoveConfirming"
          title="Previous"
          comments="Do you confirm to go back to previous step? (Some information may be lost if you go to previous step)"
          @click-yes="draftStepMove"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['confirm-card']"
          v-model="isContractAbandoning"
          title="Abandon"
          comments="Do you confirm to abandon this contract?"
          @click-yes="postAbandonContract"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['confirm-card']"
          :value="false && isContractSubmitting"
          title="Send for Approval"
          :comments="'Your Request will be sent to ' + contract.managerApprover.name + ' (' + contract.managerApprover.email + ') for approval. Are you sure to submit this request?'"
          @click-yes="postSubmitContract"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['confirm-card']"
          v-model="isNewDraftedContractUploadConfirming"
          comments="Do you conrfirm to upload new contract version to replace the previous version?"
          @click-yes="postReviseDraftedContract"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['client-project-contract-confirm-card']"
          v-model="isExtSignerConfirming"
          title="External Signer Confirm"
          @click-yes="clickConfirmExtSigner"
          @click-no="cancelConfirmExtSigner"
          @click-close="closeConfirmExtSigner"
        >
          <div>
            <div class="mb-2" v-if="msgExtOrg">{{ msgExtOrg }}</div>
            <div class="mb-2" v-if="msgExtAgent">{{ msgExtAgent }}</div>
            <div class="mb-2" v-if="msgExtOrgXAgent">{{ msgExtOrgXAgent }}</div>
          </div>
        </confirm-dialog>

        <confirm-dialog
          :card-class="['client-project-contract-confirm-card']"
          v-model="isContractArchiving"
          icon="mdi-information"
          icon-color="primary"
          title="Archive"
          comments="Do you confirm to archive the contract?"
          @click-yes="postArchive"
        ></confirm-dialog>

        <confirm-dialog unavailable for signing now
          :card-class="['nielsen-entity-alert-card']"
          v-model="isNielsenEntityExpiredAlertDisplaying"
          title="Issue - Sales Organization"
          icon="mdi-alert"
          icon-color="red"
          yes-text="OK"
          @click-yes="isNielsenEntityExpiredAlertDisplaying = false"
        >
          <span>Sales Organization <b>"{{ salesOrganizationInLanguage }}"</b> is unavailable for signing now. <br/>For any query, please contact Scarlett (scarlett.liao.consultant@nielseniq.com) for solution.</span>
        </confirm-dialog>

        <confirm-dialog
          :card-class="['nielsen-entity-alert-card']"
          v-model="isNielsenEntityPhysicalSignOnlyDisplaying"
          title="Issue - Sales Organization"
          icon="mdi-alert"
          icon-color="red"
          yes-text="OK"
          @click-yes="isNielsenEntityPhysicalSignOnlyDisplaying = false"
        >
          <span>Sales Organization <b>"{{ salesOrganizationInLanguage }}"</b> is only for <b>offline signing</b> now. <br/>For any query, please contact Scarlett (scarlett.liao.consultant@nielseniq.com) for solution.</span>
        </confirm-dialog>
      </v-layout>
    </div>
  </v-fade-transition>
</template>

<script>
import { isProductEnv, isCdRevEnv } from '@/config'
import { mapState, mapGetters, mapActions } from 'vuex'

import shareAccess from '@/components/app/dialog/share-access'
import forwardReviewer from '@/components/app/dialog/forward-reviewer'
import leaderSelect from '@/components/app/select/user/leader-select'
import userSelectSync from '@/components/app/select/user/user-select-sync'
import AccountProfile from '@/components/vuetify-extension/account-profile'
import confirmDialog from '@/components/vuetify-extension/confirm-dialog'
import fileList from '@/components/vuetify-extension/file-list'
import flexibleList from '@/components/vuetify-extension/flexible-list'
import activityHistory from '@/components/app/history'
import xUpload from '@/components/x-upload'
import personSelect from '@/components/vuetify-extension/person-select'
import TextareaAt from '@/components/vuetify-extension/textarea-at-v2'

import addOpportunityCard from '@/components/app/opportunity/x-opp-id-card'

import { xPost, xDownload } from '@/functions/http/axios'
import { toThousands } from '@/functions/maths'
import { docScrollToId } from '@/functions/ui/scroll.js'

import { financeRule } from '@/const/contract-rules.js'
import { setTimeout } from 'timers';

import MsalFunctions from '@/components/msal/functions/index.vue'

export default {
  components: {
    MsalFunctions,
    activityHistory,
    addOpportunityCard,
    confirmDialog,
    fileList,
    flexibleList,
    AccountProfile,
    leaderSelect,
    userSelectSync,
    shareAccess,
    forwardReviewer,
    xUpload,
    personSelect,
    TextareaAt,
  },
  data () {
    return {
      // props
      termsOthersMax: 500,
      commentsMax: 1000,

      addingOpportunity: false,
      fixingOpportunity: false,

      opportunityKeywordField: 'id',
      opportunityKeywordSearching: '',
      opportunityKeywordSearched: '',

      showingStartDatePicker: false,
      showingEndDatePicker: false,
      showingStartDatePickerGenerator: false,
      showingEndDatePickerGenerator: false,

      // To be removed
      isContractPreviewing: false,

      isFreshContractPreviewing: false,
      
      toShowGenerateContract: false,
      
      tfPreviewingContract: null,
      isContractAbandoning: false,
      isContractSubmitting: false,
      isContractRecalling: false,
      isContractApproving: false,
      isDraftStepMoveConfirming: false,
      isCommentsSending: false,
      isAccessSharing: false,
      isReviewerForwarding: false,

      isSapsLoading: false,
      timeLoadSaps: null,

      isContractTemplateGetting: false,
      isContractSowsGetting: false,

      isSowUploadConfirming: false,
      isSowUploading: false,
      isDraftedContractUploading: false,
      isAttachmentUploading: false,
      isNewDraftedContractUploadCardShowing: false,
      isNewDraftedContractUploadConfirming: false,
      isNewDraftedContractUploading: false,
      isPreviewContractLoading: false,

      isContractLoading: true,
      
      isSignTypeSelecting: false,
      isSignTypeNextLoading: false,
      isSignerSelecting: false,
      isIntSignerLoading: false,
      isExtSignerLoading: false,
      isSignFlowCreating: false,
      isExtSignerConfirming: false,
      isSignUrlDisplayed: false,
      isContractArchiving: false,
      isNielsenEntityExpiredAlertDisplaying: false,
      isNielsenEntityPhysicalSignOnlyDisplaying: false,

      msgExtOrg: '',
      msgExtAgent: '',
      msgExtOrgXAgent: '',

    // time flag - 保证读取最新数据
      flagPostExtOrgs: null,
      flagPostExtAgents: [null, null],
      flagPostExtOrgsAndAgents: [null, null],

      // data
      contract: {
        amount: '',
        backgroundBriefing: '',
        billingTerms: '',
        billingTermsOthers: '',
        contractGenerator: '',
        costCenter: '',
        currency: '',
        draftStep: 0,
        draftedContract: [],
        endDate: '',
        toDraftStep: 0,
        farestDraftStep: 0,
        id: '',
        version: '',
        language: '',
        requestor: {
          email: '',
          name: '',
          icon: ''
        },
        managerApprover: {
          email: '',
          name: '',
          icon: ''
        },
        financeNeed: false,
        financeApprover: {
          email: '',
          name: '',
          icon: ''
        },
        financeApproversInTeam: [],
        legalApprover: {
          email: '',
          name: '',
          icon: ''
        },
        legalApproversInTeam: [],
        ciApprovalNeed: false,
        ciApprover: {
          email: '',
          name: '',
          icon: ''
        },
        ciApproversInTeam: [],
        cpgApprover: {
          email: '',
          name: '',
          icon: ''
        },
        cpgApproversInTeam: [],
        msalsa: '',
        paymentTerms: '',
        paymentTermsOthers: '',
        projectDescription: '',
        requestId: '',
        salesOrganization: '',
        sap: '',
        saps: [],
        sapKey: '',
        sapKeyGen: '',
        customerNumber: '',
        customerNumbers: [],
        sow: [],
        stage: '',
        stageNum: '',
        startDate: '',
        status: 1,
        team: '',
        type: '',
        usedTemplate: '',
        
        standardPaymentTermSap: '',
        
        records: {
          isStandardBillingTerm: false,
          isStandardPaymentTerm: false,
          standardsBillingTermsOpp: [],
        },
      
        archiveApprover: {
          email: null,
          name: null,
          icon: null,
        },

        signType: null,
        needExtSign: null,
        needArchive: null,
        canRecallArchive: null,
      },
      newDraftedContract: {
        files: [],
        comments: '',
      },
      contractPdfMsPath: '',
      contractDocMsPath: '',
      contractPdfLocal: '',
      contractDocLocal: '',
      contractPreviewSrc: null,
      opportunities: [],
      isLoadingOpportunity: true,
      opportunitiesToAdd: [],
      indexOfOpportunitiesToAdd: [],
      isLoadingNewOpportunities: false,
      isLoadingMoreNewOpportunities: false,
      batchOfNewOpportunity: 0,
      commentsAttachments: [],
      history: [],
      
      dummyContractsUnderReview: [{
        name: 'Contract Aligned'
      }],

      reviewers: [],
      collaborators: [],
      ats: [],
      
      archiveApprovers: [],

      comments: {
        to: null,
        content: null,
        files: [],

        defaultRequestors: {
          name: 'Requestors',
          email: 'Owner & Members',
          icon: require('@/static/img/avatars/team.jpg')
        }
      },
      approvalComments: '',

      extraRequestors: [],
      contractTypeList: [],
      salesOrganizationList: [],
      contractCurrencyList: [],
      billingTermsDefaultList: [],
      paymentTermsDefaultList: [],

      financeRule,

      leaderTitles: [],
      financeApproversOptions: [],
      legalApproversOptions: [],

      oppHoverStyle: {
        'background-color': 'rgba(150, 150, 150, .1)',
        cursor: 'pointer'
      },
      miniOppHoverStyle: {
        'background-color': 'rgba(150, 150, 150, .1)',
        cursor: 'pointer'
      },

      contractInfoChecked: false,
      approvalInfoChecked: false,

      extSignerOrganizeNameKey: '',
      // extSignerOrganizeNameItems: [],

      extSignerAgentNameKey: '',
      // extSignerAgentNameItems: [],

      extSignerAgentMobileKey: '',
      // extSignerAgentMobileItems: [],

      extSignerAgentEmailKey: '',
      // extSignerAgentEmailItems: [],

      recall: {
        type: null
      },

      signConf: {
        defSignType: null,
        needExtSign: null,
      },
      signFlow: {
        esignFlowId: null,
        type: null,
        intSigner: {
          organize: {
            id: null,
            name: null,
          },
          signed: null,
          url: null,
          pwd: null,
        },
        extSigner: {
          need: null,
          organize: {
            id: null,
            name: null,
          },
          agent: {
            id: null,
            name: null,
            mobile: null,
            email: null,
          },
          // organize and agent binded ?
          binded: false,
          signed: null,
          url: null,
        }
      },
      intOrganizes: [],
      extOrganizesXAgents: [],
      extAgentsXOrganizes: [],

      sapFocused: false,
      sapGenFocused: false,

      // msal
      refNo: Date.parse(new Date()),
    }
  },
  computed: {
    ...mapState('app', {
      windowWidth: state => state.windowSize.width,
      windowHeight: state => state.windowSize.height,
      themeColor: state => state.color
    }),
    ...mapGetters('app', [
      'isMobile'
    ]),
    ...mapGetters('user', [
      'costCenter',
      'loginStatus',
      'userEmail',
      'userName',
      'userPhotoUrl',
      'userTeam',
      'userLocation',
    ]),

    // msal
    refMsalFunction () {
      return `msal-function-${this.refNo}`
    },

    // ui
    miniList () {
      return this.windowWidth < 960
    },

    // contract info
    token () {
      return this.$route.params.token
    },
    isNewContract () {
      return this.token === 'new'
    },
    isDraftedContract () {
      return this.contract.stageNum == 1
    },
    isRecalledContract () {
      return this.contract.stageNum == 8
    },
    isPendingContract () {
      return this.isNewContract || this.isDraftedContract || this.isRecalledContract
    },
    isManagerApprovingContract () {
      return this.contract.stageNum == 2
    },
    isFinanceApprovingContract () {
      return this.contract.stageNum == 3
    },
    isLegalApprovingContract () {
      return this.contract.stageNum == 4
    },
    isCiApprovingContract () {
      return this.contract.stageNum == 9
    },
    isCpgApprovingContract () {
      return this.contract.stageNum == 5
    },
    isApprovalContract () {
      return (
        this.isManagerApprovingContract ||
        this.isFinanceApprovingContract ||
        this.isLegalApprovingContract ||
        this.isCiApprovingContract ||
        this.isCpgApprovingContract
      )
    },
    isJustApprovedContract () {
      return this.contract.stageNum == 6
    },
    isIntSigningContract () {
      return this.contract.stageNum == 10
    },
    isIntSignedContract () {
      return this.contract.stageNum == 11
    },
    isExtSigningContract () {
      return this.contract.stageNum == 12
    },
    isExtSignedContract () {
      return this.contract.stageNum == 13
    },
    isSigningContract () {
      return (
        this.isIntSigningContract
        || this.isIntSignedContract
        || this.isExtSigningContract
        || this.isExtSignedContract
      )
    },
    isArchivingContract () {
      return this.contract.stageNum == 14
    },
    isArchivedContract () {
      return this.contract.stageNum == 15
    },
    isApprovedContract () {
      return (
        this.isJustApprovedContract
        || this.isSigningContract
        || this.isArchivingContract
        || this.isArchivedContract
      )
    },
    
    isAbandonedContract () {
      return this.contract.stageNum == 7
    },

    // user role definition
    isRequestor () {
      return this.userEmail === this.contract.requestor.email
    },
    isManagerApprover () {
      return this.userEmail === this.contract.managerApprover.email
    },
    isFinanceViewer () {
      return this.userEmail === this.contract.financeApprover.email ||
        (this.contract.financeApproversInTeam.findIndex(el => el.email === this.userEmail) > -1)
    },
    isFinanceApprover () {
      return this.userEmail === this.contract.financeApprover.email ||
        (this.contract.financeApproversInTeam.findIndex(el => el.email === this.userEmail && (['Approver', 'Supporter'].indexOf(el.role) > -1)) > -1)
    },
    isLegalViewer () {
      return this.userEmail === this.contract.legalApprover.email ||
        (this.contract.legalApproversInTeam.findIndex(el => el.email === this.userEmail) > -1)
    },
    isLegalApprover () {
      return this.userEmail === this.contract.legalApprover.email ||
        (this.contract.legalApproversInTeam.findIndex(el => (el.email === this.userEmail) && (['Approver', 'Supporter'].indexOf(el.role) > -1)) > -1)
    },
    isCiViewer () {
      return this.userEmail === this.contract.ciApprover.email ||
        (this.contract.ciApproversInTeam.findIndex(el => el.email === this.userEmail) > -1)
    },
    isCiApprover () {
      return this.userEmail === this.contract.ciApprover.email ||
        (this.contract.ciApproversInTeam.findIndex(el => el.email === this.userEmail && (['Approver', 'Supporter'].indexOf(el.role) > -1)) > -1)
    },
    isCpgViewer () {
      return this.userEmail === this.contract.cpgApprover.email ||
        (this.contract.cpgApproversInTeam.findIndex(el => el.email === this.userEmail) > -1)
    },
    isCpgApprover () {
      return this.userEmail === this.contract.cpgApprover.email ||
        (this.contract.cpgApproversInTeam.findIndex(el => el.email === this.userEmail && (['Approver', 'Supporter'].indexOf(el.role) > -1)) > -1)
    },
    isArchiveApprover () {
      return (
        this.userEmail === this.contract.archiveApprover.email
        || !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Approver')
      )
    },
    isArchiveSupporter () {
      return !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Supporter')
    },
    isArchiveViewer () {
      return !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Viewer')
    },
    isReviewer () {
      return this.reviewers.findIndex(el => el.email === this.userEmail) > -1
    },
    isCollaborator () {
      return this.collaborators.findIndex(el => el.email === this.userEmail) > -1
    },
    isAt () {
      return this.ats.findIndex(el => el.email === this.userEmail) > -1
    },
    isCurrentApprover () {
      return (
        (this.isManagerApprovingContract && this.isManagerApprover) ||
        (this.isFinanceApprovingContract && this.isFinanceApprover) ||
        (this.isLegalApprovingContract && this.isLegalApprover) ||
        (this.isCiApprovingContract && this.isCiApprover) ||
        (this.isCpgApprovingContract && this.isCpgApprover) ||
        (this.isArchivingContract && (this.isArchiveApprover || this.isArchiveSupporter))
      )
    },
    isCurrentViewer () {
      return (
        (this.isFinanceApprovingContract && this.isFinanceViewer) ||
        (this.isLegalApprovingContract && this.isLegalViewer) ||
        (this.isCiApprovingContract && this.isCiViewer) ||
        (this.isCpgApprovingContract && this.isCpgViewer) ||
        (this.isArchivingContract && this.isArchiveViewer)
      )
    },
    isPastApprover () {
      let version = (this.contract.version || '1.0').split('.')[0]
      switch (version) {
        case '1':
          return (
            (this.isFinanceApprovingContract && this.isManagerApprover) ||
            (this.isLegalApprovingContract && (this.isManagerApprover || this.isFinanceApprover)) ||
            (this.isCpgApprovingContract && (this.isManagerApprover || this.isFinanceApprover || this.isLegalApprover)) ||
            (this.isApprovedContract && (this.isManagerApprover || this.isFinanceApprover || this.isLegalApprover || this.isCpgApprover))
          )
          break;
        default:
          return (
            (this.isCiApprovingContract && 
              this.isManagerApprover) 
            ||
            (this.isFinanceApprovingContract && (
              this.isManagerApprover || this.isCiApprover)) 
            ||
            (this.isCpgApprovingContract && (
              this.isManagerApprover || this.isCiApprover || this.isFinanceApprover)) 
            ||
            (this.isLegalApprovingContract && (
              this.isManagerApprover || this.isCiApprover || this.isFinanceApprover || this.isCpgApprover)) 
            ||
            (this.isApprovedContract && (
              this.isManagerApprover || this.isCiApprover || this.isFinanceApprover || this.isCpgApprover || this.isLegalApprover))
            ||
            (this.isArchivedContract && (
              this.isArchiveApprover || this.isArchiveSupporter))
          )
          break;
      }
    },

    // user access
    canView () {
      return (
        !this.isAbandonedContract
        && (
          this.isRequestor ||
          this.isCollaborator ||
          this.isReviewer ||
          this.isFinanceViewer ||
          this.isLegalViewer ||
          this.isCiViewer ||
          this.isCpgViewer ||
          this.isCurrentApprover ||
          this.isCurrentViewer ||
          this.isPastApprover ||
          this.isAt
        )
      )
    },
    canEdit () {
      return (
        !this.isMobile &&
        this.isPendingContract &&
        (
          this.isRequestor ||
          this.isCollaborator
        )
      )
    },
    canShare () {
      return (
        this.isRequestor ||
        this.isCollaborator
      )
    },
    canComment () {
      return (
          this.isApprovalContract && (
            this.isRequestor ||
            this.isCollaborator ||
            this.isReviewer ||
            this.isAt ||
            this.isCurrentApprover
          )
        ) || (
          this.isApprovedContract && (
            this.isRequestor ||
            this.isCollaborator
          )
        ) || (
          this.isArchivingContract && (
            this.isRequestor ||
            this.isCollaborator ||
            this.isReviewer ||
            this.isAt ||
            this.isCurrentApprover
          )
        )
    },    
    canRecallSubmit () {
      return (
        (this.isRequestor || this.isCollaborator)
        && (this.isApprovalContract || (this.isApprovedContract && !this.isArchivedContract) || (this.isArchivedContract && this.contract.canRecallArchive))
      )
    },
    canRecallSign () {
      return (
        (this.isRequestor || this.isCollaborator)
        && (this.isSigningContract || this.isArchivingContract || (this.isArchivedContract && this.contract.canRecallArchive))
      )
    },
    cntCanRecall () {
      return (
        this.canRecallSubmit * 1
        + this.canRecallSign * 1
      )
    },
    canRecall () {
      return !!this.cntCanRecall
    },
    canReviseContract () {
      return (
        this.isApprovalContract
        && (
          this.isRequestor ||
          this.isCollaborator
        )
      )
    },
    canAbandon () {
      return (
        !this.isNewContract &&
        (this.isDraftedContract || this.isRecalledContract) &&
        (
          this.isRequestor ||
          this.isCollaborator
        )
      )
    },
    canApprove () {
      return this.isApprovalContract && this.isCurrentApprover
    },

    isDraftStepInOpportunity () {
      return this.canEdit && !this.isLoadingOpportunity && this.contract.draftStep == 0
    },
    isDraftStepInContractType () {
      return this.canEdit && this.contract.draftStep == 1
    },
    isDraftStepInDraftedContract () {
      return this.canEdit && this.contract.draftStep == 2
    },
    isDraftStepInContractInfo () {
      return this.canEdit && this.contract.draftStep == 3
    },
    isDraftStepInGeneratedContract () {
      return this.canEdit && this.contract.draftStep == 4
    },
    isDraftStepInApprovalInfo () {
      return this.canEdit && this.contract.draftStep == 5
    },
    canSign () {
      let version = (this.contract.version || '1.0').split('.')[0]

      return (
        (['1', '2'].indexOf(version) === -1)
        && (this.isRequestor || this.isCollaborator)
        && this.isJustApprovedContract
      )
    },
    canViewSignInfo () {
      return (
        (this.isRequestor || this.isCollaborator)
        && this.isSigningContract
      )
    },
    canDownloadSignFile () {
      return (
        (
          // (this.isRequestor || this.isCollaborator || this.isArchiveApprover || this.isArchiveSupporter)
          // && 
          (this.isArchivingContract || this.isArchivedContract)
        )
      )
    },
    canArchive () {
      return (
        (this.isArchiveApprover || this.isArchiveSupporter)
        && this.isArchivingContract
      )
    },
    
    canEditContractForm () {
      return (this.isRequestor || this.isCollaborator) && this.isDraftStepInContractType && !this.isPreviewContractLoading
    },

    statusListStepper () {
      return this.statusList.filter(el => !!el.inStepper)
    },
    statusStep () {
      return this.statusListStepper.findIndex(el => el.code === this.contract.status) + 1
    },

    commentBoxRows () {
      return parseInt((this.windowHeight - 64 - 45) / 18 / 2)
    },
    newDraftedContractCommentBoxRows () {
      return parseInt((this.windowHeight - 64 - 45) / 18 / 2)
    },

    contractTemplateList () {
      return [
        ...[
          'BASES',
          'CI',
          'DAR',
          'NSI',
          'NU'
        ],
        ...(
          (this.isDraftStepInContractInfo || this.canEditContractForm)
          ? []
          : [
            'LINX',
            'RMS',
            'RMS Backdata'
          ]
        ),
      ]
    },

    statusList () {
      let version = (this.contract.version || '1.0').split('.')[0]      
      let procs_draft_mng = [
        {
          code: 1,
          value: 'Requestor Drafted',
          inStepper: [1, 8].indexOf(this.contract.status) > -1
        },
        {
          code: 2,
          value: 'Requestor Drafted',
          // alias: ['Contract Recalled'],
          inStepper: [2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1
        },
        {
          code: 3,
          value: 'Manager Approval',
          alias: ['Requestor Submited'],
          inStepper: [3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1
        }
      ]
      let procs_fnnc_apvl = [
        {
          code: 4,
          value: 'Finance Approval',
          inStepper: [3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1 && this.financeApproverAvailable
        }
      ]
      let procs_legal_apvl = [
        {
          code: 5,
          value: 'Legal Approval',
          inStepper: [3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1 && this.legalApproverAvailable
        }
      ]
      let procs_ci_apvl = [
        {
          code: 9,
          value: 'CI Leader Approval',
          inStepper: [3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1 && this.ciApproverAvailable
        }
      ]
      let procs_cpg_apvl = [
        {
          code: 6,
          value: 'CPG Leader Approval',
          inStepper: [3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1 && this.cpgApproverAvailable
        }
      ]
      let procs_apvd = [
        {
          code: 7,
          value: 'Contract Approved',
          inStepper: [3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1
        }
      ]
      let procs_abd = [
        {
          code: 8,
          value: 'Contract Abandoned',
          alias: ['Requestor Abandoned'],
          inStepper: [8].indexOf(this.contract.status) > -1
        }
      ]
      let procs_sign = [
        {
          code: 10,
          value: 'Internal Signing',
          inStepper: [10].indexOf(this.contract.status) > -1
        },
        {
          code: 11,
          value: 'Internal Signed',
          inStepper: [11, 12, 13, 14, 15].indexOf(this.contract.status) > -1
        },
        {
          code: 12,
          value: 'External Signing',
          inStepper: [10, 12].indexOf(this.contract.status) > -1 && this.contract.needExtSign
        },
        {
          code: 13,
          value: 'External Signed',
          inStepper: [13, 14, 15].indexOf(this.contract.status) > -1 && this.contract.needExtSign
        },
        {
          code: 14,
          value: 'Pending Archive',
          inStepper: [10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1 && this.contract.needArchive
        },
        {
          code: 15,
          value: 'Archived',
          inStepper: [10, 11, 12, 13, 14, 15].indexOf(this.contract.status) > -1
        }
      ]
      switch (version) {
        case '1':
          return [...procs_draft_mng, ...procs_fnnc_apvl, ...procs_legal_apvl, ...procs_cpg_apvl, ...procs_apvd, ...procs_abd]
          break;

        default:
          return [...procs_draft_mng, ...procs_cpg_apvl, ...procs_ci_apvl, ...procs_fnnc_apvl, ...procs_legal_apvl, ...procs_apvd, ...procs_abd, ...procs_sign]
          break;
      }
    },
    salesOrganizationListForSelect () {
      return (this.salesOrganizationList ?? []).filter(el => el?.active)
    },
    salesOrganizationPlaceholder () {
      return (
        this.isPendingContract
          ? ''
          : (this.salesOrganizationList ?? [])?.find(el => el.value === this.contract.salesOrganization)?.[this.languageAvailable ? (this.contract.language === 'Chinese' ? 'textCn' : 'textEn') : 'text'] ?? this.contract.salesOrganizationn ?? ''
      )
    },
    
    billingTermsValues () {
      return [
        ...this.billingTermsDefaultListDisplayed.map(el => ({
          value: el.value,
          text: el.text,
        })),
        ...(
          this.isPendingContract
          ? []
          : [{
            value: this.contract.billingTerms,
            text: this.contract.billingTerms,
          }]
        ),
      ]
    },
    paymentTermsValues () {
      return [
        ...this.paymentTermsDefaultListDisplayed.map(el => ({
          value: el.value,
          text: el.text,
        })),
        ...(
          this.isPendingContract
          ? []
          : [{
            value: this.contract.paymentTerms,
            text: this.contract.paymentTerms,
          }]
        ),
      ]
    },

    /**
     * column fields and style
     */
    opportunityFields () {
      return [
        // {
        //   slot: 'slot-icon',
        //   miniColumn: 1,
        //   titleStyle: {
        //     'width': '32px',
        //     'padding-left': '6px',
        //     'padding-right': '0',
        //     'text-align': 'center'
        //   },
        //   valueStyle: {
        //     'width': '32px',
        //     'padding-left': '6px',
        //     'padding-right': '0',
        //     'text-align': 'center'
        //   }
        // },
        {
          title: 'Opportunity ID',
          value: 'id',
          miniColumn: 1,
          titleStyle: {
            'width': '175px'
          },
          miniValueStyle: {
            'color': '#999'
          },
          searchText: 'Opportunity ID (=)'
        },
        // {
        //   title: 'Opportunity Name',
        //   value: 'name',
        //   miniColumn: 1,
        //   searchText: 'Opportunity Name',
        //   titleStyle: {
        //     'min-width': '87px'
        //   }
        // },
        {
          title: 'Account Name',
          value: 'accountName',
          miniColumn: 1,
          searchText: 'Account Name',
          titleStyle: {
            'min-width': '116px'
          },
        },
        {
          title: 'Amount',
          value: 'amount',
          miniColumn: 2 - ((this.windowWidth < 450) * 1),
          titleStyle: {
            'width': '120px',
            'min-width': '120px',
            'padding-left': '2px',
            'padding-right': '2px',
            'text-align': 'right'
          },
          valueStyle: {
            'padding-left': '2px',
            'padding-right': '2px',
            'text-align': 'right'
          },
          miniItemStyle: {
            'justify-content': 'flex-end',
            'text-align': 'right',
            color: '#999'
          },
          searchText: 'Amount (=)',
        },
        {
          title: 'Currency',
          value: 'currency',
          miniColumn: 2 - ((this.windowWidth < 450) * 1),
          titleStyle: {
            'width': '90px',
            'min-width': '90px'
          },
          miniItemStyle: {
            'justify-content': 'flex-end',
            'text-align': 'right',
            color: '#999'
          },
        },
        {
          slot: 'slot-actions',
          miniColumn: 3 - ((this.windowWidth < 450) * 1),
          titleStyle: {
            'width': '34px'
          },
          valueStyle: {
            'width': '34px',
            'padding': '0',
            'text-align': 'center'
          },
          miniItemStyle: {
            'justify-content': 'flex-end'
          }
        }
      ]
    },
    miniColumnsStyle () {
      return (this.windowWidth < 450) ? [
        {
          position: 'relative',
          'width': '120px',
          'vertical-align': 'top'
        },
        {
          position: 'relative',
          'width': '30px',
          'padding-left': '0',
          'padding-right': '0'
        }
      ] : [
        {
          'min-width': '120px'
        },
        {
          position: 'relative',
          'width': '120px',
          'text-align': 'right',
          'vertical-align': 'top'
        },
        {
          position: 'relative',
          'width': '40px',
          'padding-left': '2px',
          'padding-right': '0'
        }
      ]
    },

    /**
     * check of contract info form available and requested
     */
    isContractGeneratorAvailable () {
      return ['Sales Contract', 'Non Disclosure Agreement'].indexOf(this.contract.type) > -1
    },
    isContractGeneratorUsed () {
      return !!this.isContractGeneratorAvailable && this.contract.contractGenerator === 'Yes'
    },
    legalApproverAvailable () {
      // return !this.isContractGeneratorUsed
      return (
          ['Sales Contract', 'Non Disclosure Agreement'].indexOf(this.contract.type) > -1
          && this.contract.contractGenerator === 'No'
        )
        || ['Purchase Order', 'Sales Contract (with Local MSA)', 'Amendment'].indexOf(this.contract.type) > -1
    },
    cpgApproverAvailable () {
      return !!this.contract.cpgApprover.email
    },
    languageAvailable () {
      return ['Sales Contract', 'Non Disclosure Agreement'].indexOf(this.contract.type) > -1
        && this.isContractGeneratorUsed
    },
    contractTemplateAvailable () {
      return ['Sales Contract'].indexOf(this.contract.type) > -1
        && this.isContractGeneratorUsed
    },
    amountAvailable () {
      return !!this.contract.type && ['Sales Contract', 'Purchase Order', 'Sales Contract (with Local MSA)', 'Amendment'].indexOf(this.contract.type) > -1
    },
    currencyAvailable () {
      return this.amountAvailable
    },
    billingTermsAvailable () {
      return !!this.contract.type && ['Sales Contract', 'Purchase Order', 'Sales Contract (with Local MSA)', 'Amendment'].indexOf(this.contract.type) > -1
    },
    billingTermsOthersAvailable () {
      return this.billingTermsAvailable && !!this.billingTermsDefaultList.find(el => el.other && el.value === this.contract.billingTerms)
    },
    standardsBillingTermDefault () {
      // Get default billing terms
      return this.billingTermsDefaultList.filter(el => el.finStd || false)
    },
    standardsBillingTermOpp () {
      // Get standard billing terms by opportunity's material id (unique)
      if (this.isPendingContract) {
        let materials = []
        try {
          for (const opp of this.opportunities) {
            for (const product of opp.products) {
              materials.push(product.material)
            }
          }
          materials = [...new Set(materials)]
          if (materials.length !== 1) { return [] }
          return this.billingTermsDefaultList
            .filter(el => el.finStdMtrls.includes(materials[0]))
            .map(el => el.value)
        } catch (err) {
          return []
        }
      } else {
        return this.contract.records.standardsBillingTermsOpp
      }
      return []
    },
    billingTermsDefaultListDisplayed () {
      return this.billingTermsDefaultList.map(el => ({
        ...el,
        standard: this.checkFinBillingStandard(el),
      })).map(el => ({
        ...el,
        text: el.value + ((this.isPendingContract ? el.standard : this.contract.records.isStandardBillingTerm) ? ' (Standard)' : ''),
      }))
    },
    isStandardBillingTerm () {
      const billingTerm = this.billingTermsDefaultList.find(el => el.value === this.contract.billingTerms) || {}
      if (!billingTerm.value) { return false }
      return this.checkFinBillingStandard(billingTerm)
    },
    paymentTermsAvailable () {
      return !!this.contract.type && ['Sales Contract', 'Purchase Order', 'Sales Contract (with Local MSA)', 'Amendment'].indexOf(this.contract.type) > -1
    },
    paymentTermsOthersAvailable () {
      return this.paymentTermsAvailable && !!this.paymentTermsDefaultList.find(el => el.other && el.value === this.contract.paymentTerms)
    },
    standardPaymentTermSap () {
      // Get Sold-to's standard payment term in SAP items
      const valSapPaymentTermStandard = this.contract.standardPaymentTermSap || ''
      return (valSapPaymentTermStandard
        ? (
          this.paymentTermsDefaultList.find(el => el.value === valSapPaymentTermStandard || el.alias.includes(valSapPaymentTermStandard)) ||
          // get finance weight if value is 'Net xxx'
          [parseInt(valSapPaymentTermStandard.replace(/^Net /, '')) || -1].map(el => ({
            value: (el > -1 ? `Net ${el} days` : valSapPaymentTermStandard),
            finWeight: el,
          }))[0]
        )
        : { finWeight: -1 }
      )
    },
    standardPaymentTermDefault () {
      // Get default payement term
      return this.paymentTermsDefaultList.find(el => !!el.finDefault) || { finWeight: -1 }
    },
    paymentTermsDefaultListDisplayed () {
      return this.paymentTermsDefaultList.map(el => ({
        ...el,
        standard: this.checkFinPaymentStandard(el),
      })).map(el => ({
        ...el,
        text: el.value + ((this.isPendingContract ? el.standard : this.contract.records.isStandardPaymentTerm) ? ' (Standard)' : ''),
      }))
    },
    isStandardPaymentTerm () {
      const paymentTerm = this.paymentTermsDefaultList.find(el => el.value === this.contract.paymentTerms) || {}
      if (!paymentTerm.value) { return false }
      return this.checkFinPaymentStandard(paymentTerm)
    },
    msalsaAvailable () {
      return !!this.contract.type && ['Sales Contract', 'Purchase Order', 'Sales Contract (with Local MSA)', 'Amendment'].indexOf(this.contract.type) > -1
    },
    financeApproverAvailable () {
      try {
        if (!this.isPendingContract) { return this.contract.financeNeed }

        // // - Finance approval not available - 2023/04/11 requested by Colleen (2/2)
        // const msaLsaNeed = this.financeRule.MsaLsa[this.contract.msalsa].need
        // if (this.contract.type) {
        //   if (
        //     ['Sales Contract', 'Purchase Order', 'Sales Contract (with Local MSA)', 'Amendment'].indexOf(this.contract.type) > -1 &&
        //     (
        //       !this.isStandardBillingTerm ||
        //       (!this.isStandardPaymentTerm && msaLsaNeed === 'Yes')
        //     )
        //   ) {
        //     return true
        //   }

        //   if (['LINX', 'RMS Backdata'].indexOf(this.contract.usedTemplate) > -1) {
        //     if (this.contract.billingTerms === '100% upfront' && this.contract.paymentTerms === 'Net 30 days') {
        //       return false
        //     } else {
        //       return true
        //     }
        //   }
        // }
      } catch (e) {
        // console.log(e)
      }
      return false
    },
    financeApproverAvailableString() {
      return (!!this.financeApproverAvailable).toString()
    },
    ciApproverAvailable () {
      try {
        let version = (this.contract.version || '1.0').split('.')[0]
        if (version == '1') return false

        if (!this.isPendingContract) { return this.contract.ciApprovalNeed }
        if (!this.financeApproverAvailable) return false
        if (!this.opportunities.find(el => el.products instanceof Array && !!el.products.find(el2 => el2.practice == 'CI'))) return false
        return true
      } catch (e) {
        console.log(e)
      }
      return false
    },
    ciApproverAvailableString() {
      return (!!this.ciApproverAvailable).toString()
    },
    financeLeaderApproval() {
      return this.ciApproverAvailable
    },
    projectDescriptionAvailable () {
      return ['Non Disclosure Agreement'].indexOf(this.contract.type) > -1
        && this.isContractGeneratorUsed
    },
    sowAvailable () {
      return ['Sales Contract'].indexOf(this.contract.type) > -1
        && this.isContractGeneratorUsed
    },
    isDraftedContractAvailable () {
      return (
          ['Sales Contract', 'Non Disclosure Agreement'].indexOf(this.contract.type) > -1
          && this.contract.contractGenerator === 'No'
        )
        || ['Purchase Order', 'Sales Contract (with Local MSA)', 'Amendment'].indexOf(this.contract.type) > -1
    },

    currencyInLanguage () {
      const index = this.contractCurrencyList.findIndex(el => el.value === this.contract.currency)
      if (index < 0) return this.contract.currency
      switch (this.contract.language) {
        case 'English': case 'english': case 'EN': case 'En': case 'en':
          return this.contractCurrencyList[index].value
          break
        case 'Chinese': case 'chinese': case 'CN': case 'Cn': case 'cn': 
          return this.contractCurrencyList[index].cn
          break
        default:
          return this.contract.currency
          break
      }
    },
    salesOrganizationInLanguage () {
      const index = this.salesOrganizationList.findIndex(el => el.value === this.contract.salesOrganization)
      if (index < 0) return this.contract.salesOrganization
      switch (this.contract.language) {
        case 'English': case 'english': case 'EN': case 'En': case 'en':
          return this.salesOrganizationList[index].textEn
          break
        case 'Chinese': case 'chinese': case 'CN': case 'Cn': case 'cn': 
          return this.salesOrganizationList[index].textCn
          break
        default:
          return this.contract.salesOrganization
          break
      }
    },

    contractAmountToThousands () {
      let fltAmount = parseFloat(this.contract.amount)
      if (isNaN(fltAmount)) return ''
      return toThousands(fltAmount.toFixed(2)) + (fltAmount%1 ? '' : '.00')
    },

    listApproverCommentTo () {
      return [this.comments.defaultRequestors].concat(this.reviewers)
    },

    defaultSalesOrg () {
      switch (this.userLocation) {
        case 'CN-Guangzhou':
          // // The Nielsen Company (Guangzhou) Ltd. 广州尼尔森爱科市场研究有限公司
          // return 'The Nielsen Company (Guangzhou) Ltd.';
          // NielsenIQ (Guangzhou) LTD 广州尼尔森市场研究有限公司
          return 'NielsenIQ (Guangzhou) LTD';
          break;

        // case 'CN-Beijing':
        // case 'CN-Chengdu':
        // case 'CN-Harbin':
        // case 'CN-Hong Kong':
        // case 'CN-Nantong':
        // case 'CN–Remote Office':
        // case 'CN-Shanghai':
        // case 'CN-Shenzhen':
        // case 'Emeryville':
        // case 'IN-Chennai':
        // case 'RU-Moscow':
        // case 'SG-Singapore':
        //   // The Nielsen Company (Guangzhou) Ltd., Shanghai Branch. 广州尼尔森市场研究有限公司上海分公司
        //   return 'The Nielsen Company (Guangzhou) Ltd., Shanghai Branch';
        //   break;
      
        default:
          // // The Nielsen Company (Guangzhou) Ltd., Shanghai Branch. 广州尼尔森市场研究有限公司上海分公司
          // return 'The Nielsen Company (Guangzhou) Ltd., Shanghai Branch';
          // NielsenIQ (Guangzhou) LTD, Shanghai Branch 广州尼尔森爱科市场研究有限公司上海分公司
          return 'NielsenIQ (Guangzhou) LTD, Shanghai Branch';
          break;
      }
    },

    isSignerLoading () {
      return this.isIntSignerLoading || this.isExtSignerLoading
    },
    
    // signers setting
    intSignerOrganizeNames () {
      let ret = []
      this.intOrganizes.forEach(el => {
        if (el.id && el.name && (ret.indexOf(el.name) === -1)) {
          ret.push(el.name)
        }
      })
      return ret
    },
    extSignerOrganizeNames () {
      let ret = []
      this.extAgentsXOrganizes
        .filter(el => 
          (
            (!this.extSignerOrganizeNameKey && !this.signFlow.extSigner.organize.name)
            || (
              (!!this.extSignerOrganizeNameKey && !!el.orgName && el.orgName.indexOf(this.extSignerOrganizeNameKey) !== -1)
              || (!!this.signFlow.extSigner.organize.name && !!el.orgName && el.orgName.indexOf(this.signFlow.extSigner.organize.name) !== -1)
            )
          )
          && (
            !this.signFlow.extSigner.agent.name || (el.agentName === this.signFlow.extSigner.agent.name)
          )
          && (
            !this.signFlow.extSigner.agent.mobile || (el.agentMobile === this.signFlow.extSigner.agent.mobile)
          )
          && (
            !this.signFlow.extSigner.agent.email || (el.agentEmail === this.signFlow.extSigner.agent.email)
          )
        )
        .forEach(el => {
          if (el.orgId && el.orgName && !ret.find(el2 => el2.name === el.orgName)) {
            ret.push({
              name: el.orgName,
              hasAgent: true,
            })
          }
        })
      this.extOrganizesXAgents.forEach(el => {
        if (el.orgId && el.orgName && !ret.find(el2 => el2.name === el.orgName)) {
          ret.push({
            name: el.orgName,
            hasAgent: false,
          })
        }
      })
      return ret
    },
    extSignerAgents () {
      let ret = []
      this.extOrganizesXAgents
        .filter(el =>
          (
            !!this.signFlow.extSigner.organize.name && (el.orgName === this.signFlow.extSigner.organize.name)
          )
          && (
            (!this.extSignerAgentNameKey && !this.signFlow.extSigner.agent.name)
            || (
              (!!this.extSignerAgentNameKey && !!el.agentName && el.agentName.indexOf(this.extSignerAgentNameKey) !== -1)
              || (!!this.signFlow.extSigner.agent.name && !!el.agentName && el.agentName.indexOf(this.signFlow.extSigner.agent.name) !== -1)
            )
          )
          && (
            (!this.extSignerAgentMobileKey && !this.signFlow.extSigner.agent.mobile)
            || (
              (!!this.extSignerAgentMobileKey && !!el.agentMobile && el.agentMobile.indexOf(this.extSignerAgentMobileKey) !== -1)
              || (!!this.signFlow.extSigner.agent.mobile && !!el.agentMobile && el.agentMobile.indexOf(this.signFlow.extSigner.agent.mobile) !== -1)
            )
          )
          && (
            (!this.extSignerAgentEmailKey && !this.signFlow.extSigner.agent.email)
            || (
              (!!this.extSignerAgentEmailKey && !!el.agentEmail && el.agentEmail.indexOf(this.extSignerAgentEmailKey) !== -1)
              || (!!this.signFlow.extSigner.agent.email && !!el.agentEmail && el.agentEmail.indexOf(this.signFlow.extSigner.agent.email) !== -1)
            )
          )
        )
        .forEach(el => {
          if (
            el.agentId && el.agentName && el.agentMobile && el.agentEmail
            && !ret.find(el2 => el2.id === el.agentId && el2.name === el.agentName && el2.mobile === el.agentMobile && el2.email === el.agentEmail)
          ) {
            ret.push({
              id: el.agentId,
              name: el.agentName,
              mobile: el.agentMobile,
              email: el.agentEmail,
              hasOrg: true,
            })
          }
        })
      this.extAgentsXOrganizes.forEach(el => {
        if (
          el.agentId && el.agentName && el.agentMobile && el.agentEmail
          && !ret.find(el2 => 
            el2.id === el.agentId 
            && el2.name === el.agentName 
            && el2.mobile === el.agentMobile 
            && el2.email === el.agentEmail
          )
        ) {
          ret.push({
            id: el.agentId,
            name: el.agentName,
            mobile: el.agentMobile,
            email: el.agentEmail,
            hasOrg: false,
          })
        }
      })
      return ret
    },
    extSignerAgentNames () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentNameKey || (!!el.name && el.name.indexOf(this.extSignerAgentNameKey) !== -1))
            || (!this.signFlow.extSigner.agent.name || (!!el.name && el.name.indexOf(this.signFlow.extSigner.agent.name) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.name === el.name)) {
            ret.push({
              name: el.name,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(
                el2 => el2.name === el.name
                && (!!this.signFlow.extSigner.agent.mobile || !!this.signFlow.extSigner.agent.email)
                && (el2.mobile === this.signFlow.extSigner.agent.mobile || !this.signFlow.extSigner.agent.mobile)
                && (el2.email === this.signFlow.extSigner.agent.email || !this.signFlow.extSigner.agent.email)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },
    extSignerAgentMobiles () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentMobileKey || (!!el.mobile && el.mobile.indexOf(this.extSignerAgentMobileKey) !== -1))
            || (!this.signFlow.extSigner.agent.mobile || (!!el.mobile && el.mobile.indexOf(this.signFlow.extSigner.agent.mobile) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.mobile === el.mobile)) {
            ret.push({
              mobile: el.mobile,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(el2 => 
                el2.mobile === el.mobile 
                && (!!this.signFlow.extSigner.agent.name || !!this.signFlow.extSigner.agent.email)
                && (el2.name === this.signFlow.extSigner.agent.name || !this.signFlow.extSigner.agent.name)
                && (el2.email === this.signFlow.extSigner.agent.email || !this.signFlow.extSigner.agent.email)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },
    extSignerAgentEmails () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentEmailKey || (!!el.email && el.email.indexOf(this.extSignerAgentEmailKey) !== -1))
            || (!this.signFlow.extSigner.agent.email || (!!el.mobile && el.email.indexOf(this.signFlow.extSigner.agent.email) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.email === el.email)) {
            ret.push({
              email: el.email,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(el2 => 
                el2.email === el.email 
                && (!!this.signFlow.extSigner.agent.name || !!this.signFlow.extSigner.agent.mobile)
                && (el2.name === this.signFlow.extSigner.agent.name || !this.signFlow.extSigner.agent.name)
                && (el2.mobile === this.signFlow.extSigner.agent.mobile || !this.signFlow.extSigner.agent.mobile)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },

    dummyContractSigning () {
      return [{
        name: 'Contract Signing'
      }]
    },
    dummyContractSigned () {
      return this.isContractGeneratorUsed 
        ?
        [{
          name: 'Contract Signed'
        }]
        : 
        this.contract.draftedContract
          .map(el => ({
            name: el.name + ' (Signed)'
          }))
    },
  },
  methods: {
    ...mapActions('app', ['setMainTitle']),

    presetData () {
      this.contract.costCenter = this.costCenter
      this.contract.requestor.email = this.userEmail
      this.contract.requestor.name = this.userName
      this.contract.requestor.icon = this.userPhotoUrl
      this.contract.status = 1
      this.contract.team = this.userTeam
    },

    getBillingTermText () {
      let billingtermText = ['', null, undefined].indexOf(this.contract.billingTerms) < 0 ? this.billingTermsOthersAvailable && ['', null, undefined].indexOf(this.contract.billingTermsOthers) < 0 ? this.contract.billingTermsOthers : this.billingTermsDefaultList.find(el => el.value === this.contract.billingTerms)[this.contract.language === 'English' ? 'enTemplate' : 'cnTemplate'] : ''
      
      if (['LINX', 'RMS Backdata'].indexOf(this.contract.usedTemplate) > -1 && this.contract.billingTerms === '100% upfront') {
        billingtermText = this.contract.language === 'English' ? 'All invoices, including any applicable tax, are due and payable upon presentation and before delivery of service.' : '所有的发票（包含根据规定应缴纳的税赋）都应在服务被提交的同时或之前到期并被支付。'
      }

      return billingtermText
    },
    getPaymentTermText () {
      let paymenttermText = ['', null, undefined].indexOf(this.contract.paymentTerms) < 0 ? this.paymentTermsOthersAvailable && ['', null, undefined].indexOf(this.contract.paymentTermsOthers) < 0 ? this.contract.paymentTermsOthers : this.paymentTermsDefaultList.find(el => el.value === this.contract.paymentTerms)[this.contract.language === 'English' ? 'enTemplate' : 'cnTemplate'] : ''
      
      // if (['LINX', 'RMS Backdata'].indexOf(this.contract.usedTemplate) > -1 && this.contract.paymentTerms === 'Net 30 days') {
      //   paymenttermText = '30'
      // }

      return paymenttermText
    },

    /**
     * post requests
     */
    async postGetOptions () {
      try {
        const req = xPost(
          'contract_review',
          'getOptions',
          {
            loginStatus: this.loginStatus
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          this.salesOrganizationList = res.data.salesOrgs
            .map(el => ({
              value: el?.en,
              active: !!parseInt(el.active ?? 0),
              signable: !!parseInt(el.signable ?? 0),
              text: `${el?.en} ${el?.cn}`,
              textEn: el?.en,
              textCn: el?.cn,
            }))
          this.contractTypeList = res.data.contractTypes
            .filter(el => !!el.Type)
            .map(el => ({
              value: el.Type
            }))
          this.contractCurrencyList = res.data.currencies
            .map(el => ({
              value: el.en,
              text: el.en+' '+el.cn,
              cn: el.cn
            }))
          this.billingTermsDefaultList = res.data.billingTerms
            .filter(el => {
              return el.value && (
                !!Number(el.other) || (
                  el.en_template &&
                  el.cn_template
                )
              )
            })
            .map(el => {
              let finStdMtrls = []
              try {
                finStdMtrls = JSON.parse(el.fin_std_materials_json)
              } catch (err) { /** do nothing */ }
              return {
                value: el.value,
                other: !!Number(el.other),
                finStd: Number(el.fin_std) || 0,
                finStdMtrls: (finStdMtrls instanceof Array) ? finStdMtrls : [],
                enTemplate: el.en_template || '',
                cnTemplate: el.cn_template || '',
              }})
          this.paymentTermsDefaultList = res.data.paymentTerms
            .filter(el => {
              return el.value && (
                !!Number(el.other) || (
                  el.en_template &&
                  el.cn_template
                )
              )
            })
            .map(el => {
              let alias = []
              try {
                alias = JSON.parse(el.alias_json)
              } catch (err) { /** do nothing */ }
              return {
                value: el.value,
                alias: (alias instanceof Array) ? alias : [],
                other: !!Number(el.other),
                finWeight: Number(el.fin_weight) || 0,
                finDefault: !!Number(el.fin_default),
                enTemplate: el.en_template || '',
                cnTemplate: el.cn_template || '',
              }})
          this.leaderTitles = res.data.leaderTitles
            .map(el => el.Title)
          this.financeApproversOptions = res.data.financeApprovers
            .filter(el => !!el.Email)
            .map(el => ({
              email: el.Email,
              name: el.Name || null,
              icon: el.Icon || null,
              team: el.Team || null,
              location: el.Location || null
            }))
          this.legalApproversOptions = res.data.legalApprovers
            .filter(el => !!el.Email)
            .map(el => ({
              email: el.Email,
              name: el.Name || null,
              icon: el.Icon || null,
              team: el.Team || null,
              location: el.Location || null
            }))
          this.archiveApprovers = res.data.archiveApprovers
            .filter(el => !!el.email && !!parseInt(el.active))
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.extraRequestors = res.data.extraRequestors || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [load data - client project contract info options]')
        console.log(err)
        this.$router.push('/legal/request/list')
        return
      }
    },
    async getData (token) {
      token = token || this.token

      const postGetContractOpportunities = this.postGetContractOpportunities(token)
      const awaitPostGetContractOpportunities = await postGetContractOpportunities
      
      const postGetContractDetail = this.postGetContractDetail(token)
      const postSignFlowInfo = this.postSignFlowInfo(token)
      await postGetContractDetail
      await postSignFlowInfo

      // SAP
      if (this.isPendingContract) {
        if (this.contract.sap) {
          if (this.contract.sapKey === this.contract.sap) {
            this.postGetSapNames(this.contract.sapKey, 1)
          } else if (this.contract.sapKeyGen === this.contract.sap) {
            this.postGetSapNames(this.contract.sapKeyGen, 2)
          } else {
            this.contract.sapKey = this.contract.sap
            this.contract.sapKeyGen = this.contract.sap
          }
        }
      } else {
        if (this.contract.sap) { this.contract.saps = [this.contract.sap] }
        if (this.contract.customerNumber) {
          this.contract.customerNumbers = [this.contract.customerNumber]
        } else {
          this.postGetSapCustomerNumbersByName()
        }
      }
    },
    async postGetContractDetail (token) {
      token = token || this.token

      try {
        const req = xPost(
          'contract_review',
          'initContractInformationDetail',
          {
            loginStatus: this.loginStatus,
            token
          }
        )
        const res = await req
        if (res.data && res.data.status === 200 && res.data.data) {
          const data = res.data.data.data[0]
          const upload = res.data.data.upload
          const history = res.data.data.history
          const listSharedTo = res.data.data.sharedTo.map(el => ({
            email: el.Email,
            name: el.Name,
            icon: el.Icon,
            role: el.Role
          }))
          const financeApprovers = res.data.data.financeApprovers || []
          const legalApprovers = res.data.data.legalApprovers || []
          const cpgApprovers = res.data.data.cpgApprovers || []
          const ciApprovers = res.data.data.ciApprovers || []
          const financeLeader = res.data.data.financeLeader || []

          const salesOrganizationValueIndex = this.salesOrganizationList.findIndex(el => el.value === data.NielsenLegalEntityName)
          const salesOrganizationTextIndex = this.salesOrganizationList.findIndex(el => el.textEn === data.NielsenLegalEntityName || el.textCn === data.NielsenLegalEntityName)
          const salesOrganizationIndex = salesOrganizationValueIndex > -1 ? salesOrganizationValueIndex : salesOrganizationTextIndex

          this.contract.version = data.Ver          
          let statusIndex = this.statusList.findIndex(el => el.value === data.Stage)
          let statusIndexAlias = this.statusList.findIndex(el => el.alias ? el.alias.indexOf(data.Stage) > -1 : false)
          if (statusIndex < 0 && statusIndexAlias > -1) statusIndex = statusIndexAlias
          if (statusIndex < 0 || !this.statusList[statusIndex].inStepper) {
            // throw '- Invalid Status'
          }

          this.contract.stage = data.Stage
          this.contract.stageNum = data.StageNum
          this.contract.draftStep = parseInt(data.DraftStep) || 0
          this.contract.farestDraftStep = parseInt(data.FarestDraftStep) || 0
          this.contract.status = this.statusList[statusIndex].code

          this.contract.id = data.Id
          this.contract.requestId = data.RequestId
          this.contract.requestor.email = data.RequestorEmail
          this.contract.requestor.name = data.RequestorName
          this.contract.requestor.icon = data.RequestorIcon
          this.contract.costCenter = data.CostCenter
          this.contract.team = data.Team
          this.contract.financeNeed = (data.FinanceNeed == 'true')
          this.contract.ciApprovalNeed = (data.CiApprovalNeed == 'true')
          this.contract.managerApprover.email = data.ManagerApproverEmail.toLowerCase() || ''
          this.contract.managerApprover.name = data.ManagerApproverName || ''
          this.contract.managerApprover.icon = data.ManagerApproverIcon || ''
          this.contract.financeApprover.email = data.FinanceApproverEmail.toLowerCase() || ''
          this.contract.financeApprover.name = data.FinanceApproverName || ''
          this.contract.financeApprover.icon = data.FinanceApproverIcon || ''
          this.contract.financeApproversInTeam = financeApprovers.map(el => ({email: el.Email || '', role: el.Role || ''})) || []
          this.contract.legalApprover.email = data.LegalApproverEmail.toLowerCase() || ''
          this.contract.legalApprover.name = data.LegalApproverName || ''
          this.contract.legalApprover.icon = data.LegalApproverIcon || ''
          this.contract.legalApproversInTeam = legalApprovers.map(el => ({email: el.Email || '', role: el.Role || ''})) || []
          this.contract.cpgApprover.email = data.CpgApproverEmail.toLowerCase() || ''
          this.contract.cpgApprover.name = data.CpgApproverName || ''
          this.contract.cpgApprover.icon = data.CpgApproverIcon || ''
          this.contract.ciApprover.email = data.CiApproverEmail.toLowerCase() || ''
          this.contract.ciApprover.name = data.CiApproverName || ''
          this.contract.ciApprover.icon = data.CiApproverIcon || ''
          
          this.reviewers = listSharedTo.filter(el => el.role === '1')
          this.collaborators = listSharedTo.filter(el => el.role === '2')
          this.ats = listSharedTo.filter(el => el.role === '3')

          if (!this.canEdit && !!data.ContractType && this.contractTypeList.findIndex(el => el.value === data.ContractType) < 0) {
            this.contractTypeList.splice(0, 0, {
              value: data.ContractType,
              disabled: true
            })
          }
          this.contract.type = data.ContractType
          this.contract.contractGenerator = data.NielsenStandardTemplate
          this.contract.language = data.Language
          this.contract.usedTemplate = data.ProductServiceType
          this.contract.salesOrganization = salesOrganizationIndex > -1 ? this.salesOrganizationList[salesOrganizationIndex].value : (this.defaultSalesOrg || '')
          this.contract.sap = data.ClientLegalEntityName
          this.contract.customerNumber = data.Client_Customer_Number
          this.contract.amount = data.ContractValue

          let tmpAmount = parseFloat(data.ContractValue)
          if (isNaN(tmpAmount)) {
            this.contract.amount = '' 
          } else {
            this.contract.amount = tmpAmount.toFixed(2).toString()
          }

          this.contract.currency = data.ContractValueCurrency
          this.contract.startDate = data.ContractPeriod.split(' - ')[0]
          this.contract.endDate = data.ContractPeriod.split(' - ')[1]
          this.contract.billingTerms = data.BillingTerms
          this.contract.billingTermsOthers = data.BillingTermsOthers
          this.contract.paymentTerms = data.PaymentTerms
          this.contract.paymentTermsOthers = data.PaymentTermsOthers
          this.contract.msalsa = this.canEdit ? (this.msalsaAvailable ? 'No' : '') : data.MsaLsa
          this.contract.projectDescription = data.ProjectDescription
          this.contract.backgroundBriefing = data.BackgroundBriefing || ''
          
          if (this.isPendingContract) {
            this.postGetSapFinPayStd()
          } else {
            this.contract.standardPaymentTermSap = data.Standard_Payment_Term_SAP || ''
          }
          this.contract.records.isStandardBillingTerm = !!parseInt(data.Is_Standard_Billing_Term || 0)
          this.contract.records.isStandardPaymentTerm = !!parseInt(data.Is_Standard_Payment_Term || 0)
          this.contract.records.standardsBillingTermsOpp = JSON.parse(data.Standards_Billing_Term_Opp || '[]')

          this.contractPdfMsPath = data.ContractPdfMsPath || ''
          this.contractDocMsPath = data.ContractDocMsPath || ''
          this.contractPdfLocal = data.ContractPdfLocal || ''
          this.contractDocLocal = data.ContractDocLocal || ''
          
          if (!this.isApprovedContract && !this.contract.cpgApprover.email && cpgApprovers instanceof Array && cpgApprovers.length > 0) {
            this.contract.cpgApprover.email = cpgApprovers[0].Email
            this.contract.cpgApprover.name = cpgApprovers[0].Name
            this.contract.cpgApprover.icon = cpgApprovers[0].Icon
          }          
          this.contract.cpgApproversInTeam = cpgApprovers.map(el => ({email: el.Email || '', role: el.Role || ''})) || []
          if (!this.isApprovedContract && !this.contract.ciApprover.email && ciApprovers instanceof Array && ciApprovers.length > 0) {
            this.contract.ciApprover.email = ciApprovers[0].Email
            this.contract.ciApprover.name = ciApprovers[0].Name
            this.contract.ciApprover.icon = ciApprovers[0].Icon
          }
          this.contract.ciApproversInTeam = ciApprovers.map(el => ({email: el.Email || '', role: el.Role || ''})) || []
          if (this.financeLeaderApproval) {
            if (!this.isApprovedContract && !this.contract.financeApprover.email && financeLeader instanceof Array && financeLeader.length > 0) {
              this.contract.financeApprover.email = financeLeader[0].Email
              this.contract.financeApprover.name = financeLeader[0].Name
              this.contract.financeApprover.icon = financeLeader[0].Icon
            }
            this.contract.financeApproversInTeam = financeLeader.map(el => ({email: el.Email || '', role: el.Role || ''})) || []
          }          

          this.contract.signType = !!parseInt(data.signType)
          this.contract.needExtSign = !!parseInt(data.needExtSign)
          this.contract.needArchive = !!parseInt(data.needArchive)
          
          if (this.contract.needArchive) {
            this.contract.archiveApprover.email = data.archiveApproverEmail || null
            this.contract.archiveApprover.name = data.archiveApproverName || null
            this.contract.archiveApprover.icon = data.archiveApproverIcon || null
          }

          this.contract.canRecallArchive = res.data.recallArchv || false
          
          this.contract.sow = upload
            .filter(el => el.Type === 'SOW')
            .map(el => ({
              url: el.FileName || '',
              name: el.DispName || '',
              time: el.UploadTime || '',
              type: el.Type,
              download: true,
              delete: true,
              ...el
            }))

          this.contract.draftedContract = upload
            .filter(el => el.Type === 'drafted')
            .map(el => ({
              url: el.FileName || '',
              name: el.DispName || '',
              time: el.UploadTime || '',
              type: el.Type,
              download: true,
              delete: true,
              ...el
            }))

          this.isContractLoading = false

          this.history = []
          history.forEach(el => {
            let index = this.history
              .findIndex(el2 =>
                el2.user.email === el.User &&
                el2.time === el.Time &&
                el2.action === el.ActionName &&
                el2.comment === el.Content
              )
            if (index < 0) {
              index = this.history.length
              this.history.push({
                user: {
                  email: el.User || '',
                  name: el.UserName || '',
                  icon: el.UserIcon || ''
                },
                time: el.Time,
                action: el.ActionName,
                comment: el.Content,
                files: [],
                receivers: []
              })
            }
            if (!!el.FileName && !!el.DispName) {
              this.history[index].files.push({
                url: el.FileName,
                name: el.DispName
              })
            }
            if (el.DisplayToUser != 0 && !!el.ToUser) {
              this.history[index].receivers.push({
                email: el.ToUser || '',
                name: el.ToUserName || '',
                icon: el.ToUserIcon || ''
              })
            }
          })
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [load data - client project contract info detail]')
        console.log(err)    
        this.$router.push('/legal/request/list')
        return
      }
    },
    async postGetContractOpportunities (token) {
      token = token || this.token
      try {
        const req = xPost(
          'contract_review',
          'initContractOpportunities',
          {
            loginStatus: this.loginStatus,
            token
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          const opportunities = res.data.opportunities
          
          this.opportunities = opportunities.map(el => {
            let tmpAmount = parseFloat(el.Amount)
            let shownAmount
            if (isNaN(tmpAmount)) {
              shownAmount = '-'
            } else {
              shownAmount = toThousands(tmpAmount.toFixed(2)) + (tmpAmount%1 ? '' : '.00')
            }
  
            return {
              id: el.OpportunityId,
              name: el.OpportunityName,
              accountName: el.AccountName,
              ...(el.SoldToParty ? {sap: el.SoldToParty} : {}),
              amount: shownAmount,
              currency: el.Currency,
              from: el.From,
              products: el.Products
            }
          })
          // 触发 computed
          this.opportunities.splice(0,0,0)
          this.opportunities.splice(0,1)

          this.fixingOpportunity = false
          this.isLoadingOpportunity = false
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [load data - client project contract opportunities]')
        // console.log(err)
        this.$router.push('/legal/request/list')
        return
      }
    },
    async postAbandonContract() {
      this.isContractAbandoning = false
      this.$eventBus.$emit('snackbar', { text: 'Abandoning Contract', type: 'loading' })
      let action = 98
      let toUser = this.contract.requestor.email

      let backToList = false
      let snackbarParams = { text: 'Fail to abandon contract', type: 'error' }
      try {
        const res = await xPost(
          'contract_review',
          'contractReviewComment',
          {
            ver: this.contract.version,
            user: this.userEmail,
            toUser,
            contractId: this.contract.id,
            token: this.token,
            action,
            content: '',
            FinanceNeed: this.financeApproverAvailableString,
            CiApprovalNeed: this.ciApproverAvailableString,
            loginStatus: this.loginStatus,
            attached: []
          }
        )
        if (res.data.status === 200) {
          snackbarParams = { text: 'Contract Abandoned', type: 'success' }
          backToList = true
          // this.$eventBus.$emit('refreshGapi')
        } else if (res.data.status == 401 && res.data.detail == 'Action Stage Not Matched') {
          await this.getData()
          snackbarParams = { text: 'Fail to abandon contract, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw 'response status: ' + res.data.status
        }
      } catch (err) {
        console.log('Error [abandon contract]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
      if (backToList) { this.$router.push('/legal/request/list') }
    },
    async postSaveContractInfo () {
      let ret = ''

      try {
        let requestFunction
        switch (this.token) {
          case 'new':
            requestFunction = 'draftContractInformation'
            break

          default:
            requestFunction = 'contractReviewSaveEdit'
            break
        }

        let tmpAmount = parseFloat(this.contract.amount)
        if (isNaN(tmpAmount)) {
          tmpAmount = null
        } else {
          tmpAmount = tmpAmount.toFixed(2).toString()
        }
        let formItem = {
          BackgroundBriefing: this.contract.backgroundBriefing || '',
          BillingTerms: this.billingTermsAvailable ? this.contract.billingTerms : null,
          BillingTermsOthers: this.billingTermsOthersAvailable ? this.contract.billingTermsOthers : null,
          ClientLegalEntityName: this.contract.sap,
          ClientCustomerNumber: this.contract.customerNumber,
          CostCenter: this.contract.costCenter || '',
          ContractPeriod: [this.contract.startDate, this.contract.endDate],
          ContractType: this.contract.type,
          ContractValue: this.amountAvailable ? tmpAmount : null,
          ContractValueCurrency: this.currencyAvailable ? this.contract.currency : null,
          FinanceNeed: this.financeApproverAvailableString,
          CiApprovalNeed: this.ciApproverAvailableString,
          FinanceApproverEmail: (this.financeApproverAvailable && !this.financeLeaderApproval) ? this.contract.financeApprover.email : null,
          Language: this.languageAvailable ? this.contract.language : null,
          LegalApproverEmail: this.legalApproverAvailable ? this.contract.legalApprover.email : null,
          ManagerApproverEmail: this.contract.managerApprover.email || '',
          MsaLsa: this.msalsaAvailable ? this.contract.msalsa : null,
          NielsenLegalEntityName: this.contract.salesOrganization,
          NielsenStandardTemplate: this.isContractGeneratorAvailable ? this.contract.contractGenerator : null,
          PaymentTerms: this.paymentTermsAvailable ? this.contract.paymentTerms : null,
          PaymentTermsOthers: this.paymentTermsOthersAvailable ? this.contract.paymentTermsOthers : null,
          ProductServiceType: this.contractTemplateAvailable ? this.contract.usedTemplate : '',
          ProjectDescription: this.projectDescriptionAvailable ? this.contract.projectDescription : null,
          Team: this.contract.team || '',
          uploadListNew: [
            ...this.contract.sow,
            ...this.contract.draftedContract
            // ...(this.sowAvailable ? this.contract.sow : []),
            // ...(this.isDraftedContractAvailable ? this.contract.draftedContract : [])
          ],
          token: this.token,
          draftStep: this.contract.toDraftStep,
          ...this.isPendingContract 
            ? {
              IsStandardBillingTerm: this.isStandardBillingTerm,
              IsStandardPaymentTerm: this.isStandardPaymentTerm,
              StandardsBillingTermOpp: this.standardsBillingTermOpp.length ? JSON.stringify(this.standardsBillingTermOpp) : '',
            }
            : {
              IsStandardBillingTerm: this.records.isStandardBillingTerm,
              IsStandardPaymentTerm: this.records.isStandardPaymentTerm,
              StandardsBillingTermOpp: this.records.standardsBillingTermsOpp,
            },
          StandardPaymentTermSap: this.contract.standardPaymentTermSap || '',
        }
        const res = await xPost(
          'contract_review',
          requestFunction,
          {
            loginStatus: this.loginStatus,
            formItem,
            opportunities: this.opportunities,
            contractPdfMsPath: this.contractPdfMsPath,
            contractDocMsPath: this.contractDocMsPath,
            contractPdfLocal: this.contractPdfLocal,
            contractDocLocal: this.contractDocLocal
          }
        )
        if (res.data.status === 200) {
          if (this.isNewContract && res.data.token) {
            this.$router.push('/legal/request/details/client-project/' + res.data.token)
            ret = res.data.token
          } else {
            ret = this.token
          }
          // this.$eventBus.$emit('refreshGapi')
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [save data - client project contract detail]')
        // console.log(err)
      }

      return ret
    },
    async saveContractDraft () {
      this.contract.toDraftStep = this.contract.draftStep
      return await this.saveContractDraftWithStep()
    },
    async saveContractDraftWithStep (toScroll) {
      if (this.warnUserAccess()) return

      toScroll = toScroll || false

      this.$eventBus.$emit('snackbar', { text: 'Saving', type: 'loading' })

      let saveToken = await this.postSaveContractInfo()
      let funcGetData = async () => {
        if (!!saveToken) {
          let getData = this.getData(saveToken)
          this.$eventBus.$emit('snackbar', { text: 'Contract Saved', type: 'success' })
          let getDataResp = await getData
          docScrollToId('contract-review-draft-step-' + (this.contract.draftStep || 0), 30, 60)
        } else {
          this.$eventBus.$emit('snackbar', { text: 'Fail to save contract', type: 'error' })
        }
      }

      if (toScroll) {
        setTimeout(funcGetData, 1000)
      } else {
        await funcGetData()
      }

      return saveToken
    },
    async postGenerateContractPreview () {
      this.contractInfoChecked = true
      if (!this.$refs['contract-info-form-generate'].validate()) {
        return
      }
      if (this.isSowMissing()) {
        return
      }

      if (this.warnUserAccess()) {
        return
      }
      if (this.warnContractType()) {
        return
      }
      if (this.warnContractInfo()) {
        return
      }

      this.tfPreviewingContract = new Date()
      let tfPreviewingContract = this.tfPreviewingContract
      this.isContractPreviewing = true
      this.isPreviewContractLoading = true

      try {
        let tmpAmount = parseFloat(this.contract.amount)
        if (isNaN(tmpAmount)) {
          tmpAmount = null
        } else {
          tmpAmount = tmpAmount.toFixed(2).toString()
        }

        let formItem = {
          BackgroundBriefing: this.contract.backgroundBriefing || '',
          BillingTerms: this.billingTermsAvailable ? this.contract.billingTerms : null,
          BillingTermsOthers: this.billingTermsOthersAvailable ? this.contract.billingTermsOthers : null,
          ClientLegalEntityName: this.contract.sap,
          CostCenter: this.contract.costCenter || '',
          ContractPeriod: [
            this.contract.startDate + 'T16:00:00.000Z',
            this.contract.endDate + 'T16:00:00.000Z'
          ],
          ContractType: this.contract.type,
          ContractValue: this.amountAvailable ? tmpAmount : null,
          ContractValueCurrency: this.currencyAvailable ? this.currencyInLanguage : null,
          FinanceNeed: this.financeApproverAvailableString,
          CiApprovalNeed: this.ciApproverAvailableString,
          FinanceApproverEmail: (this.financeApproverAvailable && !this.financeLeaderApproval) ? this.contract.financeApprover.email : null,
          Language: this.languageAvailable ? this.contract.language : null,
          LegalApproverEmail: this.legalApproverAvailable ? this.contract.legalApprover.email : null,
          ManagerApproverEmail: this.contract.managerApprover.email || '',
          MsaLsa: this.msalsaAvailable ? this.contract.msalsa : null,
          NielsenLegalEntityName: this.salesOrganizationInLanguage,
          NielsenStandardTemplate: this.isContractGeneratorAvailable ? this.contract.contractGenerator : null,
          PaymentTerms: this.paymentTermsAvailable ? this.contract.paymentTerms : null,
          PaymentTermsOthers: this.paymentTermsOthersAvailable ? this.contract.paymentTermsOthers : null,
          ProductServiceType: this.contractTemplateAvailable ? this.contract.usedTemplate : 'Others',
          ProjectDescription: this.projectDescriptionAvailable ? this.contract.projectDescription : null,
          Team: this.contract.team || '',
          contractId: this.contract.id,
          id: this.contract.id,
          requestId: this.contract.requestId,
          toUser: this.userEmail,
          uploadList: (this.sowAvailable ? this.contract.sow : []),
          uploadSOW: (this.sowAvailable ? this.contract.sow : []),
          user: this.userEmail,
          token: this.token
        }

        let billingTermText = this.getBillingTermText()
        let paymentTermText = this.getPaymentTermText()

        const res = await xPost(
          'contract_review',
          'createContractInformationPreview',
          {
            loginStatus: this.loginStatus,
            template: this.contractTemplateAvailable ? this.contract.usedTemplate : '',
            formItem,
            terms: {
              Billingneed: billingTermText,
              Paymentneed: paymentTermText,
            },
          }
        )
        if (this.toShowGenerateContract && this.isContractPreviewing && tfPreviewingContract === this.tfPreviewingContract) {
          if (res.data.status === 200) {
            this.contractPdfMsPath = res.data.pdf
            this.contractDocMsPath = res.data.doc
            this.contractPdfLocal = res.data.pdfl
            this.contractDocLocal = res.data.docl
            // this.$eventBus.$emit('refreshGapi')
            setTimeout(async () => {
              if (
                !!this.contractPdfMsPath
                && !!this.contractDocMsPath
                && !!this.contractPdfLocal
                && !!this.contractDocLocal
              ) {
                await this.draftStepContractPreviewNext()
                this.isPreviewContractLoading = false
                this.isFreshContractPreviewing = true
              }
            }, 2500);
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return
              }
            } catch (e) {}
            throw ''
          }
        }
      } catch (err) {
        this.isPreviewContractLoading = false
        this.isContractPreviewing = false
        console.log('Error [generate preview contract]')
        // console.log(err)
        this.$eventBus.$emit('snackbar', { text: 'Fail to generate contract preview', type: 'error' })
      }
    },
    async postSubmitContract () {
      if (this.warnUserAccess()) return
      if (!this.checkOpportunities()) return
      if (this.warnContractType()) return
      if (this.warnDraftedContract()) return
      if (this.warnContractInfo()) return
      if (this.warnApprovalInfo()) return

      this.$eventBus.$emit('snackbar', { text: 'Sending Contract for Approval', type: 'loading' })

      this.contract.toDraftStep = this.contract.draftStep
      let saveToken = await this.postSaveContractInfo()
      if (!saveToken) {
        this.$eventBus.$emit('snackbar', { text: 'Fail to save contract', type: 'error' })
        return
      }

      let action = 2
      let toUser = this.contract.managerApprover.email
      let successful = false

      let snackbarParams = { text: 'Fail to send contract to your manager', type: 'error' }
      try {
        const res = await xPost(
          'contract_review',
          'contractReviewComment',
          {
            ver: this.contract.version,
            user: this.userEmail,
            toUser,
            contractId: this.contract.id,
            token: saveToken,
            action,
            content: this.comments.content || '',
            FinanceNeed: this.financeApproverAvailableString,
            CiApprovalNeed: this.ciApproverAvailableString,
            loginStatus: this.loginStatus,
            contractPdfMsPath: this.contractPdfMsPath,
            contractDocMsPath: this.contractDocMsPath,
            contractPdfLocal: this.contractPdfLocal,
            contractDocLocal: this.contractDocLocal,
            attached: [...this.comments.files.map(el => ({
              ...el,
              response: {
                content: '',
                detail: [{
                  url: el.FileName,
                  type: el.type
                }],
                message: 'Success',
                status: 200
              }
            }))].reverse()
          }
        )
        if (res.data.status === 200) {
          successful = true
          await this.getData()
          // this.$eventBus.$emit('refreshGapi') // this.$eventBus.$emit('refreshUserWholly')
          snackbarParams = { text: 'Contract has been sent to your manager', type: 'success' }
          this.closeSendComments()
        } else if (res.data.status == 401 && res.data.detail == 'Action Stage Not Matched') {
          await this.getData()
          snackbarParams = { text: 'Fail to send contract to your manager, since request status has been changed.', type: 'error' }
          this.closeSendComments()
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [submit contract]')
        // console.log(err)
      }
      if (successful && this.userEmail === this.contract.managerApprover.email) {
        this.postApproveContract()
      } else {
        this.$eventBus.$emit('snackbar', snackbarParams)
      }
    },
    async postRecallContract () {
      let snackbarParams = { text: 'Fail to recall contract', type: 'error' }
      try {
        this.$eventBus.$emit('snackbar', { text: 'Recalling Contract', type: 'loading' })

        let action
        switch (this.recall.type) {
          case 1:
            action = 97
            break;
        
          case 2:
            action = 115
            break;
        
          default:
            snackbarParams = { text: 'Please select Resubmit/Resign.', type: 'error' }
            throw ''
            break;
        }
        let toUser = this.contract.requestor.email

        const res = await xPost(
          'contract_review',
          'contractReviewComment',
          {
            ver: this.contract.version,
            user: this.userEmail,
            toUser,
            contractId: this.contract.id,
            token: this.token,
            action,
            content: '',
            FinanceNeed: this.financeApproverAvailableString,
            CiApprovalNeed: this.ciApproverAvailableString,
            loginStatus: this.loginStatus,
            attached: []
          }
        )
        if (res.data.status === 200) {
          await this.getData()
          snackbarParams = { text: 'Contract Recalled', type: 'success' }
          this.isContractRecalling = false
          // this.$eventBus.$emit('refreshGapi')
        } else if (res.data.status == 401 && res.data.detail == 'Action Stage Not Matched') {
          await this.getData()
          snackbarParams = { text: 'Fail to recall contract, since request status has been changed.', type: 'error' }
        } else {
          console.log(res.data)
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [recall contract]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
      this.isContractPreviewing = false
    },
    async postReviseDraftedContract () {
      let action, toUser

      let snackbarParams = { text: 'Fail to revise draft contract', type: 'error' }
      try {
        if (this.isManagerApprovingContract) {
          action = 5
          toUser = this.contract.managerApprover.email
        }
        if (this.isFinanceApprovingContract) {
          action = 9
          toUser = this.contract.financeApprover.email
        }
        if (this.isLegalApprovingContract) {
          action = 13
          toUser = this.contract.legalApprover.email
        }
        if (this.isCpgApprovingContract) {
          action = 33
          toUser = this.contract.cpgApprover.email
        }
        if (this.isCiApprovingContract) {
          action = 41
          toUser = this.contract.ciApprover.email
        }

        this.$eventBus.$emit('snackbar', { text: 'Updating Draft Contract', type: 'loading' })
        this.isNewDraftedContractUploadConfirming = false

        const res = await xPost(
          'contract_review',
          'contractReviewComment',
          {
            ver: this.contract.version,
            user: this.userEmail,
            toUser,
            contractId: this.contract.id,
            token: this.token,
            action,
            content: this.newDraftedContract.comments || '',
            FinanceNeed: this.financeApproverAvailableString,
            CiApprovalNeed: this.ciApproverAvailableString,
            loginStatus: this.loginStatus,
            attached: [...this.newDraftedContract.files.map(el => ({
              ...el,
              response: {
                content: '',
                detail: [{
                  url: el.FileName,
                  type: el.type
                }],
                message: 'Success',
                status: 200
              }
            }))]
          }
        )
        if (res.data.status === 200) {
          await this.getData()
          snackbarParams = { text: 'Draft Contract Updated', type: 'success' }
          this.isNewDraftedContractUploadCardShowing = false
          // this.$eventBus.$emit('refreshGapi')
        } else if (res.data.status == 401 && res.data.detail == 'Action Stage Not Matched') {
          await this.getData()
          snackbarParams = { text: 'Fail to revise draft contract, since request status has been changed.', type: 'error' }
          this.isNewDraftedContractUploadCardShowing = false
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [update drafted contract]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postApproveContract () {
      this.isContractApproving = false
      this.$eventBus.$emit('snackbar', { text: 'Approving Contract', type: 'loading' })

      let snackbarParams = { text: 'Fail to approve contract', type: 'error' }

      let action = 0
      if (this.isManagerApprover && this.isManagerApprovingContract) {
        action = 6
      } else if (this.isFinanceApprover && this.isFinanceApprovingContract) {
        action = 10
      } else if (this.isLegalApprover && this.isLegalApprovingContract) {
        action = 18
      } else if (this.isCpgApprover && this.isCpgApprovingContract) {
        action = 34
      }else if (this.isCiApprover && this.isCiApprovingContract) {
        action = 42
      }
      if (action) {
        let toUser = this.contract.requestor.email
        try {
          const res = await xPost(
            'contract_review',
            'contractReviewComment',
            {
              ver: this.contract.version,
              user: this.userEmail,
              toUser,
              contractId: this.contract.id,
              token: this.token,
              action,
              content: this.contract.approvalComments || '',
              FinanceNeed: this.financeApproverAvailableString,
              CiApprovalNeed: this.ciApproverAvailableString,
              loginStatus: this.loginStatus,
              attached: []
            }
          )

          // clear comments
          this.contract.approvalComments = ''
          
          if (res.data.status === 200) {
            await this.getData()
            this.isContractApproving = false
            this.toShowGenerateContract = false
            snackbarParams = { text: 'Contract Approved', type: 'success' }
            if (!(this.isRequestor || this.isCollaborator || this.canApprove)) {
              this.$router.push('/approval')
            } 
            // this.$eventBus.$emit('refreshGapi') // this.$eventBus.$emit('refreshUserWholly')
          } else if (res.data.status == 401 && res.data.detail == 'Action Stage Not Matched') {
            await this.getData()
            snackbarParams = { text: 'Fail to approve contract, since request status has been changed.', type: 'error' }
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return
              }
            } catch (e) {}
            throw ''
          }
        } catch (err) {
          console.log('Error [approve contract]')
          // console.log(err)
        }
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postSendComments () {
      if (!this.$refs['contract-comment-form'].validate()) return

      this.$eventBus.$emit('snackbar', { text: 'Sending Comments', type: 'loading' })

      let snackbarParams = { text: 'Fail to send comments', type: 'error' }

      let action = 0
      let toUser = this.contract.requestor.email
      if (this.isManagerApprovingContract) {
        if (this.isManagerApprover) {
          action = 19
          // if (!this.comments.to || !this.comments.to.email || this.comments.to.name === this.comments.defaultRequestors.name) {
          //   action = 19
          // } else {
          //   action = 25
          //   toUser = this.comments.to.email
          // }
        } else if (this.isRequestor || this.isCollaborator) {
          action = 4
          toUser = this.contract.managerApprover.email
        } else if (this.isReviewer || this.isAt) {
          action = 28
          toUser = this.contract.managerApprover.email
        }
      } else if (this.isFinanceApprovingContract) {
        if (this.isFinanceApprover) {
          action = 20
          // if (!this.comments.to || !this.comments.to.email || this.comments.to.name === this.comments.defaultRequestors.name) {
          //   action = 20
          // } else {
          //   action = 26
          //   toUser = this.comments.to.email
          // }
        } else if (this.isRequestor || this.isCollaborator) {
          action = 8
          toUser = this.contract.financeApprover.email
        } else if (this.isReviewer || this.isAt) {
          action = 29
          toUser = this.contract.financeApprover.email
        }
      } else if (this.isLegalApprovingContract) {
        if (this.isLegalApprover) {
          action = 21
          // if (!this.comments.to || !this.comments.to.email || this.comments.to.name === this.comments.defaultRequestors.name) {
          //   action = 21
          // } else {
          //   action = 27
          //   toUser = this.comments.to.email
          // }
        } else if (this.isRequestor || this.isCollaborator) {
          action = 16
          toUser = this.contract.legalApprover.email
        } else if (this.isReviewer || this.isAt) {
          action = 30
          toUser = this.contract.legalApprover.email
        }
      } else if (this.isCpgApprovingContract) {
        if (this.isCpgApprover) {
          action = 36
          // if (this.comments.to.name === this.comments.defaultRequestors.name) {
          //   action = 36
          // } else {
          //   action = 37
          //   toUser = this.comments.to.email
          // }
        } else if (this.isRequestor || this.isCollaborator) {
          action = 32
          toUser = this.contract.cpgApprover.email
        } else if (this.isReviewer || this.isAt) {
          action = 38
          toUser = this.contract.cpgApprover.email
        }
      } else if (this.isCiApprovingContract) {
        if (this.isCiApprover) {
          action = 44
          // if (!this.comments.to || !this.comments.to.email || this.comments.to.name === this.comments.defaultRequestors.name) {
          //   action = 44
          // } else {
          //   action = 45
          //   toUser = this.comments.to.email
          // }
        } else if (this.isRequestor || this.isCollaborator) {
          action = 40
          toUser = this.contract.ciApprover.email
        } else if (this.isReviewer || this.isAt) {
          action = 46
          toUser = this.contract.ciApprover.email
        }
      } else if (this.isArchivingContract) {
        if (this.isArchiveApprover || this.isArchiveSupporter) {
          action = 114
          // if (!this.comments.to || !this.comments.to.email || this.comments.to.name === this.comments.defaultRequestors.name) {
          //   action = 44
          // } else {
          //   action = 45
          //   toUser = this.comments.to.email
          // }
        } else if (this.isRequestor || this.isCollaborator) {
          action = 111
          toUser = this.contract.archiveApprover.email
        } else if (this.isReviewer || this.isAt) {
          action = 113
          toUser = this.contract.archiveApprover.email
        }
      } else if (this.isJustApprovedContract) {
        if (this.isRequestor || this.isCollaborator) {
          action = 96
          toUser = ''
        }
      } else if (this.isIntSigningContract) {
        if (this.isRequestor || this.isCollaborator) {
          action = 107
          toUser = ''
        }
      } else if (this.isIntSignedContract) {
        if (this.isRequestor || this.isCollaborator) {
          action = 108
          toUser = ''
        }
      } else if (this.isExtSigningContract) {
        if (this.isRequestor || this.isCollaborator) {
          action = 109
          toUser = ''
        }
      } else if (this.isExtSignedContract) {
        if (this.isRequestor || this.isCollaborator) {
          action = 110
          toUser = ''
        }
      } else if (this.isArchivedContract) {
        if (this.isRequestor || this.isCollaborator) {
          action = 112
          toUser = ''
        }
      }
      if (action) {
        try {
          const res = await xPost(
            'contract_review',
            'contractReviewComment',
            {
              ver: this.contract.version,
              user: this.userEmail,
              toUser,
              contractId: this.contract.id,
              token: this.token,
              action,
              content: this.comments.content || '',
              FinanceNeed: this.financeApproverAvailableString,
              CiApprovalNeed: this.ciApproverAvailableString,
              loginStatus: this.loginStatus,
              attached: [...this.comments.files.map(el => ({
                ...el,
                response: {
                  content: '',
                  detail: [{
                    url: el.FileName,
                    type: el.type
                  }],
                  message: 'Success',
                  status: 200
                }
              }))].reverse()
            }
          )
          if (res.data.status === 200) {
            await this.getData()
            snackbarParams = { text: 'Comments Sent', type: 'success' }
            this.closeSendComments()
            // this.$eventBus.$emit('refreshGapi')
          } else if (res.data.status == 401 && res.data.detail == 'Action Stage Not Matched') {
            await this.getData()
            snackbarParams = { text: 'Fail to send comments, since request status has been changed.', type: 'error' }
            this.closeSendComments()
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return
              }
            } catch (e) {}
            throw ''
          }
        } catch (err) {
          console.log('Error [send comments]')
          // console.log(err)
        }
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async downloadFile (data) {
      try {
        const res = await xDownload(
          data.item.url,
          data.item.name,
          { 
            loginStatus: this.loginStatus
          }
        )
        try {
          if (res.data.message === 'Authorize Failed') {
            this.$router.push('/login')
            return
          }
        } catch (e) {
          console.log(e)
          throw ''
        }
      } catch (e) {
        console.log('Error [download file]')
        console.log(e)
      }
    },

    deleteSow (data) {
      this.contract.sow.splice(data.index, 1)
    },
    deleteDraftedContract (data) {
      this.contract.draftedContract.splice(data.index, 1)
    },
    deleteNewDraftedContract (data) {
      this.newDraftedContract.files.splice(data.index, 1)
    },
    deleteAttachment (data) {
      this.comments.files.splice(data.index, 1)
    },

    beforeUploadSow(file) {
      this.isSowUploading = true
    },
    errorUploadSow(err, file) {
      console.log('Error [upload sow]')
      // console.log(err)
      // console.log(file)
      this.isSowUploading = false
    },
    async successUploadSow(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.contract.sow = [{
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 'SOW',
          UploadTime: time,
          token: this.token
        }]
        this.isSowUploadConfirming = false

        if (this.toShowGenerateContract) {
          this.contract.toDraftStep = this.contract.draftStep
          await this.postSaveContractInfo()
          // let token = await this.postSaveContractInfo()

          // if (!!token) {
          //   this.postRefreshSowPreviewGDoc(token)
          // }
        }
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload sow]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isSowUploading = false
    },

    beforeUploadDraftedContract(file) {
      this.isDraftedContractUploading = true
    },
    errorUploadDraftedContract(err, file) {
      console.log('Error [upload drafted contract]')
      // console.log(err)
      // console.log(file)
      this.isDraftedContractUploading = false
    },
    successUploadDraftedContract(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.contract.draftedContract.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 'drafted',
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload drafted contract]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isDraftedContractUploading = false
    },

    beforeUploadAttachment(file) {
      this.isAttachmentUploading = true
    },
    errorUploadAttachment(err, file) {
      console.log('Error [upload attachment]')
      // console.log(err)
      // console.log(file)
      this.isAttachmentUploading = false
    },
    successUploadAttachment(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.comments.files.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: '',
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload attachment]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isAttachmentUploading = false
    },

    beforeUploadNewDraftedContract(file) {
      this.isNewDraftedContractUploading = true
    },
    errorUploadNewDraftedContract(err, file) {
      console.log('Error [upload new drafted contract]')
      // console.log(err)
      // console.log(file)
      this.isNewDraftedContractUploading = false
    },
    successUploadNewDraftedContract(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.newDraftedContract.files.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 'drafted',
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload new drafted contract]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isNewDraftedContractUploading = false
    },
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    getMaxElements(array, key) {
      try {
        let temp_array, max
        if (key) {
          temp_array = array.map(el => el[key])
        } else {
          temp_array = [...array]
        }
        max = temp_array.sort((a, b) => b > a ? 1 : (a > b ? -1 : 0))[0]
        if (key) {
          return array.filter(el => el[key] === max)
        } else {
          return array.filter(el => el === max)
        }
      } catch (err) {
        // console.log(err)
        return array
      }
    },

    warnUserAccess () {
      let warning = ''

      try {
        if (
          (
            typeof this.costCenter === 'undefined'
            || this.costCenter.length < 7
          )
          && (this.extraRequestors.map(x => x.toLowerCase()).indexOf(this.loginStatus.userInfo.primaryEmail.toLowerCase()) === -1)
        ) {
          warning = 'You don\'t have right to save or submit request.'
        }
      } catch (err) {
        console.log('Error [warn user access]')
        // console.log(err)
      }

      if (warning) this.$eventBus.$emit('snackbar', { text: warning, type: 'warning' })
      return warning
    },
    warnOpportunities () {
      let warning = ''

      try {
        if (this.opportunities.length < 1) {
          warning = 'Please add opportunitiy'
          if (this.$refs['contract_opportunities']) { this.$refs['contract_opportunities'].focus() }
        }
      } catch (err) {
        console.log('Error [warn opportunities]')
        // console.log(err)
      }

      if (warning) this.$eventBus.$emit('snackbar', { text: warning, type: 'warning' })
      return warning
    },
    checkOpportunities () {
      try {
        if (!this.opportunities.length > 0) {
          this.fixingOpportunity = true
          docScrollToId('contract-review-draft-step-0', 120, 60)
          return false
        }
        return true
      } catch (err) {
        console.log('Error [check opportunities]')
        // console.log(err)
      }
      
      return false
    },

    contractTypeMissing () {
      try {
        return this.contractTypeList.findIndex(el => el.value === this.contract.type) < 0
      } catch (err) {
        return false
      }
    },
    contractGeneratorMissing () {
      try {
        return this.isContractGeneratorAvailable && !this.contract.contractGenerator
      } catch (err) {
        return false
      }
    },
    contractTypeCardReady () {
      return !(this.contractTypeMissing() || this.contractGeneratorMissing())
    },

    draftedContractMissing () {
      try {
        return this.isDraftedContractAvailable && this.contract.draftedContract.length < 1
      } catch (err) {
        return false
      }
    },
    draftedContractReady () {
      return !this.draftedContractMissing()
    },

    isLanguageMissing () {
      try {
        return this.languageAvailable && !this.contract.language
      } catch (err) {
        return false
      }
    },
    isContractTemplateMissing () {
      try {
        return this.contractTemplateAvailable && this.contractTemplateList.indexOf(this.contract.usedTemplate) < 0
      } catch (err) {
        return false
      }
    },
    isSalesOrganizationMissing () {
      try {
        return this.salesOrganizationListForSelect.findIndex(el => el.value === this.contract.salesOrganization) < 0
      } catch (err) {
        return false
      }
    },
    isSapMissing () {
      try {
        return !this.contract.sap
      } catch (err) {
        return false
      }
    },
    isCustomerNumber () {
      try {
        return !this.contract.customerNumber
      } catch (err) {
        return false
      }
    },
    isAmountMissing () {
      try {
        return this.amountAvailable && !this.contract.amount
      } catch (err) {
        return false
      }
    },
    isCurrencyMissing () {
      try {
        return this.currencyAvailable && this.contractCurrencyList.findIndex(el => el.value === this.contract.currency) < 0
      } catch (err) {
        return false
      }
    },
    isStartDateMissing () {
      try {
        return !this.contract.startDate
      } catch (err) {
        return false
      }
    },
    isEndDateMissing () {
      try {
        return !this.contract.endDate
      } catch (err) {
        return false
      }
    },
    isStartDateGreaterThanEndDate () {
      try {
        return !!this.contract.startDate && !!this.contract.endDate && this.contract.startDate > this.contract.endDate
      } catch (err) {
        return false
      }
    },
    isBillingTermMissing () {
      try {
        return this.billingTermsAvailable && !this.billingTermsValues.filter(el => el.value === this.contract.billingTerms).length
      } catch (err) {
        return false
      }
    },
    isBillingTermOthersMissing () {
      try {
        return this.billingTermsOthersAvailable && !this.contract.billingTermsOthers
      } catch (err) {
        return false
      }
    },
    isBillingTermOthersOverload () {
      try {
        if (this.billingTermsOthersAvailable && this.contract.billingTermsOthers) {
          return this.contract.billingTermsOthers.length > this.termsOthersMax
        } else {
          return false
        }
      } catch (err) {
        return false
      }
    },
    isPaymentTermMissing () {
      try {
        return this.paymentTermsAvailable && !this.paymentTermsValues.filter(el => el.value === this.contract.paymentTerms).length
      } catch (err) {
        return false
      }
    },
    isPaymentTermOthersMissing () {
      try {
        return this.paymentTermsOthersAvailable && !this.contract.paymentTermsOthers
      } catch (err) {
        return false
      }
    },
    isPaymentTermOthersOverload () {
      try {
        if (this.paymentTermsOthersAvailable && this.contract.paymentTermsOthers) {
          return this.contract.paymentTermsOthers.length > this.termsOthersMax
        } else {
          return false
        }
      } catch (err) {
        return false
      }
    },
    isMsalsaMissing () {
      try {
        return this.msalsaAvailable && !this.contract.msalsa
      } catch (err) {
        return false
      }
    },
    isProjectDescriptionMissing () {
      try {
        return this.projectDescriptionAvailable && !this.contract.projectDescription
      } catch (err) {
        return false
      }
    },
    isProjectDescriptionOverload () {
      try {
        if (this.projectDescriptionAvailable && this.contract.projectDescription) {
          return this.contract.projectDescription.length > this.commentsMax
        } else {
          return false
        }
      } catch (err) {
        return false
      }
    },
    isSowMissing () {
      try {
        return this.sowAvailable && this.contract.sow.length < 1
      } catch (err) {
        return false
      }
    },
    isContractInfoReady () {
      return !(
        this.isLanguageMissing() ||
        this.isContractTemplateMissing() ||
        this.isSalesOrganizationMissing() ||
        this.isSapMissing() ||
        this.isCustomerNumber() ||
        this.isAmountMissing() ||
        this.isCurrencyMissing() ||
        this.isStartDateMissing() ||
        this.isEndDateMissing() ||
        this.isStartDateGreaterThanEndDate() ||
        this.isBillingTermMissing() ||
        this.isBillingTermOthersMissing() ||
        this.isBillingTermOthersOverload() ||
        this.isPaymentTermMissing() ||
        this.isPaymentTermOthersMissing() ||
        this.isPaymentTermOthersOverload() ||
        this.isMsalsaMissing() ||
        this.isProjectDescriptionMissing() ||
        this.isProjectDescriptionOverload() ||
        this.isSowMissing()
      )
    },
    isContractInfoSavable () {
      return !(
        this.isStartDateGreaterThanEndDate() ||
        this.isBillingTermOthersOverload() ||
        this.isPaymentTermOthersOverload() ||
        this.isProjectDescriptionOverload()
      )
    },

    isManagerApproverMissing () {
      try {
        return !this.contract.managerApprover.email
      } catch (err) {
        return false
      }
    },
    isFinanceApproverMissing () {
      try {
        return this.financeApproverAvailable && !this.contract.financeApprover.email
      } catch (err) {
        return false
      }
    },
    isFinanceApproverNotInList () {
      try {
        return this.financeApproverAvailable && !this.financeLeaderApproval && (this.financeApproversOptions.findIndex(el => el.email === this.contract.financeApprover.email) < 0)
      } catch (err) {
        return false
      }
    },
    isLegalApproverMissing () {
      try {
        return this.legalApproverAvailable && !this.contract.legalApprover.email
      } catch (err) {
        return false
      }
    },
    isLegalApproverNotInList () {
      try {
        return this.legalApproverAvailable && (this.legalApproversOptions.findIndex(el => el.email === this.contract.legalApprover.email) < 0)
      } catch (err) {
        return false
      }
    },
    isBackgroundBriefingOverload () {
      try {
        if (this.contract.backgroundBriefing) {
          return this.contract.backgroundBriefing.length > this.commentsMax
        } else {
          return false
        }
      } catch (err) {
        return false
      }
    },
    isApprovalInfoReady () {
      return !(
        this.isManagerApproverMissing() ||
        this.isFinanceApproverMissing() ||
        this.isFinanceApproverNotInList() ||
        this.isLegalApproverMissing() ||
        this.isLegalApproverNotInList() ||
        this.isBackgroundBriefingOverload()
      )
    },
    isApprovalInfoSavable () {
      return !(
        this.isBackgroundBriefingOverload()
      )
    },

    warnContractType () {
      let warning = ''

      try {
        if (!warning && this.contractTypeMissing()) {
          warning = 'Please select Contract Type'
          if (this.$refs['contract_form_type']) { this.$refs['contract_form_type'].focus() }
        }
        if (!warning && this.contractGeneratorMissing()) {
          warning = 'Please select Contract Type'
          if (this.$refs['contract_form_generator']) { this.$refs['contract_form_generator'].focus() }
        }
      } catch (err) {
        console.log('Error [warn contract type]')
        // console.log(err)
      }

      if (warning) this.$eventBus.$emit('snackbar', { text: warning, type: 'warning' })
      return warning
    },

    warnDraftedContract () {
      let warning = ''

      try {
        if (!warning && this.draftedContractMissing()) {
          warning = 'Please upload Draft Contract'
          if (this.$refs['contract_form_draft_contract']) { this.$refs['contract_form_draft_contract'].focus() }
        }
      } catch (err) {
        console.log('Error [warn drafted contract]')
        // console.log(err)
      }

      if (warning) this.$eventBus.$emit('snackbar', { text: warning, type: 'warning' })
      return warning
    },

    warnContractInfo () {
      let warning = ''

      try {
        if (!warning && this.isLanguageMissing()) {
          warning = 'Please select Language'
          if (this.$refs['contract_form_language']) { this.$refs['contract_form_language'].focus() }
        }
        if (!warning && this.isContractTemplateMissing()) {
          warning = 'Please select Contract Template'
          if (this.$refs['contract_form_template']) { this.$refs['contract_form_template'].focus() }
        }
        if (!warning && this.isSalesOrganizationMissing()) {
          warning = 'Please select Sales Organization'
          if (this.$refs['contract_form_sales_organization']) { this.$refs['contract_form_sales_organization'].focus() }
        }
        if (!warning && this.isSapMissing()) {
          warning = 'Please select Sold To Party (SAP)'
          if (this.$refs['contract_form_sap']) { this.$refs['contract_form_sap'].focus() }
        }
        if (!warning && this.isCustomerNumber()) {
          warning = 'Please select Customer Number (SAP)'
          if (this.$refs['contract_form_customer_number']) { this.$refs['contract_form_customer_number'].focus() }
        }
        if (!warning && this.isAmountMissing()) {
          warning = 'Please input Amount'
          if (this.$refs['contract_form_amount']) { this.$refs['contract_form_amount'].focus() }
        }
        if (!warning && this.isCurrencyMissing()) {
          warning = 'Please select Currency'
          if (this.$refs['contract_form_currency']) { this.$refs['contract_form_currency'].focus() }
        }
        if (!warning && this.isStartDateMissing()) {
          warning = 'Please select Contract Start Date'
          if (this.$refs['contract_form_start_date']) { this.$refs['contract_form_start_date'].focus() }
        }
        if (!warning && this.isEndDateMissing()) {
          warning = 'Please select Contract End Date'
          if (this.$refs['contract_form_end_date']) { this.$refs['contract_form_end_date'].focus() }
        }
        if (!warning && this.isStartDateGreaterThanEndDate()) {
          warning = 'Please confirm Contract End Date is not less than Contract Start Date'
          if (this.$refs['contract_form_end_date']) { this.$refs['contract_form_end_date'].focus() }
        }
        if (!warning && this.isBillingTermMissing()) {
          warning = 'Please select Billing Terms'
          if (this.$refs['contract_form_billing_terms']) { this.$refs['contract_form_billing_terms'].focus() }
        }
        if (!warning && this.isBillingTermOthersMissing()) {
          warning = 'Please input Other Specify of Billing Terms'
          if (this.$refs['contract_form_billing_terms_others']) { this.$refs['contract_form_billing_terms_others'].focus() }
        }
        if (!warning && this.isBillingTermOthersOverload()) {
          warning = 'Please check Other Specify of Billing Terms which is overloaded'
          if (this.$refs['contract_form_billing_terms_others']) { this.$refs['contract_form_billing_terms_others'].focus() }
        }
        // if (!warning && this.billingTermsOthersAvailable && !this.contract.billingTermsOthers) warning = ''
        if (!warning && this.isPaymentTermMissing()) {
          warning = 'Please select Payment Terms'
          if (this.$refs['contract_form_payment_terms']) { this.$refs['contract_form_payment_terms'].focus() }
        }
        if (!warning && this.isPaymentTermOthersMissing()) {
          warning = 'Please input Other Specify of Payment Terms'
          if (this.$refs['contract_form_payment_terms_others']) { this.$refs['contract_form_payment_terms_others'].focus() }
        }
        if (!warning && this.isPaymentTermOthersOverload()) {
          warning = 'Please check Other Specify of Payment Terms which is overloaded'
          if (this.$refs['contract_form_payment_terms_others']) { this.$refs['contract_form_payment_terms_others'].focus() }
        }
        // if (!warning && this.paymentTermsOthersAvailable && !this.contract.paymentTermsOthers) warning = ''
        if (!warning && this.isMsalsaMissing()) {
          warning = 'Please select Follow MSA/LSA'
          if (this.$refs['contract_form_msalsa']) { this.$refs['contract_form_msalsa'].focus() }
        }
        if (!warning && this.isProjectDescriptionMissing()) {
          warning = 'Please input Project Description'
          if (this.$refs['contract_form_project_description']) { this.$refs['contract_form_project_description'].focus() }
        }
        if (!warning && this.isProjectDescriptionOverload()) {
          warning = 'Please check Project Description which is overloaded'
          if (this.$refs['contract_form_project_description']) { this.$refs['contract_form_project_description'].focus() }
        }
        if (!warning && this.isSowMissing()) {
          warning = 'Please upload Scope of Work (SOW - Word .DOCX Only)'
          if (this.$refs['contract_form_sow']) { this.$refs['contract_form_sow'].focus() }
        }
      } catch (err) {
        console.log('Error [warn contract info]')
        // console.log(err)
      }

      if (warning) this.$eventBus.$emit('snackbar', { text: warning, type: 'warning' })
      return warning
    },
    async postSignFlowInfo () {
      try {
        const res = await xPost(
          'contract_review',
          'signflow',
          {
            loginStatus: this.loginStatus,
            token: this.token
          },
        )
        if (res.data.status === 200) {
          let signFlow = res.data.signFlow
          this.signFlow.esignFlowId = signFlow.esignFlowId || null
          this.signFlow.type = parseInt(signFlow.type) || null
          this.signFlow.extSigner.need = (signFlow.needExtSign !== '0')

          let intSigner = signFlow.signer.find(el => el.type === '1')
          if (!!intSigner) {
            this.signFlow.intSigner.signed = (intSigner.signed !== '0')
            this.signFlow.intSigner.url = intSigner.url
          }

          let extSigner = signFlow.signer.find(el => el.type === '2')
          if (!!extSigner) {
            this.signFlow.extSigner.signed = (extSigner.signed !== '0')
            this.signFlow.extSigner.url = extSigner.url
          }
        }
      } catch (err) {
        console.log('Error [post load data - get sign flow info]')
        console.log(err)
        this.$router.push('/legal/request/list')
      }
    },

    warnApprovalInfo () {
      let warning = ''

      try {
        if (!warning) {
          if (this.isManagerApproverMissing()) {
            warning = 'Please select Commercial Leader Approver'
            if (this.$refs['contract_form_manager_approver']) { this.$refs['contract_form_manager_approver'].focus() }
          }
        }
        if (!warning) {
          if (this.isFinanceApproverMissing()) {
            warning = 'Please select Finance Approver'
            if (this.$refs['contract_form_finance_approver']) { this.$refs['contract_form_finance_approver'].focus() }
          }
        }
        if (!warning) {
          if (this.isFinanceApproverNotInList()) {
            warning = 'Finance Approver "' + this.contract.financeApprover.name +'" is out of approver group, please select another Finance Approver'
            if (this.$refs['contract_form_finance_approver']) { this.$refs['contract_form_finance_approver'].focus() }
          }
        }
        if (!warning) {
          if (this.isLegalApproverMissing()) {
            warning = 'Please select Legal Approver'
            if (this.$refs['contract_form_legal_approver']) { this.$refs['contract_form_legal_approver'].focus() }
          }
        }
        if (!warning) {
          if (this.isLegalApproverNotInList()) {
            warning = 'Legal Approver "' + this.contract.legalApprover.name +'" is out of approver group, please select another Legal Approver'
            if (this.$refs['contract_form_legal_approver']) { this.$refs['contract_form_legal_approver'].focus() }
          }
        }
        if (!warning && this.isBackgroundBriefingOverload()) {
          warning = 'Please check Background Briefing which is overloaded'
          if (this.$refs['contract_form_background_briefing']) { this.$refs['contract_form_background_briefing'].focus() }
        }
        // contract_form_project_description
      } catch (err) {
        console.log('Error [warn approval info]')
        // console.log(err)
      }

      if (warning) this.$eventBus.$emit('snackbar', { text: warning, type: 'warning' })
      return warning
    },

    doAddOpportunity(opportunity) {
      let index = this.opportunities.findIndex(el => el.id === opportunity.id)

      if (index > -1) {
        this.opportunities.splice(index, 1, opportunity)
      } else {
        this.opportunities.push(opportunity)
      }
    }, 
    closeAddOpportunity () {
      this.addingOpportunity = false
      setTimeout(() => {
        this.opportunityKeywordField = 'id'
        this.opportunityKeywordSearching = ''
        this.opportunitiesToAdd = []
        this.indexOfOpportunitiesToAdd = []
      }, 300);
    },
    closeAddOpportunityNew () {
      this.addingOpportunity = false
    },
    removeOpportunity (index) {
      this.opportunities.splice(index, 1)
    },

    showShareTo () {
      this.isAccessSharing = true
    },
    closeShareTo () {
      this.isAccessSharing = false
    },
    showForward () {
      this.isReviewerForwarding = true
    },
    closeForward () {
      this.isReviewerForwarding = false
    },
    showShareAccess () {
      this.showShareTo()
    },

    cancelNewContract () {
      this.$router.push('/legal/request/list')
    },

    showSendComments(isToSumbit) {
      if (this.$refs['contract-comment-form']) {
        this.$refs['contract-comment-form'].resetValidation()
      }
      this.resetComments()
      if (!!isToSumbit) {this.isContractSubmitting = true}
      this.isCommentsSending = true
    },
    closeSendComments() {
      this.isCommentsSending = false
      this.isContractSubmitting = false
    },
    resetComments () {
      this.comments.to = this.listApproverCommentTo.length === 1 ? this.listApproverCommentTo[0] : null
      this.comments.content = null
      this.comments.files = []
    },
    confirmSubmit () {
      this.approvalInfoChecked = true
      if (!this.$refs['approval-info-form'].validate()) return
      
      if (this.warnUserAccess()) return
      if (!this.checkOpportunities()) return
      if (this.warnContractType()) return
      if (this.warnDraftedContract()) return
      if (this.warnContractInfo()) return
      if (this.warnApprovalInfo()) return

      this.showSendComments(1)
    },
    async downloadDoc() {
      let url = (
        await this.$refs[this.refMsalFunction].getDownloadUrlByPath(
          this.isApprovedContract ? this.contractPdfMsPath : this.contractDocMsPath
        )
      ) || null
      if (!url) return
      const elemIF = document.createElement('iframe')
      elemIF.src = url
      elemIF.style.display = 'none'
      document.body.appendChild(elemIF)
    },
    async downloadSignFile (index) {
      let url = await this.postGetEsignDownloadUrl(index)
      if (!url) return

      const elemIF = document.createElement('iframe')
      elemIF.src = url
      elemIF.style.display = 'none'
      document.body.appendChild(elemIF)
    },

    showSowContract () {
      this.toShowGenerateContract = true
    },
    showUploadNewDraftedContract () {
      this.newDraftedContract.files = []
      this.newDraftedContract.comments = ''
      this.isNewDraftedContractUploadCardShowing = true
    },
    confirmNewDraftedContract () {
      if (this.newDraftedContract.files.length < 1) {
        this.$eventBus.$emit('snackbar', { text: 'Please upload new contract', type: 'warning' })
        return
      }
      this.isNewDraftedContractUploadConfirming = true
    },

    updateCollaborator (members) {
      let newList = []
      let change = []
      try {
        newList = members
          .map(el => ({
            email: el.email,
            name: el.name,
            icon: el.icon,
            role: el.role
          }))
        change = newList
          .filter(el => this.collaborators.findIndex(el2 => el.email == el2.email) < 0)
          .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
              role: el.role,
              caseType: 'added'
            }))
          .concat(
            this.collaborators
              .filter(el => newList.findIndex(el2 => el.email == el2.email) < 0)
              .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
              role: el.role,
              caseType: 'deleted'
            }))
          )
        this.collaborators = newList
      } catch (e) {
        return []
      }
      return change
    },
    async postUpdatedCollaborator (members) {
      this.$eventBus.$emit('snackbar', { text: 'Saving Access', type: 'loading' })

      let change = this.updateCollaborator(members)
      if (!change.length) {
        this.$eventBus.$emit('snackbar', { text: 'Change not found', type: 'warning' })
      } else {
        let snackbarParams = { text: 'Fail to save access', type: 'error' }
        try {
          const res = await xPost(
            'contract_review',
            'updateSharedTo',
            {
              loginStatus: this.loginStatus,
              token: this.token,
              role: '2',
              added: change.filter(el => el.caseType === 'added'),
              deleted: change.filter(el => el.caseType === 'deleted')
            }
          )
          if (res.data.status === 200) {
            snackbarParams = { text: 'Access Saved', type: 'success' }
            this.closeShareTo()
            // this.$eventBus.$emit('refreshGapi')
            setTimeout(() => {
              this.getData()
            }, 100);
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return
              }
            } catch (e) {}
            throw ''
          }
        } catch (err) {
          console.log('Error [save access]')
          // console.log(err)
        }
        this.$eventBus.$emit('snackbar', snackbarParams)
      }
    },
    async postForward (data) {
      this.$eventBus.$emit('snackbar', { text: 'Forwarding', type: 'loading' })

      let to = data.to
      if (!to.length) {
        this.$eventBus.$emit('snackbar', { text: 'Please select user forwarded', type: 'warning' })
      } else {
        let snackbarParams = { text: 'Fail to forward', type: 'error' }
        let action = 0
        if (this.isManagerApprovingContract) {
          action = 22
        } else if (this.isFinanceApprovingContract) {
          action = 23
        } else if (this.isLegalApprovingContract) {
          action = 24
        } else if (this.isCpgApprovingContract) {
          action = 35
        } else if (this.isCiApprovingContract) {
          action = 43
        }
        if (action) {
          try {
            const res = await xPost(
              'contract_review',
              'updateSharedTo',
              {
                loginStatus: this.loginStatus,
                token: this.token,
                role: '1',
                added: to,
                history: {
                  action
                },
                comments: data.comments,
                deleted: []
              }
            )
            if (res.data.status === 200) {
              snackbarParams = { text: 'Forwarded successfully', type: 'success' }
              this.closeForward()
              // this.$eventBus.$emit('refreshGapi') // this.$eventBus.$emit('refreshUserWholly')
              setTimeout(() => {
                this.getData()
              }, 100);
            } else {
              try {
                if (res.data.message === 'Authorize Failed') {
                  this.$router.push('/login')
                  return
                }
              } catch (e) {}
              throw ''
            }
          } catch (err) {
            console.log('Error [forward]')
            // console.log(err)
          }
        }
        this.$eventBus.$emit('snackbar', snackbarParams)
      }
    },
    draftStepOpportunityNext () {
      this.contract.toDraftStep = 1
      this.saveContractDraftWithStep(true)
    },
    draftStepContractTypeNext () {
      if (!this.$refs['contract-type-form'].validate()) return

      if (this.isContractGeneratorUsed) {
        this.contract.toDraftStep = this.contract.draftStep
        this.postSaveContractInfo()
        this.showGenerateContract()
      } else {
        this.contract.toDraftStep = 2
        this.saveContractDraftWithStep(true)
      }
    },
    draftStepDraftedContractNext () {
      this.contract.toDraftStep = 3
      this.saveContractDraftWithStep(true)
    },
    async draftStepContractInfoNext () {
      this.contractInfoChecked = true
      if (!this.$refs['contract-info-form'].validate()) return

      this.contract.toDraftStep = this.isContractGeneratorUsed ? 3 : 5
      let token = await this.saveContractDraftWithStep(true)
      if (!!token && this.toShowGenerateContract) { this.toShowGenerateContract = false }
    },
    draftStepGeneratedContractNext () {
      this.contract.toDraftStep = 5
      this.saveContractDraftWithStep(true)
    },
    async draftStepContractPreviewNext () {
      this.contract.toDraftStep = 4
      await this.saveContractDraftWithStep()
    },
    draftStepContractTypePrevConfirm () {
      this.contract.toDraftStep = 0
      this.isDraftStepMoveConfirming = true
    },
    draftStepDraftedContractPrevConfirm () {
      this.contract.toDraftStep = 1
      this.isDraftStepMoveConfirming = true
    },
    draftStepContractInfoPrevConfirm () {
      this.contract.toDraftStep = this.isContractGeneratorUsed ? 1 : 2
      this.isDraftStepMoveConfirming = true
    },
    draftStepGeneratedContractPrevConfirm () {
      this.contract.toDraftStep = this.isContractGeneratorUsed ? 1 : 2
      this.isDraftStepMoveConfirming = true
    },
    draftStepApprovalInfoPrevConfirm () {
      this.contract.toDraftStep = this.isContractGeneratorUsed ? 1 : 3
      this.isDraftStepMoveConfirming = true
    },
    draftStepMove() {
      this.saveContractDraftWithStep(true)
      this.isDraftStepMoveConfirming = false
    },

    showGenerateContract () {
      this.toShowGenerateContract = true
      if (this.$refs['contract-info-form-generate']) {
        this.$refs['contract-info-form-generate'].resetValidation()
      }
    },
    closeGenerateContract () {
      this.toShowGenerateContract = false
      this.contract.toDraftStep = this.contract.draftStep
      this.postSaveContractInfo()
    },

    reEditPreviewingContract () {
      if (this.isDraftStepInGeneratedContract) {
        this.contract.toDraftStep = 3
        this.draftStepMove()
      }
      this.isContractPreviewing = false
    },

    async reEditPreviewingContractV3 () {
      if (this.isDraftStepInGeneratedContract) {
        this.contract.toDraftStep = 1
        let token = await this.postSaveContractInfo()
        if (!!token) { this.contract.draftStep = 1 }
      }
    },

    async init () {
      this.isContractLoading = true
      this.isLoadingOpportunity = true
      await this.postGetOptions()
      if (this.isNewContract) {
        this.presetData()
      } else {
        this.$eventBus.$emit('clearNotification', {
          type: 'Client Project Contract Detail',
          token: this.token
        })
        
        await this.getData()
      }
      if (!this.canView) {
        this.$router.push('/legal/request/list')
      }
      this.isContractLoading = false
      this.isLoadingOpportunity = false
    },

    allowedStartDates(date) {
      if (this.contract.endDate) {
        return date <= this.contract.endDate
      } else {
        return true
      }
    },
    allowedEndDates(date) {
      if (this.contract.startDate) {
        return date >= this.contract.startDate
      } else {
        return true
      }
    },

    // autocomplete SAP input change
    async postGetSapNames (keyword, mode) {
      try {
        keyword = keyword || ''
        if (!keyword) { return }
        this.isSapsLoading = true
        const time = new Date()
        this.timeLoadSaps = time
        const req = xPost(
          'sap',
          'getSapByKeyword',
          {
            loginStatus: this.loginStatus,
            keyword
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          let currKey = ''
          let focused = false
          switch (mode) {
            case 1:
              currKey = this.contract.sapKey
              focused = this.sapFocused
              break;
          
            case 2:
              currKey = this.contract.sapKeyGen
              focused = this.sapGenFocused
              break;
          
            default:
              break;
          }
          if (keyword === currKey && this.timeLoadSaps === time) {
            const saps = res.data.response
            this.contract.saps = saps
            if (!focused && this.contract.sap && !saps.includes(this.contract.sap)) {
              this.contract.sap = ''
            }
            this.isSapsLoading = false
          }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get SAP names]')
        console.log(err)
        return
      }
    },
    async postGetSapCustomerNumbersByName () {
      try {
        const name = this.contract.sap || ''
        if (!name) {
          this.contract.customerNumbers = []
          this.contract.customerNumber = ''
          return
        }
        const req = xPost(
          'sap',
          'getSapCusNumsByName',
          {
            loginStatus: this.loginStatus,
            name,
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          if (name === this.contract.sap) {
            this.contract.customerNumbers = res.data.response.filter(el => !!el)
            if (this.isPendingContract && !this.contract.customerNumbers.includes(this.contract.customerNumber)) {
              if (this.contract.customerNumbers.length === 1) {
                this.contract.customerNumber = this.contract.customerNumbers[0]
              } else if (this.contract.customerNumber) {
                this.contract.customerNumber = ''
              }
            }
          }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get SAP customer number by name]')
        console.log(err)
        return
      }
    },
    async postGetSapFinPayStd () {
      try {
        const name = this.contract.sap
        const cnum = this.contract.customerNumber
        if (!name || !cnum) {
          this.contract.standardPaymentTermSap = ''
          return
        }
        const req = xPost(
          'sap',
          'getSapFinPayStdByPKey',
          {
            loginStatus: this.loginStatus,
            name,
            cnum,
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          if (name === this.contract.sap && cnum === this.contract.customerNumber) {
            if (this.isPendingContract) {
              this.contract.standardPaymentTermSap = res.data.response
            }
          }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get SAP finance payment terms standard]')
        console.log(err)
        return
      }
    },

    blurContractAmount () {
      let fltAmount = parseFloat(this.contract.amount)
      if (isNaN(fltAmount)) return ''
      this.contract.amount = fltAmount.toFixed(2).toString()
    },

    clog(val) {
      console.log(val)
    },

    commentInput (value) {
    },
    commentAt (item) {
    },
    commentAtInsert (item) {
    },

    draftCommentInput (value) {
    },
    draftCommentAt (item) {
    },
    draftCommentAtInsert (item) {
    },

    approvalCommentInput (value) {
    },
    approvalCommentAt (item) {
    },
    approvalCommentAtInsert (item) {
    },
    
    inputSap (value) {
      if (!!value) {
        if (this.contract.sapKey !== value) { this.contract.sapKey = value }
      }
    },
    inputSapGen (value) {
      if (!!value) {
        if (this.contract.sapKeyGen !== value) { this.contract.sapKeyGen = value }
      }
    },
    focusSap () {
      this.sapFocused = true
    },
    focusSapGen () {
      this.sapGenFocused = true
    },
    blurSap () {
      this.sapFocused = false
      if (this.contract.sapKey !== this.contract.sap) {
        this.contract.sap = ''
      }
    },
    blurSapGen () {
      this.sapGenFocused = false
      if (this.contract.sapKeyGen !== this.contract.sap) {
        this.contract.sap = ''
      }
    },

    // form 内容更新
    changeStartDate () {
      this.$refs.startDateGenerator.save(this.contract.startDate)
    },
    changeEndDate () {
      this.$refs.endDateGenerator.save(this.contract.endDate)
    },
    changeBillingTerm () {
      let billingterm = this.getBillingTermText()
    },
    changeBillingTermSpec () {
      this.changeBillingTerm()
    },
    changePaymentTerm () {
      let paymentterm = this.getPaymentTermText()
    },
    changePaymentTermSpec () {
      this.changePaymentTerm()
    },
    
    clickRecall () {
      if (this.canRecall) {
        if (this.cntCanRecall === 1) {
          this.recall.type = 
            this.canRecallSubmit ? 1 :
            this.canRecallSign ? 2 : null
        } else {
          if (this.$refs['client-project-contract-recall-form']) {
            this.$refs['client-project-contract-recall-form'].resetValidation()
          }          
          this.recall.type = null
        }
        this.isContractRecalling = true
      }
    },

    inputExtSignerOrganizeName () {
      this.extSignerOrganizeNameKey = this.signFlow.extSigner.organize.name
    },
    inputExtSignerAgentName () {
      this.extSignerAgentNameKey = this.signFlow.extSigner.agent.name
    },
    inputExtSignerAgentMobile () {
      this.extSignerAgentMobileKey = this.signFlow.extSigner.agent.mobile
    },
    inputExtSignerAgentEmail () {
      this.extSignerAgentEmailKey = this.signFlow.extSigner.agent.email
    },
    
    async getIntOrgs () {
      this.isIntSignerLoading = true
      let postGetIntOrgs = this.postGetIntOrgs()
      this.intOrganizes = await postGetIntOrgs
      this.isIntSignerLoading = false
    },
    async getExtOrgsWithAgents (val) {
      this.isExtSignerLoading = true
      let postGetExtOrgs = this.postGetExtOrgs(val)
      let resp = await postGetExtOrgs
      if ((resp instanceof Array) && val === this.extSignerOrganizeNameKey) {
        this.extOrganizesXAgents = resp
      }
      this.isExtSignerLoading = false
    },
    async getExtAgentsWithOrgs (val, type) {
      this.isExtSignerLoading = true
      type = type || ''
      this.flagPostExtAgents[0] = val
      this.flagPostExtAgents[1] = type
      let postGetExtAgents = this.postGetExtAgents(val, type)
      let resp = await postGetExtAgents
      if ((resp instanceof Array) && val === this.flagPostExtAgents[0] && type === this.flagPostExtAgents[1]) {
        this.extAgentsXOrganizes = resp
      }
      this.isExtSignerLoading = false
    },

    clickStartSign () {
      const activeSalesOrg = this.salesOrganizationListForSelect.find(el => el.value === this.contract.salesOrganization)
      // check NielsenIQ entity
      if (!activeSalesOrg) {
        this.isNielsenEntityExpiredAlertDisplaying = true
      } else if (!activeSalesOrg?.signable) {
        this.isNielsenEntityPhysicalSignOnlyDisplaying = true
      } else {
        this.startSign()
      }
    },
    async startSign () {
      await this.postGetSignConf()
      if (!this.signConf.defSignType) {
        this.isSignTypeSelecting = true
      } else {
        this.signFlow.type = this.signConf.defSignType
        await this.finishSelectType()
      }
    },
    async finishSelectType () {
      this.isSignTypeNextLoading = true
      await this.getIntOrgs()
      this.isSignTypeNextLoading = false

      // if manual selecting
      this.isSignTypeSelecting = false

      this.signFlow.extSigner.need = (this.signConf.needExtSign && (this.signFlow.type === 2))
      this.startSignerSelecting()
    },
    startSignerSelecting () {
      if (this.$refs['client-project-contract-signer-form']) {
        this.$refs['client-project-contract-signer-form'].resetValidation()
      }
      this.signFlow.intSigner.organize.name = null
      this.signFlow.extSigner.organize.name = null
      this.signFlow.extSigner.agent.name = null
      this.signFlow.extSigner.agent.mobile = null
      this.signFlow.extSigner.agent.email = null
      this.isSignerSelecting = true
    },
    async createSignFlow () {
      let validate = true
      if (!!this.$refs['client-project-contract-signer-form']) {
        validate = validate && this.$refs['client-project-contract-signer-form'].validate()
      }
      if (!validate) return

      this.isSignFlowCreating = true
      // ids
      let intOrg = this.intOrganizes.find(el => el.name === this.signFlow.intSigner.organize.name)
      
      if (this.signFlow.extSigner.need) {
        let validationExtSigner = true
        let extOrg = this.extOrganizesXAgents.find(el => el.orgName === this.signFlow.extSigner.organize.name)
        if (!extOrg) {
          this.msgExtOrg = 'Legal Entity Not Found: New legal entity will be created.'
          validationExtSigner = false
        }
        let extAgent = this.extAgentsXOrganizes.find(el => el.agentName === this.signFlow.extSigner.agent.name && el.agentMobile === this.signFlow.extSigner.agent.mobile && el.agentEmail === this.signFlow.extSigner.agent.email)
        if (!extAgent) {
          this.msgExtAgent = 'Liaison (Name/Mobile/Email) Not Found: New liaison will be created.'
          validationExtSigner = false
        }
        let extOrgXAgent = this.extAgentsXOrganizes.find(el => el.orgName === this.signFlow.extSigner.organize.name && el.agentName === this.signFlow.extSigner.agent.name && el.agentMobile === this.signFlow.extSigner.agent.mobile && el.agentEmail === this.signFlow.extSigner.agent.email)
        if (!extOrgXAgent) {
          this.msgExtOrgXAgent = 'Liaison (Name/Mobile/Email) Not Found in Legal Entity: Liaison will be added into Legal Entity.'
          validationExtSigner = false
        }
        this.signFlow.extSigner.binded = validationExtSigner
        if (!validationExtSigner) {
          this.isExtSignerConfirming = true
          return
        }
      }

      await this.confirmCreateSignFlow()
    },
    async clickConfirmExtSigner () {
      this.isExtSignerConfirming = false
      await this.confirmCreateSignFlow()
    },
    cancelConfirmExtSigner () {
      this.isSignFlowCreating = false
    },
    closeConfirmExtSigner () {
      this.isSignFlowCreating = false
    },
    async confirmCreateSignFlow () {
      // post
      let postCreateSignFlow = await this.postCreateSignFlow()
      this.isSignFlowCreating = false
      if (postCreateSignFlow) {
        this.isSignerSelecting = false
        await this.getData()
      } else {
        await this.getExtOrgsWithAgents(this.signFlow.extSigner.organize.name)
        await this.getExtAgentsWithOrgs('', '')
      }
    },
    async clickViewSign () {
      const activeSalesOrg = this.salesOrganizationListForSelect.find(el => el.value === this.contract.salesOrganization)
      if (!(await this.postGetIntSignPwd())) {
        this.$eventBus.$emit('snackbar', { text: 'Fail to get signing information', type: 'error' })
      } else if (!activeSalesOrg) {
        this.isNielsenEntityExpiredAlertDisplaying = true
      } else if (!activeSalesOrg?.signable) {
        this.isNielsenEntityPhysicalSignOnlyDisplaying = true
      } else {
        this.isSignUrlDisplayed = true
      }
    },
    clickIntLink () {
      // DO NOT go to sign when code review
      if (!isProductEnv && isCdRevEnv) return;
      window.open(this.signFlow.intSigner.url)
    },
    clickExtLink () {
      // DO NOT go to sign when code review
      if (!isProductEnv && isCdRevEnv) return;
      window.open(this.signFlow.extSigner.url)
    },
    async clickArchive () {
      this.isContractArchiving = true
    },

    autoExtSigner (type) {
      let auto = false
      switch (type) {
        case 'orgName':
          auto = (this.extSignerOrganizeNames instanceof Array && !!this.extSignerOrganizeNames.find(el => el.name === this.signFlow.extSigner.organize.name))
          break;
      
        case 'agentName':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.name === this.signFlow.extSigner.agent.name))
          break;
      
        case 'agentMobile':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.mobile === this.signFlow.extSigner.agent.mobile))
          break;
      
        case 'agentEmail':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.email === this.signFlow.extSigner.agent.email))
          break;
      
        default:
          // do nothing
          break;
      }
      if (auto) {
        if (!this.signFlow.extSigner.organize.name && this.extSignerOrganizeNames instanceof Array && this.extSignerOrganizeNames.length === 1) {
          this.signFlow.extSigner.organize.name = this.extSignerOrganizeNames[0].name
        }
        if (!this.signFlow.extSigner.agent.name && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.name = this.extSignerAgents[0].name
        }
        if (!this.signFlow.extSigner.agent.mobile && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.mobile = this.extSignerAgents[0].mobile
        }
        if (!this.signFlow.extSigner.agent.email && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.email = this.extSignerAgents[0].email
        }
      }
    },
    
    async postGetSignConf () {
      try {
        const res = await xPost(
          'contract_review',
          'signconf',
          {
            loginStatus: this.loginStatus,
            token: this.token
          }
        )
        if (res.data.status === 200) {
          this.signConf.defSignType = res.data.defSignType
          this.signConf.needExtSign = res.data.needExtSign
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get sign conf]')
        console.log(err)
      }
    },
    async postCreateSignFlow () {
      let ret = false

      this.$eventBus.$emit('snackbar', { text: 'Creating Sign Flow', type: 'loading' })
      let snackbarParams = { text: 'Fail to create sign flow', type: 'error' }
      
      try {
        const res = await xPost(
          'contract_review',
          'createsign',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.contract.stageNum,
            signFlow: this.signFlow,
            needArchive: this.signConf.needArchive,
          }
        )
        if (res.data.status === 200) {
          await this.getData()
          snackbarParams = { text: 'Sign Flow Created', type: 'success' }
          ret = true
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.getData()
          snackbarParams = { text: 'Fail to create sign flow, since request status has been changed.', type: 'error' }
        } else if (res.data.status === 401 && res.data.detail === 'create_ext_signer_user_exist') {
          await this.getData()
          snackbarParams = { text: 'Fail to create liaison, since email address as account exists.', type: 'error' }
        } else if (res.data.status === 401 && res.data.detail === 'create_ext_signer_user_new') {
          await this.getData()
          snackbarParams = { text: 'Fail to create liaison. Please confirm the information (Mobile No./ Email) available.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return ret
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [create sign]')
        //  console.log(err)
      }

      this.$eventBus.$emit('snackbar', snackbarParams)
      return ret
    },
    async postGetIntOrgs () {
      try {        
        const res = await xPost(
          'esign',
          'intorgs',
          {
            loginStatus: this.loginStatus
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetExtOrgs (val) {
      try {        
        const res = await xPost(
          'esign',
          'extorgsxagents',
          {
            loginStatus: this.loginStatus,
            name: val || null,
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetExtAgents (val, type) {
      try {        
        let name = this.signFlow.extSigner.agent.name
        let mobile = this.signFlow.extSigner.agent.mobile
        let email = this.signFlow.extSigner.agent.email

        switch (type) {        
          case 'name':
            name = val
            break;
        
          case 'mobile':
            mobile = val
            break;
        
          case 'email':
            email = val
            break;
        
          default:
            break;
        }

        const res = await xPost(
          'esign',
          'extagentsxorgs',
          {
            loginStatus: this.loginStatus,
            name: name || null,
            mobile: mobile || null,
            email: email || null,
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetIntSignPwd () {
      let ret = false

      try {
        const res = await xPost(
          'esign',
          'intsignpwd',
          {
            loginStatus: this.loginStatus,
          }
        )
        if (res.data.status === 200) {
          this.signFlow.intSigner.pwd = res.data.data || null
          ret = true
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get internal signer pwd]')
        // console.log(err)
      }

      return ret
    },
    async postArchive () {
      this.isContractArchiving = false
      let ret = false

      this.$eventBus.$emit('snackbar', { text: 'Archiving', type: 'loading' })
      let snackbarParams = { text: 'Fail to archive', type: 'error' }
      
      try {
        const res = await xPost(
          'contract_review',
          'archive',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.contract.stageNum,
          }
        )
        if (res.data.status === 200) {
          await this.getData()
          snackbarParams = { text: 'Contract Archived', type: 'success' }
          ret = true
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.getData()
          snackbarParams = { text: 'Fail to archive, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return ret
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [archive]')
        //  console.log(err)
      }

      this.$eventBus.$emit('snackbar', snackbarParams)
      return ret
    },
    async postGetEsignDownloadUrl (index) {
      try {
        index = index || 0

        const res = await xPost(
          'esign',
          'esigndownurl',
          {
            loginStatus: this.loginStatus,
            id: this.signFlow.esignFlowId,
            idx: index,
          }
        )
        if (res.data.status === 200) {
          return res.data.data
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return null
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get esign download url]')
        //  console.log(err)
      }

      return null
    },
    checkFinBillingStandard (item) {
      // Finance Standard Rules - Billing Terms
      // 1. if: current value matches default standard                                    --> (Yes)
      // 2. else-if: current value matches unqiue opportunity material id's standard      --> (Yes)
      // 2. else:                                                                         --> (No)
      return (
        !!this.standardsBillingTermDefault.find(el => el.value === item.value) ||
        this.standardsBillingTermOpp.includes(item.value)
      )
    },
    checkFinPaymentStandard (item) {
      // Finance Standard Rules - Payment Terms
      // 1. if: current value (not empty) = standard/default value          --> (Yes)
      // 2. else-if: current finance weight != Net days                     --> (No)
      // 3. else:                                                           then:
      // 3.1. if: standard finance weight != Net days                       then:
      // 3.1.1. if: default finance weight != Net days                      --> (No)
      // 3.1.2. else-if: current finance weight > default finance weight    --> (No)
      // 3.1.3. else:                                                       --> (Yes)
      // 3.2. else:                                                         then:
      // 3.2.1. if: current finance weight > standard finance weight        --> (No)
      // 3.2.2. else:
      return (
        (item.value &&
          ((item.value === this.standardPaymentTermSap.value) ||
          ((item.value === this.standardPaymentTermDefault.value) &&
            (this.standardPaymentTermDefault.finWeight < 0)))
        ) ||
        !(item.finWeight < 0 ||
          (this.standardPaymentTermSap.finWeight < 0
          ? (this.standardPaymentTermDefault.finWeight < 0 ||
            (item.finWeight > this.standardPaymentTermDefault.finWeight))
          : (item.finWeight > this.standardPaymentTermSap.finWeight))))
    }
  },
  async mounted () {
    this.setMainTitle('Client Project Contract')
    await this.init()
  },
  watch: {
    token (val, oval) {
      if (val !== oval) {
        this.init()
      }
    },
    canEdit (val, oval) {
      if (!!val && !oval) {
        this.contract.msalsa = this.msalsaAvailable ? 'No' : ''
      }
    },
    msalsaAvailable (val, oval) {
      if (this.canEdit) {
        if (!!val) {
          this.contract.msalsa = 'No'
        } else {
          this.contract.msalsa = ''
        }
      }
    },
    'contract.sap': {
      handler(nv, ov) {
        if (nv === ov) { return }
        if (this.isPendingContract) {
          this.postGetSapCustomerNumbersByName()
        }
      }
    },
    'contract.sapKey': {
      handler(nv, ov) {
        if (!nv || nv === ov) { return }
        if (this.isPendingContract) { this.postGetSapNames(nv, 1) }
      }
    },
    'contract.sapKeyGen': {
      handler(nv, ov) {
        if (!nv || nv === ov) { return }
        if (this.isPendingContract) { this.postGetSapNames(nv, 2) }
      }
    },
    'contract.customerNumber': {
      handler(nv, ov) {
        if (!nv || nv === ov) { return }
        if (this.isPendingContract) { this.postGetSapFinPayStd() }
      }
    },
    'contract.toDraftStep' () {
      this.contractInfoChecked = false
      this.approvalInfoChecked = false
    },    
    isContractPreviewing (val) {
      if (!val) {
        this.isFreshContractPreviewing = false
      }
    },
    listApproverCommentTo: {
      deep: true,
      handler (val) {
        if (val.length === 1) {
          this.comments.to = val[0]
        }
      }
    },
    toShowGenerateContract: {
      async handler (nv, ov) {
        if (!!nv && nv !== ov) {
          this.contractPreviewSrc = null
          if (this.contractPdfMsPath) {
            this.contractPreviewSrc = (
              await this.$refs[this.refMsalFunction].getItemPreviewUrlByPath(this.contractPdfMsPath)
            ) || null
          }
        }
        if (!nv) {
          this.isFreshContractPreviewing = false
        }
      }
    },
    
    extSignerOrganizeNameKey: {
      async handler (val) {
        await this.getExtOrgsWithAgents(val)
      }
    },
    extSignerAgentNameKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'name')
      }
    },
    extSignerAgentMobileKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'mobile')
      }
    },
    extSignerAgentEmailKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'email')
      }
    },
    'signFlow.extSigner.organize.name': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('orgName')
        }
      }
    },
    'signFlow.extSigner.agent.name': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentName')
        }
      }
    },
    'signFlow.extSigner.agent.mobile': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentMobile')
        }
      }
    },
    'signFlow.extSigner.agent.email': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentEmail')
        }
      }
    },
    contractPdfMsPath: {
      async handler () {
        if (this.toShowGenerateContract) { 
          this.contractPreviewSrc = (
            await this.$refs[this.refMsalFunction].getItemPreviewUrlByPath(this.contractPdfMsPath)
          ) || null
        }
      }
    }
  }
}
</script>

<style lang="sass">
.contract-detail
  width: 100% !important

  button
    margin-top: 3px
    margin-bottom: 3px
  

  .approver-selector-with-details
    display: flex
    align-items: center
  

  .approver-selector-without-details
    padding-right: 40px
  

  .opportunity-expansion-logo
    padding: 9px 0px 9px 6px
  
  .opportunity-expansion-title,
  .opportunity-expansion-value
    color: rgba(0, 0, 0, .54)
    padding: 0px 12px 3px
    vertical-align: top

    &.bottom
      padding-bottom: 12px
     
  
  .extra-button-text
    padding: 8px 14px 8px 8px !important

  .v-input.v-textarea.v-input--is-readonly
    textarea
      color: rgba(0,0,0,.4) !important
  


.add-contract
  width: 100% !important
  padding: 12px
  padding-left: 0
  border-top: 1px solid rgba(0, 0, 0, .12)


.add-contract-mini
  padding-left: 12px
  cursor: pointer

  &:hover
    background-color: rgba(0, 0, 0, .1)
  


.contract-stepper-container
  padding: 0 0 16px 8px


.contract-stepper
  // height: 24px

  .v-stepper,
  .v-stepper--vertical
    background: none
    box-shadow: none
    min-height: 0
    margin: 0 -12px
    padding-bottom: 0

    .v-stepper__header
      height: auto
    

    .v-stepper__step
      padding: 0 24px 3px 24px
    

    .v-stepper__header .v-divider
      border-color: hsla(0,0%,100%,.5)
    

    .v-stepper__content
      height: 10px
    
  


.v-card-x-buttons
  text-align: left


.v-card-x-layout
  .v-card-x-title
    font-size: 14px
    padding-left: 9px !important
    padding-right: 9px !important
    padding-bottom: 6px !important
  

  .v-card-x-cell
    text-align: left

    .v-card-x-cell-title
      font-size: 14px
      color: rgba(0,0,0,1)
      transform: translateX(-12.5%) scale(.75)
      -webkit-transform: translateX(-12.5%) scale(.75)
      width: 132%    

    .v-card-x-cell-title-absolute
      position: absolute
    
  

  .v-card-contract-amount
    .v-label
      font-size: 14px
    

    // .v-messages__message
    //   color: rgba(0, 0, 255, 0.5)
    // 
  


.v-flex-row-breaker
  padding: 0 !important


.contract-radio-group-horizonal
  .v-input__control
    width: 100% !important

    .v-radio
      width: calc(50% - 16px) !important
      align-items: start

      label
        top: 2px

.contract-radio-group-vertical
  .v-input__control
    width: 100% !important

    .v-radio
      width: 100% !important
      align-items: start

      label
        top: 2px
      
    
  

  .v-input--selection-controls
    margin-top: 0
  


.bottom-btn-container
  text-align: left

  &-right
    text-align: right
  

  .v-btn,
  &-right .v-btn
    max-width: 130px
    min-width: 130px
  


.comments-card,
.approve-card,
.client-project-contract-sign-type-card,
.client-project-contract-signer-card,
.contract-preview-card,
.generate-contract-card,
.new-drafted-contract-card,
.nielsen-entity-alert-card
  width: 600px !important
  max-width: 100% !important
  // overflow: hidden


.comments-card,
.new-drafted-contract-card
  height: 480px !important


.confirm-card,
.client-project-contract-sign-type-card
  width: 400px !important


.client-project-contract-signer-card
  width: 450px !important
  max-width: 100% !important


.approve-card
  width: 500px !important
  height: 286px !important


.contract-preview-card,
.generate-contract-card
  width: 1200px !important
  height: 90vh !important


.contract-review-comments-container
  .v-input
    padding-top: 0px
  

  .v-input__slot
    padding: 0 9px 9px
  

  .person-select
    .v-input__slot
      padding: 9px
    
    .v-select__selections
      padding-left: 6px
    
  

  textarea
    margin-top: 0px !important
    padding-top: 8px !important
  
.contract-review-comments-container-icon
  display: flex

  i
    margin: 0 10px 0 0
  

  &-bold
    font-weight: 500
  



.add-opp-card
  width: 900px !important
  max-width: 100% !important
  height: 90vh !important
  min-height: 600px !important
  overflow: hidden


.app-header-title-right
  display: flex
  padding-top: 2px
  margin-right: 12px

  .v-btn
    margin-left: 12px
    margin-right: 0px
  

  @media(max-width:960px)
    .v-btn
      margin-top: 9px
      margin-left: 12px
    
  


.contract-detail-loading
  width: 100%
  text-align: center
  padding-top: calc(50vh - 85px)


.contract-review-approver-no-need
  margin-right: 40px
  padding: 28px 0 13px
  position: relative
  color: rgba(0, 0, 0, .68) !important

  &:before
    bottom: -1px
    content: ""
    left: 0
    position: absolute
    transition: .3s cubic-bezier(.25,.8,.5,1)
    width: 100% !important
    border-color: #d2d2d2!important
    border-style: solid
    border-width: thin 0 0
    border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38),rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat
  


.contract-review-details-approver-tooltip
  text-align: left


.client-project-contract-recall-radio-group
  .client-project-contract-recall-radio-description
    color:  grey
    font-size: 11px
    line-height: 12px
  


.client-project-contract-sign-con-type-radio-group
  .client-project-contract-sign-con-type-radio-description
    color:  grey
    font-size: 11px
    line-height: 12px
  


.sign-flow-signer-header,
.sign-flow-info-sign-header
  text-align: left
  font-size: 16px


.sign-flow-ext-signer-error-msg
  color: red
  text-align: left
  font-size: 12px


.sign-flow-info-sign-content
  text-align: left
  font-size: 12px

</style>
